<template lang="pug">
  div
    v-row(no-gutters @click="$store.commit('current/updateEditingSection', $userFlows.FILL_IN_EXTRA_FIELDS_FLOW)")
      v-col(cols="4")
        div.p2.basic600--text(style="width: 100px") {{ $t('additional') }}
      v-col(cols="7")
        transition(name="title")
          div(v-if="!(editingSection === $userFlows.FILL_IN_EXTRA_FIELDS_FLOW)")
            div.p2.primarv--text {{ fieldNum }}
      v-col(cols="1" align="end")
        eva-icon(:name="editingSection === $userFlows.FILL_IN_EXTRA_FIELDS_FLOW ? 'chevron-up' : 'chevron-down'" :fill="$vuetify.theme.themes[$theme].info400" height="20" width="20" )
    transition(name="content")
      v-form(ref="form")
        div(v-if="editingSection === $userFlows.FILL_IN_EXTRA_FIELDS_FLOW")
          template(v-if="lead.form.email_enabled")
            div.label.basic600--text.text-uppercase.mt-4 {{ $t('email') }}
            v-text-field.mt-2(v-model="leadEmail" :placeholder="$t('email')" background-color="basic200" outlined dense hide-details="auto" :rules="(lead.form.email_required || lead.email) ? rules.valid_email : []")

          template(v-for="(extra_field, index) in customer.extra_fields_data")
            template(v-if="extra_field.enabled")
              .text(v-if="extra_field.type == 'text'")
                div.mt-4.label.basic600--text.text-uppercase {{ extra_field.name }}
                v-text-field.mt-2(v-model="extrafields[index]" :placeholder="extra_field.name" outlined background-color="basic200" height="40" dense hide-details="auto" :rules="extra_field.required ? rules.not_empty : []")
              .dropdown(v-if="extra_field.type == 'dropdown'")
                div.mt-4.label.basic600--text.text-uppercase {{ extra_field.name }}
                v-select.mt-2(v-model="extrafields[index]"  outlined background-color="basic200" height="40" dense hide-details="auto" :rules="extra_field.required ? rules.not_empty : []" :items="extra_field.choices")
              .checklist(v-if="extra_field.type == 'checklist'")
                div.mt-4.label.basic600--text.text-uppercase {{ extra_field.name }}
                template(v-for="(choice, i) in extra_field.choices")
                  y-checkbox.pa-0.mx-0.my-3(v-model="customer.extra_fields_data[index].checklist_data[i]" :label="choice" :value="choice" :key="choice" hide-details @change="saveCheck(index)")
              .date(v-if="extra_field.type == 'date'")
                div.mt-4.label.basic600--text.text-uppercase {{ extra_field.name }}
                v-dialog(v-model="customer.extra_fields_data[index].show" max-width="320px")
                  template(v-slot:activator='{ on }')
                    v-text-field.mt-2(:value="extrafields[index]" :placeholder="$t('select_date')" outlined background-color="basic200" height="40" dense hide-details="auto" :rules="extra_field.required ? rules.not_empty : []" readonly v-on='on')
                  v-date-picker.pt-6(v-model="customer.extra_fields_data[index].date" color="textColorAccent" @change="saveDate(index)" no-title)
              .date(v-if="extra_field.type == 'delivery_date'")
                div.mt-4.label.basic600--text.text-uppercase {{ extra_field.name }}
                v-dialog(v-model="customer.extra_fields_data[index].show" max-width="320px")
                  template(v-slot:activator='{ on }')
                    v-text-field.mt-2(:value="extrafields[index]" :placeholder="$t('select_date')" outlined background-color="basic200" height="40" dense hide-details="auto" :rules="extra_field.required ? rules.not_empty : []" readonly v-on='on')
                  v-date-picker.pt-6(v-model="customer.extra_fields_data[index].date" color="textColorAccent" @change="saveDate(index)" no-title :min="getMinDayBefore(extra_field.min_day_before)")
              .date(v-if="extra_field.type == 'time'")
                div.mt-4.label.basic600--text.text-uppercase {{ extra_field.name }}
                v-dialog(v-model='customer.extra_fields_data[index].show' max-width="320px")
                  template(v-slot:activator='{ on }')
                    v-text-field.mt-2(:value="extrafields[index]" :placeholder="$t('select_time')" outlined background-color="basic200" height="40" dense hide-details="auto" :rules="extra_field.required ? rules.not_empty : []" readonly v-on='on')
                  v-time-picker(v-model='customer.extra_fields_data[index].time' color="textColorAccent")
                    v-btn.button-AA-medium.mt-3.white--text(color="textColorAccent" dark small height="40" block @click="saveTime(index)") set
              .date(v-if="extra_field.type == 'delivery_time'")
                div.mt-4.label.basic600--text.text-uppercase {{ extra_field.name }}
                v-dialog(v-model='customer.extra_fields_data[index].show' max-width="320px")
                  template(v-slot:activator='{ on }')
                    v-text-field.mt-2(:value="extrafields[index]" :placeholder="$t('select_time')" outlined background-color="basic200" height="40" dense hide-details="auto" :rules="extra_field.required ? rules.not_empty : []" readonly v-on='on')
                  v-time-picker(v-model='customer.extra_fields_data[index].time' color="textColorAccent" :min="extra_field.start_time" :max="extra_field.end_time")
                    v-btn.button-AA-medium.mt-3.white--text(color="textColorAccent" dark small height="40" block @click="saveTime(index)") set
              .long_text(v-if="extra_field.type == 'long_text'")
                div.mt-4.label.basic600--text.text-uppercase {{ extra_field.name }}
                v-textarea.mt-2(v-model="extrafields[index]" :placeholder="extra_field.hint"  background-color="basic200" outlined dense hide-details="auto" spellcheck="false" auto-grow rows="3" :rules="extra_field.required? rules.not_empty : []")

</template>

<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'

export default {
  name: 'CollapseExtrafield',
  components: {},
  props: ['active'],
  data: () => ({
    dataIsReady: false,
    saveCountdown: null,
    leadEmail: null,
    customer: {},
    extrafields: [],
    rules: {
      not_empty: [val => (val || '').length > 0 || 'This field is required'],
      valid_email: [v => !!v || 'Email is required', v => /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Invalid email address']
    }
  }),
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      lead: 'current/getLead',
      editingSection: 'current/getEditingSection',
    }),
    fieldNum () {
      let num = 0
      if (this.lead.extra_fields_data) {
        num += this.lead.extra_fields_data.length
      }
      if (this.lead.email) {
        num += 1
      }
      if (num > 1) {
        return num + ' extra fields'
      } else {
        return num + ' extra field'
      }
    }
  },
  watch: {
    'leadEmail' : {
      handler () {
        const params = { email: this.leadEmail}
        this.saveChanges(params)
      }
    },
    'customer.extra_fields_data': {
      deep: true,
      handler () {
        if (!this.dataIsReady) {
          return
        }
        this.saveChanges(this.customer)
      }
    }
  },
  mounted () {
    this.leadEmail = this.lead.email
    if (this.lead.extra_fields_data) {
      this.customer.extra_fields_data = [...this.lead.extra_fields_data]
      this.extrafields = [...this.lead.extra_fields_data.map(m => m.value)]
      if (this.customer.extra_fields_data && this.customer.extra_fields_data.length > 0) {
        this.customer.extra_fields_data.forEach(o => {
          if (o.type === 'date' || o.type === 'delivery_date') {
            const dt = o.value.split('/')
            let date = `${dt[2]}-${dt[1]}-${dt[0]}`
            let show = false
            o = { ...o, date, show }
          } else if (o.type === 'time' || o.type === 'delivery_time') {
            let show = false
            let time = ''
            o = { ...o, time, show }
          } else if (o.type === 'checklist') {
            let splitvalue = o.value.split(', ')
            this.$set(o, 'checklist_data', [])
            o.choices.forEach(r => {
              if ([...splitvalue].includes(r)) {
                o.checklist_data.push(true)
              } else {
                o.checklist_data.push(false)
              }
            })
          }
        })
      }
    }
    this.dataIsReady = true
  },
  methods: {
    saveDate (index) {
      this.extrafields[index] = this.formatDate(this.customer.extra_fields_data[index].date)
      this.$set(this.customer.extra_fields_data[index], 'show', false)
      this.saveChanges(this.customer)
    },
    saveTime (index) {
      this.extrafields[index] = this.formatTime(this.customer.extra_fields_data[index].time)
      this.$set(this.customer.extra_fields_data[index], 'show', false)
      this.saveChanges(this.customer)
    },
    saveCheck (index) {
      let selectedString = ''
      this.customer.extra_fields_data[index].checklist_data.forEach((data, idx) => {
        if (data) {
          if (selectedString.length > 0) {
            selectedString = selectedString.concat(', ')
          }
          selectedString = selectedString.concat(this.customer.extra_fields_data[index].choices[idx])
        }
      })
      this.extrafields[index] = selectedString
      this.saveChanges(this.customer)
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    formatTime (time24) {
      let ts = time24
      const H = +ts.substr(0, 2)
      let h = (H % 12) || 12
      h = (h < 10) ? ('0' + h) : h
      const ampm = H < 12 ? 'AM' : 'PM'
      ts = h + ':' + ts.substr(3, 2) + ampm
      return ts
    },
    getMinDayBefore (day) {
      return DateTime.local().plus({ days: day }).toFormat('yyyy-MM-dd')
    },
    saveChanges (params) {
      if (this.saveCountdown) {
        clearTimeout(this.saveCountdown)
      }
      this.saveCountdown = setTimeout(() => {
        this.axios.patch(`/leads/${this.lead.pid}/`, params)
          .catch(() => {})
          .finally(() => {
            this.updateCurrentLead()
          })
      }, 2000)
    }
  }
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: var(--v-primary200);
}
.error--text >>> fieldset {
  border-color: var(--v-danger);
}
.title-enter-active {
  transition: opacity .3s;
}
.title-leave-active {
  transform: scale(0,0) .3s;
}
.title-enter, .title-leave-to {
  opacity: 0;
}
.content-enter-active {
  transition: opacity .3s;
}
.content-leave-active {
  transform: scale(0,0) .3s;
}
.content-enter, .content-leave-to {
  opacity: 0;
}
</style>