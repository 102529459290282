<template lang="pug">
  div
    v-dialog(v-model="showModal" scrollable transition="fade-transition" persistent max-width="600")
      v-card
        v-card-title.pa-4
          div.h6.primary--text {{ parentAddress ? 'Edit' : 'Add' }} shipping address
          v-spacer
          v-btn(small icon @click="closeDialog")
            v-icon(color="primary") mdi-close
        v-card-text.pa-4
          v-form(ref="form")
            v-text-field(v-model="address.name" label="Name" filled rounded hide-details="auto" :rules="rules.not_empty")
            v-text-field.mt-3(v-model="address.phone" label="Phone" filled rounded hide-details="auto" :rules="rules.not_empty")
            v-select.mt-3(v-model="address_data.state" label="Province" :items="states" append-icon="" item-text="name" item-value="id" filled rounded hide-details="auto" :rules="rules.not_empty" :loading="isLoading" @change="fetchCities(address_data.state, false)")
            v-select.mt-3(v-model="address_data.city" label="City" :items="cities" append-icon="" item-text="name" item-value="id" filled rounded hide-details="auto" :rules="rules.not_empty" :loading="isLoading" @change="fetchSuburbs(address_data.city, false)")
            v-select.mt-3(v-model="address_data.suburb" label="Suburb" :items="suburbs" append-icon="" item-text="name" item-value="id" filled rounded hide-details="auto" :rules="rules.not_empty" :loading="isLoading" @change="fetchAreas(address_data.suburb, false)")
            v-select.mt-3(v-model="address_data.area" label="Area" :items="areas" append-icon="" item-text="area" filled rounded hide-details="auto" :rules="rules.not_empty" return-object :loading="isLoading" @change="setShippingAddress(address_data.area)")
            v-text-field.mt-3(v-model="address.address_1" label="Address 1" filled rounded hide-details="auto" :rules="rules.not_empty")
            v-text-field.mt-3(v-model="address.address_2" label="Address 2 (optional)" filled rounded hide-details="auto" )
            v-text-field.mt-3(v-model="address.postcode" label="Postcode" filled rounded hide-details="auto" :rules="rules.not_empty" :loading="isLoading")
            v-text-field.mt-3(v-model="address.country" label="Country" filled rounded hide-details="auto" :rules="rules.not_empty" :loading="isLoading")
        v-card-actions.pa-4
          v-btn.button-AA-medium.white--text(color="success500" dark small height="40" block @click="saveAddress" :loading="isLoading2") {{ source === 'pickup' ? 'continue to shipping method' : 'Save address' }} 
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AddShippingAddressId',
  props: ['open', 'parentAddress', 'source'],
  data: () => ({
    isLoading: false,
    isLoading2: false,
    address: {},
    address_data: {},
    states: [],
    cities: [],
    suburbs: [],
    areas: [],
    rules: {
      not_empty: [v => !!v || 'This field is required']
    }
  }),
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      restrictedCities: 'current/getRestrictedCities',
      lead: 'current/getLead'
    }),
    showModal: {
      get() {
        return this.open
      },
      set(newValue) {
        this.$emit('update:open', newValue)
      }
    }
  },
  mounted () {
    if (this.parentAddress) {
      this.address = { ...this.parentAddress }
      this.setAddressData(this.parentAddress.extra_data)
    } else {
      this.fetchStates()
      if (this.lead.shipping_address) {
        this.address = {
          name: this.lead.shipping_address.name,
          phone: this.lead.shipping_address.phone
        }
      } else {
        this.address = {
          name: this.lead.name,
          phone: this.lead.phone
        }
      }
    }
  },
  methods: {
    fetchStates () {
      this.isLoading = true
      this.$axios.get('/addresses_idn/states')
        .then(resp => {
          this.states = resp.data
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    fetchCities (id, setData) {
      this.isLoading = true
      this.address_data = { state: id }
      this.cities = []
      this.suburbs = []
      this.areas = []
      if (!setData) {
        this.clearAddress()
      }
      this.$axios.get(`/addresses_idn/cities?state_id=${id}`)
        .then(resp => {
          this.cities = resp.data
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    fetchSuburbs (id, setData) {
      this.isLoading = true
      delete this.address_data.suburb
      delete this.address_data.area
      this.suburbs = []
      this.areas = []
      if (!setData) {
        this.clearAddress()
      }
      this.$axios.get(`/addresses_idn/suburbs?city_id=${id}`)
        .then(resp => {
          this.suburbs = resp.data
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    fetchAreas (id, setData) {
      this.isLoading = true
      delete this.address_data.area
      this.areas = []
      if (!setData) {
        this.clearAddress()
      }
      this.$axios.get(`/addresses_idn/areas?suburb_id=${id}`)
        .then(resp => {
          this.areas = resp.data
          if (setData) {
            this.address_data.area = this.areas.find(f => f.extra_data.area_id === this.parentAddress.extra_data.area_id)
          }
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    setShippingAddress (data) {
      data.name = this.address.name
      data.phone = this.address.phone
      data.address_1 = this.address.address_1
      data.address_2 = this.address.address_2
      this.address = data
    },
    async setAddressData (data) {
      await this.fetchStates()
      if (data.state_id) {
        this.address_data.state = data.state_id
        await this.fetchCities(data.state_id, true)
      }
      if (data.city_id) {
        this.address_data.city = data.city_id
        await this.fetchSuburbs(data.city_id, true)
      }
      if (data.suburb_id) {
        this.address_data.suburb = data.suburb_id
        await this.fetchAreas(data.suburb_id, true)
      }
    },
    clearAddress () {
      let data = {
        name: this.address.name,
        phone: this.address.phone
      }
      if (this.address.address_1) {
        data.address_1 = this.address.address_1
      }
      if (this.address.address_2) {
        data.address_2 = this.address.address_2
      }
      this.address = data
    },
    saveAddress () {
      if (!this.$refs.form.validate()) {
        return
      }
      if (this.parentAddress) {
        if (this.source === 'select') {
          if (this.user) {
            this.isLoading2 = true
            if (this.parentAddress.id === 'main') {
              this.$axios.patch(`/zoo/${this.user.profile}`, { shipping_address: this.address })
                .then(resp => {
                  this.$emit('edit', resp.data.shipping_address)
                })
                .catch(() => {
                  this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
                })
                .finally(() => {
                  this.isLoading2 = false
                })
            } else {
              this.$axios.patch(`/zoo/${this.user.profile}/addresses/${this.parentAddress.id}`, this.address)
                .then(resp => {
                  this.$emit('edit', resp.data)
                })
                .catch(() => {
                  this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
                })
                .finally(() => {
                  this.isLoading2 = false
                })
            }
          } else {
            this.$emit('edit', this.address)
          }
        } else {
          this.isLoading2 = true
          let param = { shipping_address: this.address }
          if (this.lead.name === 'Guest') {
            param.name = this.address.name
          }
          this.axios.patch(`/leads/${this.lead.pid}/`, param)
            .then(resp => {
              const checkCity = this.lead.shipping_address.city
              if (checkCity !== resp.data.shipping_address.city) {
                this.$store.commit('current/updateTempShipping', resp.data.shipping)
              }
              this.updateCurrentLead()
              if (this.user) {
                if (this.parentAddress.id === 'main') {
                  this.$axios.patch(`/zoo/${this.user.profile}`, { shipping_address: this.address })
                    .then(resp => {
                      this.$emit('edit', resp.data.shipping_address)
                    })
                    .catch(() => {
                      this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
                    })
                    .finally(() => {
                      this.isLoading2 = false
                    })
                } else {
                  this.$axios.patch(`/zoo/${this.user.profile}/addresses/${this.parentAddress.id}`, this.address)
                    .then(resp => {
                      this.$emit('edit', resp.data)
                    })
                    .catch(() => {
                      this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
                    })
                    .finally(() => {
                      this.isLoading2 = false
                    })
                }
              } else {
                this.$emit('edit', this.address)
              }
            })
            .catch(() => {
              this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
            })
            .finally(() => {
              if (!this.user) {
                this.isLoading2 = false
              }
            })
        }
      } else {
        if (this.user) {
          this.isLoading2 = true
          this.axios.post(`/zoo/${this.user.profile}/addresses`, this.address)
            .then((resp) => {
              this.$emit('add', resp.data)
            })
            .catch(() => {
              this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
            })
            .finally(() => {
              this.isLoading2 = false
            })
        } else {
          this.$emit('add', this.address)
        }
      }
    },
    closeDialog () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
::v-deep .v-text-field--filled > .v-input__control > .v-input__slot{
  background: #F7F7F7 !important;
  border: 1px solid #eaeaea !important;
  border-radius: 4px;
  max-height: 48px;
  min-height: 48px;
  padding-left: 12px;
}
::v-deep .v-text-field.v-input--has-state > .v-input__control > .v-input__slot{
  border: 1px solid var(--v-danger) !important;
}
::v-deep .v-text-field--filled:not(.v-text-field--single-line) input{
  margin-top: 14px;
}
::v-deep .v-text-field--filled .v-label{
  top: 14px !important;
}
::v-deep .v-text-field--filled .v-label--active{
  transform: translateY(-10px) scale(0.75);
}
::v-deep .v-input--has-state.error--text{
  color: red;
}
</style>