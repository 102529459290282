<template lang="pug">
  v-card.pa-4
    template
      template(v-if="phoneStatus === 'uncheck'")
        v-form(ref="form")
          div.h6.primary--text {{ $t('secure_checkout') }}
          phone-input.my-4(v-model="leadPhone")
        v-btn.button-AA-medium.white--text(color="info500" small height="40" block @click="checkPhone" :loading="isLoading" :disabled="!isValidOrderDateTime") {{ $t('continue_checkout') }}
      template(v-if="phoneStatus === 'leadname'")
        v-form(ref="leadname")
          div.h6.primary--text {{ $t('please_enter_your_name') }}
          v-text-field.my-4(v-model="leadName" :placeholder="$t('name')" background-color="basic200" outlined dense hide-details="auto" :rules="rules.not_empty")
        v-btn.button-AA-medium.white--text(color="info500" small height="40" block @click="createLead(false)" :loading="isLoading") {{ $t('continue_checkout') }}
      template(v-if="phoneStatus === 'consent'")
        div.h6.primary--text.text-center {{ $t('checkout_faster') }}
        div.p2.basic700--text.mt-3.text-center {{ $t('we_noticed_you_have_previous') }}
        v-btn.button-AA-medium.white--text.mt-6(color="info500" dark small height="40" block @click="requestOTPWithConsent" :loading="isLoading") {{ $t('yes_please_auto_fill') }}
        div.mt-4.p2.basic600--text.text-center {{ $t('or') }}
        div.mt-4(align="center")
          v-btn.button-Aa-small.px-0(color="transparent" text @click="proceedNextStep('verified')" small)
            div.info500--text {{ $t('i_want_to_manually') }}
      template(v-if="phoneStatus === 'sendcode'")
        div.h6.primary--text.text-center {{ $t('returning_customer') }}
        div.p2.basic700--text.mt-3.text-center {{ $t('enter_the_tac_code') }}
        code-input(@finished="loginOTP")
        v-layout.my-6(v-if="isCheckingOTP")
          v-row.fill-height.ma-0(align='center' justify='center')
            v-progress-circular.ma-auto(:width="2" :size="50" color="primary" indeterminate)
        template(v-else)
          div.mt-4.c1.danger500--text.text-center {{ codeMessage }}
          div.c1.basic600--text.text-center(:class="{ 'mt-4': !codeMessage }") {{ resendOTPTimer === 0 ? `Did not receive code?&nbsp;` : resendOTPTimer ? `(${resendOTPTimer})` : '&nbsp;' }}
            template(v-if="resendOTPTimer === 0")
              strong.info500--text.ml-1(@click="requestOTP()") {{ $t('resend') }}
          div.mt-4.p2.basic600--text.text-center {{ $t('or') }}
          div.mt-4(align="center")
            v-btn.button-Aa-small.px-0(color="transparent" text @click="proceedNextStep('verified')" small)
              div.info500--text {{ $t('didnt_receive_tac_code') }}
</template>

<script>
import { mapGetters } from 'vuex'
import PhoneInput from '@/components/custom/PhoneInput.vue'
import CodeInput from '@/components/custom/CodeInput.vue'

export default {
  name: 'SecureCheckout',
  components: { PhoneInput, CodeInput },
  props: ['hasEmptyExtraFields'],
  data: () => ({
    isLoading: false,
    isCheckingOTP: false,
    leadPhone: null,
    leadName: null,
    resendOTPTimer: null,
    codeMessage: null,
    rules: {
      not_empty: [v => !!v || 'This field is required']
    }
  }),
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      orderDateTime: 'current/getDateTime',
      phoneStatus: 'current/getPhoneStatus',
      storedLeadPhone: 'current/getLeadPhone',
      storedLeadName: 'current/getLeadName',
      finalProducts: 'cart/getSanitizedFinalProducts',
      dataBin: 'current/getDataBin',
      lead: 'current/getLead',
      cart: 'cart/getCart',
      omsData: 'current/getOms',
      isCourierPlus: 'current/getIsCourierPlus',
    }),
    totalAmount () {
      let total = 0
      this.cart.forEach(o => {
        let addonprice = 0
        if (o.addons) {
          o.addons.forEach(a => {
            addonprice += (a.price * a.quantity_per_set)
          })
        }
        total += (o.quantity * (o.product.price + addonprice))
      })
      return total
    },
    isValidOrderDateTime () {
      if (this.isCourierPlus) {
        return true
      }
      if (this.orderDateTime.is_ASAP !== undefined) {
        if (this.orderDateTime.is_ASAP === false && !this.orderDateTime.asking_delivery_date) {
          return false
        }
      }
      return true
    }
  },
  watch: {
    leadPhone (val) {
      this.$store.commit('current/updateOrderLeadPhone', val)
    },
    leadName (val) {
      this.$store.commit('current/updateOrderLeadName', val)
    },
  },
  mounted () {
    if (this.lead) {
      this.leadPhone = this.lead.phone
    } else if (this.storedLeadPhone) {
      this.leadPhone = this.storedLeadPhone
    }
    if (this.phoneStatus === 'leadname' && this.storedLeadName) {
      this.leadName = this.storedLeadName
      this.createLead(true)
    }
  },
  methods: {
    checkPhone () {
      if (!this.$refs.form.validate()) {
        this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        return
      }
      if (!this.isValidToCheckout()) {
        return
      }
      if (!this.lead) {
        this.proceedPhoneVerification()
        return
      }
      this.isLoading = true
      let param = { phone: this.leadPhone }
      if (this.orderDateTime.is_ASAP !== undefined && !this.isCourierPlus) {
        param = {...param, ...this.orderDateTime}
      }
      this.axios.patch(`/leads/${this.lead.pid}/`, param)
        .then((resp) => {
          this.$store.commit('current/updateLead', resp.data)
          this.proceedPhoneVerification()
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
          this.isLoading = false
        })
    },
    proceedPhoneVerification () {
      this.proceedNextStep('verified')
      // this.$axios.get('/users/check_phone/', { params :{ phone: this.leadPhone, resource: 'zoo' } })
      //   .then((resp) => {
      //     if (resp.data.available) {
      //       this.proceedNextStep('verified')
      //     } else {
      //       this.$store.commit('user/updateZooAvailable', true)
      //       if (this.user) {
      //         this.proceedNextStep('verified')
      //       } else {
      //         this.checkConsent()
      //       } 
      //     }
      //   })
    },
    checkConsent () {
      this.$axios.get('/zoo/check_fast_checkout_consent', { params :{ phone: this.leadPhone } })
        .then(resp => {
          if (resp.data.has_consent) {
            this.requestOTP()
          } else {
            this.proceedNextStep('consent')
            this.isLoading = false
          }
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
    },
    requestOTPWithConsent () {
      this.requestOTP()
    },
    requestOTP () {
      this.isLoading = true
      if (this.leadPhone === '601777777777') {
        this.proceedNextStep('sendcode')
        return
      }
      this.$axios.post('/users/request_otp/', { phone: this.leadPhone, resource: 'zoo' })
        .then(() => {
          this.proceedNextStep('sendcode')
          this.resendOTPTimer = 30
          const resendInterval = setInterval(() => {
            this.resendOTPTimer--
            if (this.resendOTPTimer === 0) {
              clearInterval(resendInterval)
            }
          }, 1000)
        })
        .catch(() => {
          this.proceedNextStep('verified')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    loginOTP (otp) {
      this.isCheckingOTP = true
      if (this.leadPhone === '601777777777' && otp === '777777') {
        this.$store.commit('user/setDemoUser')
        this.proceedNextStep('verified')
        return
      }
      const data = {
        phone: this.leadPhone,
        otp: otp,
        password: '9cb4afde731e9eadcda4506ef7c65fa2',
        resource: 'zoo'
      }
      this.$axios.post('/users/token/', data)
        .then((resp) => {
          const user = resp.data
          user.auth_phone = this.leadPhone
          this.$store.commit('user/setUser', resp.data)
          this.proceedNextStep('verified')
        }).catch(() => {
          this.codeMessage = 'Your TAC is incorrect or has expired'
        })
        .finally(() => {
          this.isCheckingOTP = false
        })
    },
    createLead (bypass) {
      if (!this.$refs.leadname.validate() && !bypass) {
        this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        return
      }
      this.isLoading = true
      let param = {
        ...this.dataBin,
        phone: this.leadPhone,
        name: this.leadName,
        form: this.dataBin.form.id,
        products: this.finalProducts,
      }
      if (this.orderDateTime.is_ASAP !== undefined && !this.isCourierPlus) {
        param = {...param, ...this.orderDateTime}
      }
      this.axios.post(`/leads/`, param)
        .then((resp) => {
          this.axios.delete(`/databin/${this.dataBin.binKey}/`).catch(() => {})
          this.$axios.get(`/leads/${resp.data.pid}/`)
            .then((resp) => {
              this.$store.commit('current/updateLead', resp.data)
              this.$store.commit('current/updateLeadSignature', resp.data)
              this.proceedNextStep('verified')
              location.href = `/checkout/${resp.data.pid}`
            }).catch(() => {
            this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
            this.isLoading = false
          })
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
          this.isLoading = false
        })
    },
    proceedNextStep (status) {
      this.isLoading = false
      if (!this.lead && status == 'verified') {
        this.$store.commit('current/updatePhoneStatus', 'leadname')
        this.goToNextFlow(1)
        return 
      }
      this.$store.commit('current/updatePhoneStatus', status)
      this.goToNextFlow(1)
    },
    isValidToCheckout () {
      if (this.dataBin) {
        const settings = this.dataBin.settings
        if (settings) {
          if (settings.min_amount && this.totalAmount < settings.min_amount) {
            this.$store.commit('message/showMessage', ['error', `Amount purchase must be at least ${this.$options.filters.displayPrice(settings.min_amount, this.$currency)}`])
            return false
          }
        }
      }
      return true
    }
  }
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: var(--v-primary200);
}
.error--text >>> fieldset {
  border-color: var(--v-danger);
}
</style>