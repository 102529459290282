var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"shrink",staticStyle:{"margin-right":"-2px"}},[_c('v-select',{staticClass:"phoneLeft",staticStyle:{"border-radius":"4px 0 0 4px","padding-right":"0px"},attrs:{"outlined":"","dense":"","hide-details":"","background-color":_vm.bgColor,"items":_vm.countryList,"item-value":"iso"},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"keypress":function($event){return _vm.searchCountry($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mr-1",staticStyle:{"height":"18px"}},[_c('img',{attrs:{"src":require(("@/assets/images/flags/" + (item.iso) + ".png")),"width":"30px","height":"18px"}})]),_c('div',[_vm._v("+"+_vm._s(item.dialCode))])])]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-row',_vm._g({attrs:{"no-gutters":"","align":"center"}},on),[_c('v-col',{staticClass:"shrink pr-1"},[_c('div',{staticStyle:{"height":"18px"}},[_c('img',{attrs:{"src":require(("@/assets/images/flags/" + (item.iso) + ".png")),"width":"30px","height":"18px"}})])]),_c('v-col',{staticClass:"pl-1"},[_c('div',{staticStyle:{"line-height":"20px"}},[_vm._v(_vm._s(item.name)),_c('strong',{staticClass:"ml-2"},[_vm._v("+"+_vm._s(item.dialCode))])])])],1)]}}]),model:{value:(_vm.dialCode),callback:function ($$v) {_vm.dialCode=$$v},expression:"dialCode"}})],1),_c('v-col',[_c('v-text-field',{staticClass:"phoneRight",staticStyle:{"border-radius":"0 4px 4px 0","border-left":"0px"},attrs:{"placeholder":_vm.$t('phone_number'),"outlined":"","dense":"","hide-details":"","background-color":_vm.bgColor,"rules":_vm.dialCode === 'my' ? _vm.rules.phone : _vm.rules.phone_inter,"type":"tel"},on:{"keypress":function($event){return _vm.isNumber($event)},"focus":_vm.onFocus,"blur":_vm.onBlur},model:{value:(_vm.dialNumber),callback:function ($$v) {_vm.dialNumber=$$v},expression:"dialNumber"}})],1)],1),(_vm.phoneAlertMessage)?_c('div',{staticClass:"text-caption ml-3 error--text mt-1",staticStyle:{"height":"26px"}},[_vm._v(_vm._s(_vm.phoneAlertMessage))]):_c('div',{staticClass:"mt-3"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }