<template lang="pug">
  div
    v-card-text.pa-0
      v-row(no-gutters align=center)
        v-col
          div.p2.basic600--text.text-capitalize(style="width: 100px") {{ method }}
        v-col(align="right" v-if="deliveryMethod === 'both'")
          v-btn.button-Aa-small.px-0(v-if="method === 'delivery'" color="transparent" text @click="selectPickup" small)
            div.info500--text {{ $t('switch_to_pickup') }}
          v-btn.button-Aa-small.px-0(v-if="method === 'pickup'" color="transparent" text @click="selectDelivery" small)
            div.info500--text {{ $t('switch_to_delivery') }}
      v-form(ref="form")
        template(v-if="method === 'delivery'")
          v-card.px-2.py-1.mt-4(elevation="0" :color="$vuetify.theme.themes[$theme].dangerT16")
            div.c1.danger700--text {{ zonesAvailable.length === 0 ? 'Delivery is not available for your address' : 'Please select shipping method'}}
          div.mt-4
            template(v-if="zonesAvailable && zonesAvailable.length > 1")
              div.label.basic600--text.text-uppercase {{ $t('select_your_delivery_area') }}
              v-select.mt-2(v-model="selectedZone" :items="zonesAvailable" item-text="name" :placeholder="$t('select_area')" background-color="basic200" return-object outlined dense hide-details)
                template(v-slot:append)
                  eva-icon(name="chevron-down-outline" width="24" height="24" :fill="$vuetify.theme.themes[$theme].basic700")
              .guide.my-2.d-flex
                eva-icon.mr-2(name="info-outline" :fill="$vuetify.theme.themes[$theme].basic600" height="15" width="15" style="margin-top: -2px")
                div.c1.basic600--text {{ $t('do_consult_with', { text: lead.form.company.name }) }}
            v-layout.my-6(v-if="isFetching")
              v-row.fill-height.ma-0(align='center' justify='center')
                v-progress-circular.ma-auto(:width="2" :size="50" color="primary" indeterminate)
            v-card(flat v-else)
              v-divider.basic300
              template(v-if="selectedZone && couriers.length === 0")
                div.p2.px-4.text-center.danger500--text {{ $t('no_couriers_available') }}
              template(v-for="(courier, index) in couriers")
                v-row.py-2(no-gutters :key="JSON.stringify(courier)" @click="!courier.disabled ? selectedCourier = courier : ''")
                  v-col.shrink.pr-2
                    eva-icon(:name="JSON.stringify(selectedCourier) === JSON.stringify(courier) ? 'radio-button-on' : 'radio-button-off'" :fill="JSON.stringify(selectedCourier) === JSON.stringify(courier) ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].basic600" height="20" width="20" )
                  v-col
                    div.d-flex(style="margin-top: 1px")
                      div(style="word-break: break-word")
                        div.p2(:class="courier.disabled ? 'danger500--text' : 'primary--text'") {{ courier.title }}
                        div.c1.basic600--text(v-if="courier.shipping_method == 'easyparcel' && courier.meta_data && courier.meta_data.delivery") {{ courier.meta_data.delivery }}
                        div.c1.basic600--text(v-if="courier.shipping_method == 'zepto' && courier.meta_data && courier.meta_data.distance") {{ courier.meta_data.distance }} km
                        div.c1.basic600--text(v-if="courier.meta_data && courier.meta_data.currency === 'IDR' && courier.meta_data.delivery_time") {{ courier.meta_data.delivery_time }} {{ $t('working_days') }}
                      v-spacer
                      div(v-if="!courier.disabled" align="right")
                        div.p2.primary--text.mr-1(v-if="!courier.disabled") {{ courier.fee | displayPrice($currency) }}
                        v-chip.c2.px-2(v-if="courier.meta_data && courier.meta_data.cod" x-small outlined color="basic600" :style="`background-color: ${$vuetify.theme.themes[$theme].basic600T08} !important`") {{ $t('cod_available') }}
        template(v-if="method === 'pickup'")
          div.my-4
            v-text-field(v-model="shipping_address.name" label="Recipient Name" filled rounded hide-details="auto" :rules="rules.not_empty")
            v-text-field.mt-3(v-model="shipping_address.phone" label="Recipient Phone" filled rounded hide-details="auto" :rules="rules.not_empty")
            template(v-if="pickupZones.length > 1")
              div.label.basic600--text.text-uppercase.mt-5 {{ $t('preferred_pick_up_area') }}
              v-select.mt-2(v-model="selectedZone" :items="pickupZones" item-text="name" return-object :placeholder="$t('select_area')" background-color="basic200" outlined dense hide-details="auto" :rules="rules.not_empty")
                template(v-slot:append)
                  eva-icon(name="chevron-down-outline" width="24" height="24" :fill="$vuetify.theme.themes[$theme].basic700")
            v-row.mt-6(no-gutters v-if="selectedZone")
              v-col.shrink.pr-2
                eva-icon(name="pin-outline" :fill="$vuetify.theme.themes[$theme].primary500" height="22" width="22" )
              v-col
                linkify-text.s1.primary--text(:text="selectedZone.pickup_address")
            div.mt-8(v-else)
              div.c1.basic600--text.text-center {{ $t('your_pick_up_location_will') }}
    v-card-actions.px-0.pb-0.pt-2
      v-btn.button-AA-medium(color="info500" small height="40" block @click="saveShippingFee" :loading="isLoading" :dark="Boolean(selectedCourier)" :disabled="!selectedCourier")
        div(:class="{ 'white--text': selectedCourier }") {{ $t('save_shipping_method') }}
</template>

<script>
import { mapGetters } from 'vuex'
import EditDelivery from '@/components/dialogs/EditDelivery.vue'
import EditPickup from '@/components/dialogs/EditPickup.vue'
import LinkifyText from '@/components/custom/LinkifyText.vue'

export default {
  name: 'CollapseShipping',
  components: { EditDelivery, EditPickup, LinkifyText },
  data: () => ({
    method: 'delivery',
    isFetching: false,
    isLoading: false,
    shipping_address: {},
    selectedZone: null,
    selectedCourier: null,
    couriers: [],
    rules: {
      not_empty: [v => !!v || 'This field is required']
    }
  }),
  computed: {
    ...mapGetters({
      cart: 'cart/getCart',
      zones: 'current/getZones',
      tempShipping: 'current/getTempShipping',
      lead: 'current/getLead'
    }),
    deliveryMethod () {
      if (this.zones.filter(f => f.shipping_methods.some(s => s !== 'pickup')).length > 0 && this.zones.filter(f => f.shipping_methods.includes('pickup') && f.pickup_address).length > 0) {
        return 'both'
      } else if (this.zones.filter(f => f.shipping_methods.some(s => s !== 'pickup')).length > 0) {
        return 'delivery'
      } else if (this.zones.filter(f => f.shipping_methods.includes('pickup') && f.pickup_address).length > 0) {
        return 'pickup'
      } else {
        return 'error'
      }
    },
    pickupZones () {
      return this.zones.filter(f => f.shipping_methods.includes('pickup') && f.pickup_address)
    },
    zonesAvailable () {
      let tempZones = []
      if (this.lead.shipping_address && this.lead.shipping_address.city) {
        if (this.zones.length > 0) {
          this.zones.forEach(o => {
            if (o.shipping_methods.some(s => s !== 'pickup')) {
              if (!o.cities) {
                tempZones.push(o)
              } else if (o.cities.includes(this.lead.shipping_address.city)) {
                tempZones.push(o)
              }
            }
          })
        }
      }
      return tempZones    
    }
  },
  watch: {
    selectedZone: {
      immediate: true,
      handler (val) {
        if (val) {
          this.selectedCourier = null
          this.fetchCouriers()
        } else {
          this.couriers = []
          this.selectedCourier = null
        }
      }
    },
    cart () {
      if (this.selectedZone) {
        this.selectedCourier = null
        this.fetchCouriers()
      }
    },
    'lead.shipping_address.city' () {
      this.selectedZone = null
      this.checkZones()
    }
  },
  mounted () {
    this.checkZones()
    this.$set(this.shipping_address, 'phone', this.lead.shipping_address ? this.lead.shipping_address.phone : '')
    this.$set(this.shipping_address, 'name', this.lead.shipping_address ? this.lead.shipping_address.name : '')
    if (this.deliveryMethod === 'pickup') {
      this.selectPickup()
    }
  },
  methods: {
    selectDelivery () {
      this.selectedZone = null
      this.method = 'delivery'
      this.checkZones()
    },
    selectPickup () {
      this.selectedZone = null
      this.method = 'pickup'
      if (this.pickupZones.length === 1) {
        this.selectedZone = { ...this.pickupZones[0] }
      }
    },
    checkZones () {
      if (this.zonesAvailable && this.zonesAvailable.length === 1) {
        this.selectedZone = this.zonesAvailable[0]
      }
    },
    fetchCouriers () {
      this.isFetching = true
      let pickup = 'false'
      if (this.method === 'pickup') {
        pickup = 'true'
      }
      this.$axios.get(`/shipping/fees/${this.lead.pid}/?zone=${this.selectedZone.id}&pickup=${pickup}`)
        .then((response) => {
          if (pickup === 'true') {
            this.selectedCourier = { ...response.data[0] }
          } else {
            this.couriers = [...response.data]
            if (this.couriers.length == 1 && !this.couriers[0].disabled) {
              this.selectedCourier = this.couriers[0]
            }
          }
        })
        .catch(e => {
          if (e.response && e.response.data && e.response.data.message) {
            this.errorMessage = e.response.data.message
          } else {
            this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
          }
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    saveShippingFee () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.isLoading = true
      let params = {
        fee: this.selectedCourier.fee,
        lead: this.lead.id,
        shipping_method: this.selectedCourier.shipping_method,
        meta_data: this.selectedCourier.meta_data,
        signature: this.selectedCourier.signature,
        zone: this.selectedZone.id
      }
      if (this.tempShipping) {
        this.axios.patch(`/shipping/shippings/${this.tempShipping.id}/`, params)
          .then(() => {
            this.$store.commit('current/updateRemarksShipping', this.selectedCourier)
            this.$store.commit('current/updateTempShipping', null)
            if (this.method === 'pickup') {
              this.saveAddress()
            } else {
              this.updateCurrentLead()
              this.goToNextFlow(1)
            }
          })
          .catch(() => {
            this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
          })
          .finally(() => {
            if (this.method === 'pickup') {
              this.isLoading = false
            }
          })
      } else {
        this.axios.post(`/shipping/shippings/`, params)
          .then(() => {
            this.$store.commit('current/updateRemarksShipping', this.selectedCourier)
            if (this.method === 'pickup') {
              this.saveAddress()
            } else {
              this.updateCurrentLead()
              this.goToNextFlow(1)
            }
          })
          .catch(() => {
            this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
          })
          .finally(() => {
            if (this.method === 'pickup') {
              this.isLoading = false
            }
          })
      }
    },
    saveAddress () {
      let param = { shipping_address: this.shipping_address }
      this.axios.patch(`/leads/${this.lead.pid}/`, param)
        .then(() => {
          this.updateCurrentLead()
          this.goToNextFlow(1)
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to update your shipping method. Please try again later'])
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: var(--v-primary200);
}
::v-deep .v-text-field--filled > .v-input__control > .v-input__slot{
  background: #F7F7F7 !important;
  border: 1px solid #eaeaea !important;
  border-radius: 4px;
  max-height: 48px;
  min-height: 48px;
  padding-left: 12px;
}
::v-deep .v-text-field.v-input--has-state > .v-input__control > .v-input__slot{
  border: 1px solid var(--v-danger) !important;
}
::v-deep .v-text-field--filled:not(.v-text-field--single-line) input{
  margin-top: 14px;
}
::v-deep .v-text-field--filled .v-label{
  top: 14px !important;
}
::v-deep .v-text-field--filled .v-label--active{
  transform: translateY(-10px) scale(0.75);
}
::v-deep .v-input--has-state.error--text{
  color: red;
}
</style>