<template lang="pug">
  v-card.pa-4(flat :class="[{ 'hide-top-corner-radius': params.hideTopCorner }, { 'hide-bottom-corner-radius': params.hideBottomCorner }]")
    v-row(no-gutters :align="phoneStatus === 'verified' ? 'start' : 'center'")
      v-col(cols="4")
        div.p2.basic600--text {{ $t('contact') }}
      v-col
        div.mb-2.p2.primary--text(v-if="phoneStatus === 'verified'") {{ getLeadName() }}
        div.p2.primary--text {{ getLeadPhone() | phoneFormat }}
      v-col(v-if="phoneStatus !== 'verified'" cols="2" align="right")
        v-btn.button-Aa-small.px-0(color="transparent" text @click="clickEditPhone()" small)
          div.info500--text {{ $t('edit') }}

</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CustomerPhone',
  props: {
    params: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      order: 'current/getOrder',
      lead: 'current/getLead',
      phoneStatus: 'current/getPhoneStatus',
    }),
  },
  methods: {
    getLeadName () {
      if (this.lead) {
        return this.lead.name
      }
      return this.order.leadName
    },
    getLeadPhone () {
      if (this.lead) {
        return this.lead.phone
      }
      return this.order.leadPhone
    },
    clickEditPhone () {
      this.goToNextFlow(-1)
      this.$store.commit('current/updatePhoneStatus', 'uncheck')
    }
  }
}
</script>

<style scoped>
</style>