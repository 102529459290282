<template lang="pug">
  div
    v-dialog(v-model="showModal" scrollable transition="fade-transition" persistent max-width="600")
      v-card
        v-card-title.pa-4
          div.h6.primary--text(v-if="hideFields") {{ $t('enter_your_postcode') }}
          div.h6.primary--text(v-else) {{ parentAddress ? $t('edit_shipping_address') : $t('add_shipping_address') }}
          v-spacer
          v-btn(small icon @click="closeDialog")
            v-icon(color="primary") mdi-close
        v-card-text.pa-4
          v-form(ref="form")
            template(v-if="!hideFields")
              v-text-field(v-model="address.name" label="Name" filled rounded hide-details="auto" :rules="rules.not_empty")
              v-text-field.mt-3(v-model="address.phone" label="Phone" filled rounded hide-details="auto" :rules="rules.not_empty")
              v-text-field.mt-3(v-model="address.address_1" label="Address 1" filled rounded hide-details="auto" :rules="rules.not_empty")
              v-text-field.mt-3(v-model="address.address_2" label="Address 2 (optional)" filled rounded hide-details="auto")
            v-text-field(v-model="address.postcode" :class="hideFields ? '' : 'mt-3'" label="Postcode" filled rounded hide-details="auto" :rules="rules.not_empty" @keyup="fetchPostcodes(address.postcode)")
            template(v-if="!hideFields")
              v-text-field.mt-3(v-model="address.city" label="City" filled rounded hide-details="auto" :rules="rules.not_empty" :loading="isLoading")
              v-text-field.mt-3(v-model="address.state" label="State" filled rounded hide-details="auto" :rules="rules.not_empty" :loading="isLoading")
              v-text-field.mt-3(v-model="address.country" label="Country" filled rounded hide-details="auto" :rules="rules.not_empty" :loading="isLoading")
            div.p1.danger500--text.text-center.mt-3.py-2(v-if="hideFields") {{ message }}
        v-card-actions.pa-4(v-if="!hideFields")
          v-btn.button-AA-medium.white--text(color="success500" dark small height="40" block @click="saveAddress" :loading="isLoading2") {{ source === 'pickup' ? $t('continue_to_shipping_method') : $t('save_address') }} 
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AddShippingAddress',
  props: ['open', 'parentAddress', 'source'],
  data: () => ({
    isLoading: false,
    isLoading2: false,
    hideFields: false,
    message: null,
    address: {},
    rules: {
      not_empty: [v => !!v || 'This field is required']
    }
  }),
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      restrictedCities: 'current/getRestrictedCities',
      lead: 'current/getLead'
    }),
    showModal: {
      get() {
        return this.open
      },
      set(newValue) {
        this.$emit('update:open', newValue)
      }
    }
  },
  mounted () {
    if (this.parentAddress) {
      this.address = { ...this.parentAddress }
    } else {
      if (this.restrictedCities) {
        this.hideFields = true
      }
      if (this.lead.shipping_address) {
        this.address = {
          name: this.lead.shipping_address.name,
          phone: this.lead.shipping_address.phone
        }
      } else {
        this.address = {
          name: this.lead.name,
          phone: this.lead.phone
        }
      }
    }
  },
  methods: {
    fetchPostcodes (val) {
      if (!val) {
        return
      }
      if (val.length !== 5) {
        return
      }
      this.isLoading = true
      this.$axios.get(`/shipping/postcodes?postcode=${this.address.postcode}`).then((response) => {
        const addresses = response.data
        if (this.restrictedCities && this.restrictedCities.length > 0) {
          if (addresses.length > 0 && addresses[0].postcode) {
            if (this.restrictedCities.includes(addresses[0].city)) {
              this.$set(this.address, 'city', addresses[0].city)
              this.$set(this.address, 'state', addresses[0].state)
              this.$set(this.address, 'country', addresses[0].country)
              this.hideFields = false
            } else {
              this.hideFields = true
              this.message = 'Delivery is not available for your address'
            }
          }
        } else {
          this.hideFields = false
          if (addresses.length > 0 && addresses[0].postcode) {
            this.$set(this.address, 'city', addresses[0].city)
            this.$set(this.address, 'state', addresses[0].state)
            this.$set(this.address, 'country', addresses[0].country)
          }
        }
      })
      .catch(() => {
      })
      .finally(() => {
        this.isLoading = false
      })
    },
    saveAddress () {
      if (!this.$refs.form.validate()) {
        return
      }
      if (this.parentAddress) {
        if (this.source === 'select') {
          if (this.user) {
            this.isLoading2 = true
            if (this.parentAddress.id === 'main') {
              this.$axios.patch(`/zoo/${this.user.profile}`, { shipping_address: this.address })
                .then(resp => {
                  this.$emit('edit', resp.data.shipping_address)
                })
                .catch(() => {
                  this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
                })
                .finally(() => {
                  this.isLoading2 = false
                })
            } else {
              this.$axios.patch(`/zoo/${this.user.profile}/addresses/${this.parentAddress.id}`, this.address)
                .then(resp => {
                  this.$emit('edit', resp.data)
                })
                .catch(() => {
                  this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
                })
                .finally(() => {
                  this.isLoading2 = false
                })
            }
          } else {
            this.$emit('edit', this.address)
          }
        } else {
          this.isLoading2 = true
          let param = { shipping_address: this.address }
          if (this.lead.name === 'Guest') {
            param.name = this.address.name
          }
          this.axios.patch(`/leads/${this.lead.pid}/`, param)
            .then(resp => {
              const checkCity = this.lead.shipping_address.city
              if (checkCity !== resp.data.shipping_address.city) {
                this.$store.commit('current/updateTempShipping', resp.data.shipping)
              }
              this.updateCurrentLead()
              if (this.user) {
                if (this.parentAddress.id === 'main') {
                  this.$axios.patch(`/zoo/${this.user.profile}`, { shipping_address: this.address })
                    .then(resp => {
                      this.$emit('edit', resp.data.shipping_address)
                    })
                    .catch(() => {
                      this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
                    })
                    .finally(() => {
                      this.isLoading2 = false
                    })
                } else {
                  this.$axios.patch(`/zoo/${this.user.profile}/addresses/${this.parentAddress.id}`, this.address)
                    .then(resp => {
                      this.$emit('edit', resp.data)
                    })
                    .catch(() => {
                      this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
                    })
                    .finally(() => {
                      this.isLoading2 = false
                    })
                }
              } else {
                this.$emit('edit', this.address)
              }
            })
            .catch(() => {
              this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
            })
            .finally(() => {
              if (!this.user) {
                this.isLoading2 = false
              }
            })
        }
      } else {
        if (this.user) {
          this.isLoading2 = true
          this.axios.post(`/zoo/${this.user.profile}/addresses`, this.address)
            .then((resp) => {
              this.$emit('add', resp.data)
            })
            .catch(() => {
              this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
            })
            .finally(() => {
              this.isLoading2 = false
            })
        } else {
          this.$emit('add', this.address)
        }
      }
    },
    closeDialog () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
::v-deep .v-text-field--filled > .v-input__control > .v-input__slot{
  background: #F7F7F7 !important;
  border: 1px solid #eaeaea !important;
  border-radius: 4px;
  max-height: 48px;
  min-height: 48px;
  padding-left: 12px;
}
::v-deep .v-text-field.v-input--has-state > .v-input__control > .v-input__slot{
  border: 1px solid var(--v-danger) !important;
}
::v-deep .v-text-field--filled:not(.v-text-field--single-line) input{
  margin-top: 14px;
}
::v-deep .v-text-field--filled .v-label{
  top: 14px !important;
}
::v-deep .v-text-field--filled .v-label--active{
  transform: translateY(-10px) scale(0.75);
}
::v-deep .v-input--has-state.error--text{
  color: red;
}
</style>