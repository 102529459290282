<template lang="pug">
  .order-confirmation.basic100(:style="`height: ${screenHeight}px`")
    div.basic100.py-1(dense flat)
      v-container(style="max-width: 600px")
        .h6.primary500--text {{ $t('order_confirmation') }}
    
    div(v-if="order.hasZoo" dense flat :style="`background-color: ${$vuetify.theme.themes[$theme].infoT08}`")
      v-container.pa-4(style="max-width: 600px")
        div
          .s2 {{ $t('zoo_account_created') }}
          .c1 {{ $t('enjoy_faster_checkout') }}&nbsp;
            span.textColorAccent--text.clickable(@click="clickViewZooAp") {{ $t('learn_more') }}. 
    v-container.pa-0(style="max-width: 600px")
      .content.basic100
        v-img.mx-auto(:src="require('@/assets/images/bg_order_confirmation2.gif')" style="height:150px" contain)
        div.text-center
          .h6.basic700--text.my-4(v-html="order.title")
          .s1.basic600--text.my-2(v-if="order.thankyouMessage" style="white-space: pre-wrap;") {{ order.thankyouMessage }}
          .p1 {{ $t('order') }} &#35;{{ lead.rid }}
          .p1.mt-1(v-html="order.description")
          v-btn.button-AA-small.basic100--text.my-4(color="textColorAccent" depressed @click="clickViewReceipt") {{ $t('view_my') }} {{ orderName }}
      //- div.pa-4(:style="`background-color: ${$vuetify.theme.themes[$theme].infoT08}`")
      //-   .s2 Create your 6-digit Zoo PIN number for a quicker sign in at your next checkout
      //-   v-row.mt-0
      //-     v-col
      //-       v-text-field(color="primary" v-model="codeInput" type="password" placeholder="6-digit PIN" outlined background-color="basic100" @keyup.enter="signin" dense :append-icon="showPasword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPasword = !showPasword" :type="showPasword ? 'text' : 'password'")
      //-     v-col.shrink
      //-       v-btn.button-AA-small.px-8(color="textColorAccent" block outlined :style="{'background-color': $vuetify.theme.themes[$theme].infoT08}") Save
      //- v-footer.pa-0.basic700(app)
        v-container.px-4.py-3(style="max-width: 600px")
          div.d-flex
            div.s1.basic100--text {{ $t('now_you_can_get_notified') }}
            v-spacer
            v-btn.button-Aa-small.basic100--text.ml-4(color="textColorZoo" depressed @click="clickViewZooAp") {{ $t('view_in_zoo') }}
          div.c1.basic100--text.mt-1 {{ $t('download_zoo_get_notifications') }}
    
    .modals
      product-invoice(v-if="openInvoiceDialog" :open.sync="openInvoiceDialog")

</template>

<script>
import { mapGetters } from 'vuex'
import ProductInvoice from '@/components/ProductInvoice.vue'

export default {
  name: 'OrderConfirmation',
  components: { ProductInvoice },
  data: () => ({
    openInvoiceDialog: false,
    codeInput: '',
    showPasword: false,
    showZooCreatedBanner: false,
  }),
  computed: {
    ...mapGetters({
      lead: 'current/getLead'
    }),
    orderName () {
      if (!this.lead.paid) {
        return 'invoice'
      }
      return 'receipt'
    },
    order () {
      if (this.lead.cod || this.lead.pay_at_shop) {
        return { title: 'We have received your order!', description: `View and save the full ${this.orderName} for<br> your reference or to make payment`, hasZoo: this.showZooCreatedBanner, thankyouMessage: this.lead.order_remarks.thankyou_message }
      }
      if (!this.lead.paid && this.lead.order_remarks.payment_method) {
        if (this.lead.order_remarks.payment_method.id === 'manual') {
          return { title: 'Order received!<br>Just waiting for seller to confirm your payment', description: `View and save the full ${this.orderName} for<br> your reference`, hasZoo: this.showZooCreatedBanner, thankyouMessage: this.lead.order_remarks.thankyou_message }
        }
      }
      return { title: 'Thank you for your purchase!', description: `View and save the full ${this.orderName} for<br> your reference`, hasZoo: this.showZooCreatedBanner, thankyouMessage: this.lead.order_remarks.thankyou_message }
    },
    hasTackingCode () {
      if (this.lead.shipping && (this.lead.shipping.tracking_id || this.lead.shipping.shipping_method === 'easyparcel')) {
        return true
      }
      return false
    },
    screenHeight () {
      return window.innerHeight - 92
    }
  },
  mounted () {
    this.$axios.get('/zoo/check_fast_checkout_consent', { params :{ phone: this.lead.phone } })
      .then(resp => {
        if (resp.data.has_consent) {
          this.showZooCreatedBanner = true
        }
      })
    if (this.hasTackingCode) {
      this.updateTracking()
    }
  },
  methods: {
    clickViewReceipt () {
      this.openInvoiceDialog = true
    },
    clickViewZooAp () {
      window.open("http://onelink.to/hzsjsu")
    },
    updateTracking () {
      this.axios.post(`/shipping/shippings/${this.lead.shipping.id}/update-tracking`, {}).catch(() => {})
    }
  }
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: var(--v-primary200);
}
.error--text >>> fieldset {
  border-color: var(--v-danger);
}

</style>
