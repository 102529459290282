<template lang="pug">
  div
    v-row(no-gutters)
      v-col.shrink(style="margin-right: -2px")
        v-select.phoneLeft(v-model="dialCode" outlined dense hide-details :background-color="bgColor" @focus="onFocus" @blur="onBlur" @keypress="searchCountry($event)" :items="countryList" item-value="iso" style="border-radius: 4px 0 0 4px; padding-right: 0px")
          template(v-slot:selection="{ item, index }")
            div.d-flex.align-center
              div.mr-1(style="height: 18px;")
                img(:src="require(`@/assets/images/flags/${item.iso}.png`)" width="30px" height="18px")
              div +{{ item.dialCode }}
          template(v-slot:item="{ item, on, attrs }")
            v-row(no-gutters v-on="on" align="center")
              v-col.shrink.pr-1
                div(style="height: 18px;")
                  img(:src="require(`@/assets/images/flags/${item.iso}.png`)" width="30px" height="18px")
              v-col.pl-1
                div(style="line-height: 20px;") {{ item.name }}
                  strong.ml-2 +{{ item.dialCode }}
      v-col
        v-text-field.phoneRight(v-model="dialNumber" :placeholder="$t('phone_number')" outlined dense hide-details :background-color="bgColor" :rules="dialCode === 'my' ? rules.phone : rules.phone_inter" @keypress="isNumber($event)" type="tel" @focus="onFocus" @blur="onBlur" style="border-radius: 0 4px 4px 0; border-left: 0px")
    div.text-caption.ml-3.error--text.mt-1(v-if="phoneAlertMessage" style="height: 26px;") {{ phoneAlertMessage }}
    div.mt-3(v-else)
</template>

<script>
import { DateTime } from 'luxon'
import { countries } from '@/assets/phoneCodeCountries.js'
export default {
  name: 'PhoneInput',
  props: {
    value: {
      type: String,
      default: null
    }
  },
  data: () => ({
    dialCode: 'my',
    dialNumber: '',
    searchString: '',
    phoneAlertMessage: '',
    filteredCountry: [],
    rules: {
      not_empty: [val => (val || '').length > 0 || 'This field is required'],
      phone: [
        v => !!v || 'Phone number is required',
        v => /^(01)[0-46-9]-*[0-9]{7,8}$/.test(v) || 'Phone number must be valid'
      ],
      phone_inter: [v => !!v || 'Phone number is required']
    },
    bgColor: 'basic200'
  }),
  computed: {
    countryList () {
      if (this.filteredCountry.length > 0) {
        return this.filteredCountry
      }
      return countries
    },
    phoneNumber () {
      let dialcode = 6
      const filteredCountry = this.countryList.filter(f => f.iso === this.dialCode)
      if (filteredCountry.length > 0) {
        dialcode = filteredCountry[0].dialCode
      }
      if (this.dialNumber.startsWith(dialcode)) {
        return this.dialNumber
      }
      return dialcode + this.dialNumber
    }
  },
  watch: {
    dialNumber () {
      this.phoneValidation()
    },
    dialCode () {
      this.phoneValidation()
    },
    phoneNumber: {
      immediate: true,
      handler (val) {
        this.$emit('input', val)
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.value) {
        const num = this.value
        for (let i = 4; i > 0; i--) {
          const country = this.countryList.filter(f => f.dialCode === num.substr(0, i) && f.priority === 0)
          if (country.length > 0) {
            this.dialCode = country[0].iso
            this.dialNumber = num.substr(i)
            break
          }
        }
      }
      if (!this.dialNumber) {
        const zoneId = DateTime.local().toFormat('z')
        const country = this.$options.filters.getCountryCodeFromZoneId(zoneId)
        const phoneCountry = this.countryList.filter(f => f.isoUpper === country)
        if (phoneCountry.length > 0) {
          this.dialCode = phoneCountry[0].iso
        }
      }
    })
  },
  methods: {
    isNumber (evt) {
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    onBlur () {
      this.$emit('blur', true)
      this.searchString = ''
      this.filteredCountry = []
      this.bgColor = 'basic200'
      if (!this.dialNumber) {
        this.phoneAlertMessage = 'Phone number is required'
      }
    },
    onFocus () {
      this.bgColor = 'white'
      this.$emit('focus', false)
    },
    searchCountry (evt) {
      if (this.searchString) {
        this.searchString += evt.key
        this.filteredCountry = countries.filter(f => f.name.toLowerCase().slice(0, this.searchString.length) === this.searchString)
        if (this.filteredCountry.length === 0) {
          this.searchString = ''
        }
      } else {
        this.searchString = evt.key
        this.filteredCountry = countries.filter(f => f.name.toLowerCase().slice(0, this.searchString.length) === this.searchString)
        if (this.filteredCountry.length === 0) {
          this.searchString = ''
        }
      }
    },
    phoneValidation () {
      if (this.dialCode === 'my') {
        if (!this.dialNumber) {
          this.phoneAlertMessage = 'Phone number is required'
        } else if (/^(01)[0-46-9]-*[0-9]{7,8}$/.test(this.dialNumber)) {
          this.phoneAlertMessage = ''
        } else {
          this.phoneAlertMessage = 'Phone number must be valid'
        }
      } else if (!this.dialNumber) {
        this.phoneAlertMessage = 'Phone number is required'
      } else {
        this.phoneAlertMessage = ''
      }
    }
  }
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: var(--v-primary200) !important;
}
/* .error--text >>> fieldset {
  border-color: var(--v-danger);
} */
.phoneLeft >>> fieldset {
  /* border-right: 1px solid !important; */
  border-right: 0 !important;
}
.phoneRight >>> fieldset {
  /* border-left: 1px solid !important; */
  border-left: 0 !important;
}
.phoneLeft >>> div {
  padding-right: 0px !important;
}
</style>