<template lang="pug">
  div.d-flex
    div.s1.basic600--text {{ getCompanyName() }}
    v-spacer
    v-btn.button-AA-tiny(v-if="phoneStatus === 'verified'" outlined x-small height="24" color="textColorHint" @click="clickAskMerchat()") {{ $t('contact_seller') }}
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CompanyName',
  computed: {
    ...mapGetters({
      dataBin: 'current/getDataBin',
      lead: 'current/getLead',
      phoneStatus: 'current/getPhoneStatus',
    }),
  },
  methods: {
    getCompanyName () {
      if (this.dataBin) {
        return this.dataBin.form.company.name
      }
      if (this.lead) {
        return this.lead.form.company.name
      }
      return ''
    },
    clickAskMerchat () {
      window.open(`https://api.whatsapp.com/send?phone=${this.lead.member.phone}`, '_blank')
    }
  }
}
</script>

<style scoped>
</style>