<template lang="pug">
  .product-invoice
    v-dialog(fill-height fluid v-model="showModal" scrollable :fullscreen="$vuetify.breakpoint.xsOnly" transition="fade-transition" persistent max-width="600px")
      v-card(min-height="80vh")
        v-card-title.px-3.white
          v-row(no-gutters align="center")
            v-col.shrink
              v-btn(text icon @click="showModal = !showModal" height)
                v-icon(color="basic800" :size="24") mdi-close
            v-col.ml-3
              div.d-flex
                p.mb-0.h6.basic800--text {{ lead.paid ? 'Receipt' : 'Invoice' }} &#35;{{ lead.rid }}
        
        v-layout.my-6(v-if="isFetching")
          v-row.fill-height(no-gutters align='center' justify='center')
            v-progress-circular.ma-auto(:width="2" :size="50" color="primary" indeterminate)
        
        v-card-text.px-0(v-else-if="invoice")

          div.pa-4(:style="`background-color: ${banner.backgroundColor}`")
            v-row(no-gutters)
              v-col.shrink
                //- v-btn(icon :style="`background-color: ${banner.backgroundColor}`" depressed)
                eva-icon.mr-3(:name="banner.iconName" width="32" height="32" :fill="$vuetify.theme.themes[$theme].basic100")
              v-col
                .s1.basic100--text {{ banner.title }}
                .c1.basic100--text {{ banner.description }}&nbsp;
                  span.c2.basic100--text.clickable(@click="clickViewZooAp") {{ banner.action }}
          
          div.pa-4(v-if="lead.shipping && lead.pickup")
            v-row.my-1(no-gutters)
              v-col
                .c2.basic600--text {{ $t('pick_up_at') }}
                linkify-text(v-if="(typeof lead.shipping.tracking.pickup_address) === 'object'" :text="getStringFromAddress(lead.shipping.tracking.pickup_address)")
                linkify-text.h6(v-else :text="lead.shipping.tracking.pickup_address")
              v-col.shrink(align="center")
                v-btn(icon @click="clickAskMerchat()")
                  v-icon(size="28px" color="basic600") mdi-whatsapp
            
            v-divider.basic300

          .content.pa-4.mt-2
        
            .s1 {{ $t('your_order') }}:

            v-simple-table(dense)
              thead
                tr
                  th.pa-0(style="border: none")
                    div.s2.primary400--text.text-start.mr-1 {{ $t('description') }}
                  th.pa-0(style="border: none")
                    div.s2.primary400--text.text-end.mx-1 {{ $t('price') }}
                  th.pa-0(style="border: none")
                    div.s2.primary400--text.text-end.ml-1 {{ $t('amount') }}
              tbody
                template(v-for="p in invoice.products")
                  tr(:key="p.id")
                    td.text-start.pa-0(style="border: none; vertical-align:top;")
                      div.my-1
                        div.p2.primary500--text.mr-1 {{ p.quantity }} x {{ getName(p) }} {{ p.addons.length > 0 ? ' set' : '' }}
                        div.c1.danger500--text(v-if="p.product.inventory_enabled && p.product.inventory_qty < p.quantity && !lead.paid") {{ p.product.inventory_qty > 0 ? $t('only_count_left_in_stock', { count: p.product.inventory_qty }) : $t('sold_out') }}
                        div.ml-3
                          //- VARIANT NAME
                          template(v-if="sameVariantParentName(p)")
                            div.c1.basic600--text.mt-1.mr-1 {{ p.product.name }}
                          //- ADDON LIST
                          template(v-if="p.addons.length > 0")
                            div.c1.primary500--text.mt-1.mr-1 {{ p.name }}
                            div.mt-1(v-for="(a, idx) in p.addons" :key="'addon-' + idx")
                              div.c1.basic600--text.mr-1 + {{ a.name }}{{ a.quantity_per_set > 1 ? ' x ' + a.quantity_per_set : '' }}
                              div.c1.danger500--text(v-if="a.inventory_enabled && a.inventory_qty < a.quantity && !lead.paid") {{ a.inventory_qty > 0 ? `Only ${a.inventory_qty} left in stock` : 'Sold out' }}
                          //- BUNDLE LIST
                          template(v-if="p.bundles.length > 0")
                            div.mt-1(v-for="(b, idx) in p.bundles" :key="'bundle-' + idx")
                              div.c1.basic600--text.mr-1 {{ idx + 1 }}. {{ b.name }}
                              div.c1.danger500--text(v-if="b.product.inventory_enabled && b.product.inventory_qty < b.quantity && !lead.paid") {{ b.product.inventory_qty > 0 ? $t('only_count_left_in_stock', { count: a.inventory_qty }) : $t('sold_out') }}
                          //- PRODUCT NOTES
                          div.mt-1(v-if="p.product_notes_data && p.product_notes_data.length > 0")
                            div(v-for="note in p.product_notes_data" :key="note.id")
                              div.c1.primary400--text
                                strong {{ note.name }}: 
                                | {{ note.data }}
                    td.text-end.pa-0(style="border: none; min-width: 70px; vertical-align:top;")
                      div.my-1
                        div.p2.primary500--text.mx-1 {{ p.price_per_set | displayPrice($currency) }}
                        div.mt-3(v-if="p.product.inventory_enabled && p.product.inventory_qty < p.quantity && !lead.paid")
                        template(v-if="p.addons.length > 0")
                          div.c1.primary300--text.mx-1 {{ p.price | displayPrice($currency) }}
                        template(v-for="a in p.addons")
                          div.c1.primary300--text.mx-1 {{ a.price * a.quantity_per_set | displayPrice($currency) }}
                    td.text-end.pa-0(style="border: none; min-width: 70px; vertical-align:top;")
                      div.my-1.p2.primary500--text.ml-1 {{ p.price_per_set * p.quantity | displayPrice($currency) }}

            div.my-5.dotted

            div
              div.d-flex.mt-1(v-if="discountPrice")
                div.p2.primary400--text {{ $t('discount') }}:
                v-spacer
                div.p2.primary400--text {{ discountPrice | displayPrice($currency) }}
              div.d-flex.mt-1
                div.p2.primary400--text {{ $t('subtotal') }}:
                v-spacer
                div.p2.primary400--text {{ subtotalPrice | displayPrice($currency) }}
              div.d-flex.mt-1
                div.p2.primary400--text {{ $t('tax') }}:
                v-spacer
                div.p2.primary400--text {{ taxAmount }}%
              div.d-flex.align-center.mt-1(v-if="shippingFee !== null && !lead.pickup")
                div.p2.primary400--text {{ $t('delivery_charge') }}:
                v-spacer
                div.p2.primary400--text(v-if="shippingFee === 0")
                  v-chip.px-1(color="success500" small label outlined)
                    v-icon(small).pr-1 mdi-truck-fast
                    | {{ $t('free_shipping') }}
                div.p2.primary400--text(v-else) {{ shippingFee | displayPrice($currency) }}
              div.d-flex.mt-1(v-if="!lead.paid && lead.cod")
                div.s1.danger500--text {{ $t('total_due_upon_delivery') }}:
                v-spacer
                div.s1.danger500--text {{ totalPrice | displayPrice($currency) }}
              div.d-flex.mt-1(v-else-if="!lead.paid && lead.pay_at_shop")
                div.s1.danger500--text {{ $t('total_due_at_pickup') }}:
                v-spacer
                div.s1.danger500--text {{ totalPrice | displayPrice($currency) }}
              div.d-flex.mt-1(v-else)
                div.s1.primary--text {{ $t('total') }}:
                v-spacer
                div.s1.primary--text {{ totalPrice | displayPrice($currency) }}
              div.d-flex.mt-1(v-if="promoDiscountPrice")
                div.p2.primary400--text {{ $t('promo_discount') }}:
                v-spacer
                div.p2.primary400--text {{ promoDiscountPrice | displayPrice($currency) }}

          v-divider.basic300.pt-1.mt-4

          .partial(v-if="!lead.paid && totalPaid")
            .content.px-4.pt-2
              div.d-flex.mt-1
                div.p2.primary400--text {{ $t('amount_paid') }}:
                v-spacer
                div.p2.primary400--text {{ totalPaid | displayPrice($currency) }}
              div.d-flex.mt-1
                div.s1.danger500--text {{ $t('balance_due') }}:
                v-spacer
                div.s1.danger500--text {{ amountDue | displayPrice($currency) }}
              div.c1.primary400--text.mt-1 {{ $t('please_complete_payment_before') }}: {{ invoice.installment_deadline | humanizeDate }}

            v-divider.basic300.pt-1.mt-4

          .content.pa-4.mt-2
        
            div.d-flex
              div(style="word-break: break-word")
                div.s2.basic700--text {{ company.name }}
                div.p2.primary500--text {{ lead.member.phone | phoneFormat }}
              v-spacer
              div
                v-chip.text-uppercase.my-auto.button-AA-tiny(small outlined label color="success500" :style="{ 'background-color': $vuetify.theme.themes[$theme].successT08 + '!important'}" @click="clickAskMerchat()")
                  v-icon(left small) mdi-whatsapp
                  | {{ $t('whatsapp_us') }}
            
            div.s2.basic600--text.mt-4 {{ $t('order_made_on') }} {{ lead.created | convertServerDate }}

            .address.mt-4
              v-row(no-gutters)
                v-col(v-if="lead.shipping_address && !lead.pickup")
                  .p2.basic600--text {{ $t('shipping_address') }}
                  .p2.primary500--text {{ lead.shipping_address.name }}
                  .p2.primary500--text {{ lead.shipping_address.phone | phoneFormat }}
                  .p2.primary500--text {{ lead.shipping_address.address_1 }},
                  .p2.primary500--text(v-if="lead.shipping_address.address_2") {{ lead.shipping_address.address_2 }},
                  template(v-if="lead.shipping_address.area")
                    .p2.primary500--text {{ lead.shipping_address.postcode }} {{ lead.shipping_address.area }},
                    .p2.primary500--text {{ lead.shipping_address.suburb }} {{ lead.shipping_address.city }},
                  template(v-else)
                    .p2.primary500--text {{ lead.shipping_address.postcode }} {{ lead.shipping_address.city }},
                  .p2.primary500--text {{ lead.shipping_address.state }}, {{ lead.shipping_address.country }}.
                v-col(v-if="lead.shipping && lead.pickup")
                  .p2.basic600--text {{ $t('pick_up_location') }}
                  linkify-text.p2.basic700--text(v-if="(typeof lead.shipping.tracking.pickup_address) === 'object'" :text="getStringFromAddress(lead.shipping.tracking.pickup_address)")
                  linkify-text.p2.basic700--text(v-else :text="lead.shipping.tracking.pickup_address")
                  .instruction.mt-4(v-if="lead.order_remarks.shipping_method.meta_data && lead.order_remarks.shipping_method.meta_data.type === 'store_pickup'")
                    .p2.basic600--text {{ $t('pick_up_instruction') }}
                    linkify-text.p2.basic700--text(:text="lead.order_remarks.shipping_method.meta_data.instruction")
                  
                v-col.pl-4
                  .shipping(v-if="lead.order_remarks && lead.order_remarks.shipping_method && !lead.pickup")
                    .p2.basic600--text {{ $t('shipping_method') }}
                    .p2.primary500--text.mb-4 {{ lead.order_remarks.shipping_method.title }}

                  .p2.basic600--text {{ $t('payment_method') }}
                  .p2.primary500--text {{ paymentMethod }}

                  .asking_delivery_date.mt-4(v-if="lead.asking_delivery_date || lead.asking_delivery_time")
                    .p2.basic600--text {{ lead.pickup ? 'Pickup' : 'Delivery' }}
                      span.ml-1(v-if="lead.asking_delivery_date") {{ $t('date') }} {{ lead.asking_delivery_time ? '&' : '' }}
                      span.ml-1(v-if="lead.asking_delivery_time") {{ $t('time') }}
                    div.p2.primary500--text(v-if="lead.asking_delivery_date") {{ $t('date') }}: {{ lead.asking_delivery_date | displayDate }}
                    div.p2.primary500--text(v-if="lead.asking_delivery_time") {{ $t('time') }}: {{ lead.asking_delivery_time | displayTime }}
              
              div.mt-5(v-if="lead.extra_fields_data && lead.extra_fields_data.length > 0 && lead.extra_fields_data.filter(f => f.type === 'delivery_date' || f.type === 'delivery_time').length > 0")
                div.p2.primary500--text(v-if="lead.extra_fields_data.filter(f => f.type === 'delivery_date').length > 0") {{ lead.extra_fields_data.filter(f => f.type === 'delivery_date')[0].name }}: {{ lead.extra_fields_data.filter(f => f.type === 'delivery_date')[0].value }}
                div.p2.primary500--text(v-if="lead.extra_fields_data.filter(f => f.type === 'delivery_time').length > 0") {{ lead.extra_fields_data.filter(f => f.type === 'delivery_time')[0].name }}: {{ lead.extra_fields_data.filter(f => f.type === 'delivery_time')[0].value }}

            div.mt-6.text-center
              div.f1.primary400--text {{ $t('powered_by_yezza') }}
              div.f2.primary400--text(v-if="lead.paid") {{ $t('this_is_a_computer_generated_receipt') }}
              div.f2.primary400--text(v-else) {{ $t('this_is_a_computer_generated_invoice') }}

        v-footer.basic100
          v-container.pa-0(style="max-width:770px")
            v-btn.button-AA-medium.px-8.mb-2(v-if="!lead.paid && (lead.cod || lead.pay_at_shop || totalPaid)" color="info500" small height="40" block dark @click="clickProceedPayment()" :loading="processing")
              div {{ $t('pay_now') }}
            v-btn.button-AA-small.px-8.mb-2(v-else color="textColorAccent" block outlined :style="{'background-color': $vuetify.theme.themes[$theme].infoT08}" @click="startRepeatOrder") {{ $t('buy_again') }}
          
</template>

<script>
import { mapGetters } from 'vuex'
import LinkifyText from '@/components/LinkifyText.vue'

export default {
  name: 'ProductInvoice',
  components: { LinkifyText },
  props: ['open'],
  data: () => ({
    isFetching: false,
    invoice: null,
  }),
  computed: {
    ...mapGetters({
      lead: 'current/getLead'
    }),
    showModal: {
      get() {
        return this.open
      },
      set(newValue) {
        this.$emit('update:open', newValue)
      }
    },
    trackingId () {
      if (this.lead.shipping && this.lead.shipping.tracking_id) {
        return `${this.lead.shipping.courier} ${this.lead.shipping.tracking_id}.`
      }
      return null
    },
    banner () {
      if (this.lead.status === 'shipped' && this.trackingId) {
        return { backgroundColor: this.$vuetify.theme.themes[this.$theme].warning600, iconName: 'car', title: 'Order shipped', description: this.trackingId, action: 'Track with Zoo'}
      } else if (this.lead.status === 'shipped') {
        return { backgroundColor: this.$vuetify.theme.themes[this.$theme].warning600, iconName: 'car', title: 'Order shipped', description: 'Your tracking details are ready.', action: 'View in Zoo'}
      } else if (this.lead.status ==='received') {
        return { backgroundColor: this.$vuetify.theme.themes[this.$theme].warning600, iconName: 'cube', title: 'Order completed', description: 'View your previous orders in', action: 'Zoo'}
      } else if (!this.lead.paid && this.lead.order_remarks.payment_method) {
        if (this.lead.order_remarks.payment_method.id === 'pas') {
          return { backgroundColor: this.$vuetify.theme.themes[this.$theme].success500, iconName: 'checkmark-circle-2-outline', title: 'Your order is being processed', description: 'Thank you for checking out with Yezza Pay', action: ''}
        } else if (this.lead.order_remarks.payment_method.id === 'manual') {
          return { backgroundColor: this.$vuetify.theme.themes[this.$theme].warning600, iconName: 'clock', title: 'We have received your order', description: 'Awaiting merchant to confirm payment', action: ''}
        }
      }
      return { backgroundColor: this.$vuetify.theme.themes[this.$theme].success500, iconName: 'checkmark-circle-2-outline', title: 'Your order is successful', description: 'Get notified when tracking is ready.', action: 'View Zoo'}
    },
    company () {
      return this.lead.form.company
    },
    shippingFee() {
      if (!this.invoice) return 0
      return this.invoice.shipping_fee
    },
    discountPrice() {
      if (!this.invoice) return 0
      return this.invoice.total_discount
    },
    promoDiscountPrice() {
      if (!this.invoice) return 0
      return this.invoice.total_promo_discount
    },
    subtotalPrice() {
      if (!this.invoice) return 0
      return this.invoice.subtotal_amount
    },
    totalPrice() {
      if (!this.invoice) return 0
      return this.invoice.total_amount
    },
    totalPaid() {
      if (!this.invoice) return 0
      return this.invoice.total_paid
    },
    amountDue() {
      if (!this.invoice) return 0
      return this.invoice.total_amount - this.invoice.total_paid
    },
    taxAmount() {
      if (!this.invoice) return 0
      return this.invoice.company.tax_rate
    },
    paymentMethod() {
      if (this.lead.order_remarks.payment_method) {
        return this.lead.order_remarks.payment_method.display_name
      }
      return '-'
    }
  },
  mounted() {
    this.getInvoice()
  },
  methods: {
    getStringFromAddress (address) {
      return `${address.address_1},${address.address_2 ? ' ' + address.address_2 + ',' : ''} ${address.postcode},${address.area ? ' ' + address.area + ',' : ''}${address.suburb ? ' ' + address.suburb + ',' : ''} ${address.city}, ${address.state}, ${address.country}`
    },
    getInvoice () {
      this.isFetching = true
      this.$axios.get(`/invoices/${this.lead.pid}/`)
        .then((response) => {
          this.isFetching = false
          this.invoice = response.data
        })
        .catch(() => {})
    },
    getName (product) {
      if (product.product.variant_for) {
        if (this.sameVariantParentName(product)) {
          return product.name.replace(`(${product.product.name})`, '')
        }
      }
      return product.name
    },
    sameVariantParentName (product) {
      return product.name.includes(`(${product.product.name})`)
    },
    clickAskMerchat () {
      window.open(`https://api.whatsapp.com/send?phone=${this.lead.member.phone}`, '_blank')
    },
    startRepeatOrder () {
      if (this.lead.member && this.lead.member.role === 'Agent') {
        this.$axios.get(`/forms/${this.lead.form.slug}?agent_member_id=${this.lead.member.id}`)
        .then((response) => {
          location.href = `https://${process.env.VUE_APP_SHORT_URL_DOMAIN}/${response.data.slug}/?customer=${this.lead.pid}`
        })
        .catch(() => {})
        return
      }
      if (this.lead.form.type === 'store') {
        location.href = `https://${this.lead.form.company.slug}.${process.env.VUE_APP_STORE_URL_DOMAIN}/?customer=${this.lead.pid}`
        return
      }
      location.href = `https://${process.env.VUE_APP_SHORT_URL_DOMAIN}/${this.lead.form.slug}/?customer=${this.lead.pid}`
    },
    clickViewZooAp () {
      window.open("http://onelink.to/hzsjsu")
    },
    clickProceedPayment () {
      this.$router.push(`/orders/${this.lead.pid}/online_payment`)
    }
  }
}
</script>

<style scoped>

.dotted {
  border-top: 2px dashed var(--v-primary400);
}

.v-text-field--outlined >>> fieldset {
  border-color: var(--v-primary200);
}
.error--text >>> fieldset {
  border-color: var(--v-danger);
}

</style>