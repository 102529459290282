<template lang="pug">
  div(v-if="selectedCourier")
    v-row(no-gutters @click="$store.commit('current/updateEditingSection', $userFlows.FILL_IN_SHIPPING_METHOD_FLOW)")
      v-col(cols="4")
        div.p2.basic600--text(style="width: 100px") {{ $t('pick_up') }}
      v-col(cols="7")
        transition(name="title")
          div(v-if="!(editingSection === $userFlows.FILL_IN_SHIPPING_METHOD_FLOW)")
            div.p2.primary--text {{ selectedZone.pickup_address }}
      v-col(cols="1" align="end")
        eva-icon(:name="editingSection === $userFlows.FILL_IN_SHIPPING_METHOD_FLOW ? 'chevron-up' : 'chevron-down'" :fill="$vuetify.theme.themes[$theme].info400" height="20" width="20" )
    transition(name="content")
      div(v-if="editingSection === $userFlows.FILL_IN_SHIPPING_METHOD_FLOW")
        v-row.mt-4(no-gutters)
          v-col.align-self-center
            div.c2.primary--text {{ selectedZone.name }}
          v-col.shrink
            v-btn.button-Aa-tiny.px-0(x-small text color="transparent" @click="openEditPickupDialog = true")
              div.info500--text {{ $t('change_area') }}
        div.danger500--text.c1(v-if="errorMessage") {{ errorMessage }}
        v-card(flat)
          v-layout.my-6(v-if="isFetching")
            v-row.fill-height.ma-0(align='center' justify='center')
              v-progress-circular.ma-auto(:width="2" :size="50" color="primary" indeterminate)
          template(v-else)
            v-row.mt-3(no-gutters)
              v-col.shrink.pr-2
                eva-icon(name="pin-outline" :fill="$vuetify.theme.themes[$theme].primary500" height="20" width="20" )
              v-col
                linkify-text.p2.primary--text(:text="selectedZone.pickup_address")
                template(v-if="selectedCourier && selectedCourier.meta_data && selectedCourier.meta_data.type === 'store_pickup'" no-gutters)
                  linkify-text.p2.primary400--text.mt-2(:text="selectedCourier.meta_data.instruction")
        div.mt-4
          v-btn.button-Aa-small.px-0(color="transparent" text @click="openSelectAddressDialog = true" small)
            eva-icon.mr-1(name="swap" :fill="$vuetify.theme.themes[$theme].info500" height="15" width="15" style="margin-top: 2px")
            div.info500--text {{ $t('switch_to_delivery') }}
    .modal
      select-shipping-address(v-if="openSelectAddressDialog" :open.sync="openSelectAddressDialog" @shipping="openEditDeliveryDialog = true")
      edit-delivery(v-if="openEditDeliveryDialog" :open.sync="openEditDeliveryDialog" @refresh="refreshData" :source="'pickup'")
      edit-pickup(v-if="openEditPickupDialog" :open.sync="openEditPickupDialog" @refresh="refreshData" :source="'pickup'")
</template>

<script>
import { mapGetters } from 'vuex'
import SelectShippingAddress from '@/components/dialogs/SelectShippingAddress.vue'
import EditDelivery from '@/components/dialogs/EditDelivery.vue'
import EditPickup from '@/components/dialogs/EditPickup.vue'
import LinkifyText from '@/components/custom/LinkifyText.vue'

export default {
  name: 'CollapsePickup',
  components: { SelectShippingAddress, EditDelivery, EditPickup, LinkifyText },
  props: ['active'],
  data: () => ({
    openSelectAddressDialog: false,
    openEditDeliveryDialog: false,
    openEditPickupDialog: false,
    isFetching: false,
    selectedCourier: null,
    errorMessage: null
  }),
  computed: {
    ...mapGetters({
      zones: 'current/getZones',
      lead: 'current/getLead',
      editingSection: 'current/getEditingSection',
    }),
    selectedZone () {
      return this.zones.filter(f => f.id === this.lead.shipping.zone)[0]
    }
  },
  mounted () {
    this.fetchCouriers()
  },
  methods: {
    fetchCouriers () {
      this.errorMessage = null
      this.isFetching = true
      this.$axios.get(`/shipping/fees/${this.lead.pid}/?zone=${this.lead.shipping.zone}&pickup=true`)
        .then((response) => {
          this.selectedCourier = response.data[0]
        })
        .catch(e => {
          if (e.response && e.response.data && e.response.data.message) {
            this.errorMessage = e.response.data.message
          } else {
            this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
          }
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    saveCourier (courier) {
      let tempCourier = this.selectedCourier
      this.selectedCourier = courier
      let params = {
        fee: courier.fee,
        lead: this.lead.id,
        shipping_method: courier.shipping_method,
        meta_data: courier.meta_data,
        signature: courier.signature,
        zone: this.lead.shipping.zone
      }
      this.axios.patch(`/shipping/shippings/${this.lead.shipping.id}/`, params)
        .then(() => {
          this.updateCurrentLead()
          this.$store.commit('current/updateRemarksShipping', this.selectedCourier)
        })
        .catch(() => {
          this.selectedCourier = tempCourier
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    refreshData () {
      this.fetchCouriers()
      this.openEditDeliveryDialog = false
      this.openEditPickupDialog = false
    }
  }
}
</script>

<style scoped>
.title-enter-active {
  transition: opacity .3s;
}
.title-leave-active {
  transform: scale(0,0) .3s;
}
.title-enter, .title-leave-to {
  opacity: 0;
}
.content-enter-active {
  transition: opacity .3s;
}
.content-leave-active {
  transform: scale(0,0) .3s;
}
.content-enter, .content-leave-to {
  opacity: 0;
}
</style>