<template lang="pug">
  div
    v-dialog(v-model="showModal" scrollable transition="fade-transition" persistent max-width="600")
      v-card
        v-card-title.pa-4 
          div.h6.primary--text {{ $t('pick_up_location') }}
          v-spacer
          v-btn(small icon @click="showModal = false")
            v-icon(color="primary") mdi-close
        v-card-text.px-4
          v-form(ref="form")
            v-text-field(v-model="shipping_address.name" label="Recipient Name" filled rounded hide-details="auto" :rules="rules.not_empty")
            v-text-field.mt-3(v-model="shipping_address.phone" label="Recipient Phone" filled rounded hide-details="auto" :rules="rules.not_empty")
            template(v-if="zonelist.length > 1")
              div.label.basic600--text.text-uppercase.mt-5 {{ $t('preferred_pick_up_area') }}
              v-select.mt-2(v-model="selectedZone" :items="zonelist" item-text="name" return-object :placeholder="$t('select_area')" background-color="basic200" outlined dense hide-details="auto")
                template(v-slot:append)
                  eva-icon(name="chevron-down-outline" width="24" height="24" :fill="$vuetify.theme.themes[$theme].basic700")
            template(v-if="selectedZone")
              div.label.basic600--text.text-uppercase.mt-5.mb-4 {{ $t('pick_up_at') }}
              v-row(no-gutters)
                v-col.shrink.pr-2
                  eva-icon(name="pin-outline" :fill="$vuetify.theme.themes[$theme].primary500" height="22" width="22" )
                v-col
                  linkify-text.s1.primary--text(:text="selectedZone.pickup_address")
                  template(v-if="selectedCourier && selectedCourier.meta_data && selectedCourier.meta_data.type === 'store_pickup'")
                    linkify-text.p2.primary--text.mt-3(:text="selectedCourier.meta_data.instruction")
                //- v-btn.button-Aa-small.mt-4(x-small text color="primary400") View location
              .c1.basic600--text(v-if="datetimeData.description") {{ datetimeData.description }}
            div.mt-8(v-else)
              div.c1.basic600--text.text-center {{ $t('your_pick_up_location_will') }}
        v-card-actions.pa-4
          v-btn.button-AA-medium.white--text(color="success500" small height="40" block @click="saveShippingFee" :loading="isLoading" :dark="Boolean(selectedCourier) && datetimeData.available" :disabled="!selectedCourier || !datetimeData.available")
            div(:class="{ 'white--text': selectedCourier }") {{ source === 'delivery' ? $t('confirm_pick_up') : $t('save_pick_up') }}
</template>

<script>
import { mapGetters } from 'vuex'
import LinkifyText from '@/components/custom/LinkifyText.vue'

export default {
  name: 'EditPickup',
  components: { LinkifyText },
  props: ['open', 'source'],
  data: () => ({
    isLoading: false,
    shipping_address: {},
    zonelist: [],
    selectedZone: null,
    selectedCourier: null,
    rules: {
      not_empty: [v => !!v || 'This field is required']
    }
  }),
  computed: {
    ...mapGetters({
      zones: 'current/getZones',
      lead: 'current/getLead',
      datetimeData: 'current/getPickupDatetimeData',
    }),
    showModal: {
      get () {
        return this.open
      },
      set (newValue) {
        this.$emit('update:open', newValue)
      }
    }
  },
  watch: {
    selectedZone (val) {
      if (val != null) {
        this.fetchCouriers()
      }
    }
  },
  mounted () {
    this.shipping_address.phone = this.lead.shipping_address.phone || this.lead.phone
    this.shipping_address.name = this.lead.shipping_address.name || this.lead.name
    this.zonelist = [...this.zones.filter(f => f.shipping_methods.includes('pickup') && f.pickup_address)]
    if (this.zonelist.length === 1) {
      this.selectedZone = { ...this.zonelist[0] }
    } else if (this.source === 'pickup') {
      this.selectedZone = this.zonelist.filter(f => f.id === this.lead.shipping.zone)[0]
    }
  },
  methods: {
    fetchCouriers () {
      this.isFetching = true
      this.$axios.get(`/shipping/fees/${this.lead.pid}/?zone=${this.selectedZone.id}&pickup=true`)
        .then((response) => {
          this.selectedCourier = { ...response.data[0] }
        })
        .catch(e => {
          if (e.response && e.response.data && e.response.data.message) {
            this.errorMessage = e.response.data.message
          } else {
            this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
          }
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    saveShippingFee () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.isLoading = true
      let params = {
        fee: this.selectedCourier.fee,
        lead: this.lead.id,
        shipping_method: this.selectedCourier.shipping_method,
        meta_data: this.selectedCourier.meta_data,
        signature: this.selectedCourier.signature,
        zone: this.selectedZone.id
      }
      this.axios.patch(`/shipping/shippings/${this.lead.shipping.id}/`, params)
        .then(() => {
          this.$store.commit('current/updateRemarksShipping', this.selectedCourier)
          this.saveAddress()
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          if (this.source !== 'delivery') {
            this.isLoading = false
          }
        })
    },
    saveAddress () {
      this.axios.patch(`/leads/${this.lead.pid}/`, { shipping_address: this.shipping_address })
        .then(() => {
          this.updateCurrentLead()
          this.showModal = false
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: var(--v-primary200);
}
::v-deep .v-text-field--filled > .v-input__control > .v-input__slot{
  background: #F7F7F7 !important;
  border: 1px solid #eaeaea !important;
  border-radius: 4px;
  max-height: 48px;
  min-height: 48px;
  padding-left: 12px;
}
::v-deep .v-text-field.v-input--has-state > .v-input__control > .v-input__slot{
  border: 1px solid var(--v-danger) !important;
}
::v-deep .v-text-field--filled:not(.v-text-field--single-line) input{
  margin-top: 14px;
}
::v-deep .v-text-field--filled .v-label{
  top: 14px !important;
}
::v-deep .v-text-field--filled .v-label--active{
  transform: translateY(-10px) scale(0.75);
}
::v-deep .v-input--has-state.error--text{
  color: red;
}
</style>