<template lang="pug">
  div
    v-form(ref="form")
      div.h6.primary--text {{ hideFields ? 'Recipient postcode' : 'Recipient Information' }}
      template(v-if="!hideFields")
        v-text-field.mt-4(v-model="shipping_address.name" label="Name" filled rounded hide-details="auto" :rules="rules.not_empty")
        v-text-field.mt-3(v-model="shipping_address.phone" label="Phone" filled rounded hide-details="auto" :rules="rules.not_empty")
        v-text-field.mt-3(v-model="shipping_address.address_1" label="Address 1" filled rounded hide-details="auto" :rules="rules.not_empty")
        v-text-field.mt-3(v-model="shipping_address.address_2" label="Address 2 (optional)" filled rounded hide-details="auto" )
      v-text-field(v-model="shipping_address.postcode" :class="hideFields ? 'mt-4' : 'mt-3'" label="Postcode" filled rounded hide-details="auto" :rules="rules.not_empty" @keyup="fetchPostcodes(shipping_address.postcode)")
      template(v-if="!hideFields")
        v-text-field.mt-3(v-model="shipping_address.city" label="City" filled rounded hide-details="auto" :rules="rules.not_empty" :loading="isLoading")
        v-text-field.mt-3(v-model="shipping_address.state" label="State" filled rounded hide-details="auto" :rules="rules.not_empty" :loading="isLoading")
        v-text-field.mt-3(v-model="shipping_address.country" label="Country" filled rounded hide-details="auto" :rules="rules.not_empty" :loading="isLoading")
        v-btn.button-AA-medium.mt-4(color="info500" dark small height="40" block @click="saveAddress()" :loading="isLoading2") {{ $t('continue_to_shipping_method') }}
      div.p1.danger500--text.text-center.mt-3.py-2(v-if="hideFields") {{ message }}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DeliveryForm',
  data: () => ({
    isLoading: false,
    isLoading2: false,
    tab: null,
    shipping_address: {},
    message: null,
    hideFields: false,
    rules: {
      not_empty: [v => !!v || 'This field is required']
    }
  }),
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      zooData: 'current/getZooData',
      zones: 'current/getZones',
      restrictedCities: 'current/getRestrictedCities',
      lead: 'current/getLead'
    })
  },
  mounted () {
    if (this.restrictedCities) {
      this.hideFields = true
    }
    if (this.lead.shipping_address) {
      this.shipping_address = { ...this.lead.shipping_address }
      if (!this.shipping_address.phone) {
        this.$set(this.shipping_address, 'phone', this.lead.phone)
      }
      if (!this.shipping_address.name) {
        this.$set(this.shipping_address, 'name', this.lead.name)
      }
    } else {
      this.$set(this.shipping_address, 'phone', this.lead.phone)
      this.$set(this.shipping_address, 'name', this.lead.name)
    }
  },
  methods: {
    fetchPostcodes (val) {
      if (!val) {
        return
      }
      if (val.length !== 5) {
        return
      }
      this.isLoading = true
      this.$axios.get(`/shipping/postcodes?postcode=${this.shipping_address.postcode}`).then((response) => {
        const addresses = response.data
        if (this.restrictedCities && this.restrictedCities.length > 0) {
          if (addresses.length > 0 && addresses[0].postcode) {
            if (this.restrictedCities.includes(addresses[0].city)) {
              this.$set(this.shipping_address, 'city', addresses[0].city)
              this.$set(this.shipping_address, 'state', addresses[0].state)
              this.$set(this.shipping_address, 'country', addresses[0].country)
              this.hideFields = false
            } else {
              this.hideFields = true
              this.message = 'Delivery is not available for your address'
            }
          }
        } else {
          this.hideFields = false
          if (addresses.length > 0 && addresses[0].postcode) {
            this.$set(this.shipping_address, 'city', addresses[0].city)
            this.$set(this.shipping_address, 'state', addresses[0].state)
            this.$set(this.shipping_address, 'country', addresses[0].country)
          }
        }
      })
      .catch(() => {
      })
      .finally(() => {
        this.isLoading = false
      })
    },
    saveAddress () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.isLoading2 = true
      let param = { shipping_address: this.shipping_address }
      if (this.lead.phone.endsWith(this.shipping_address.phone) || this.lead.name === 'Guest') {
        param.name = this.shipping_address.name
      }
      this.axios.patch(`/leads/${this.lead.pid}/`, param)
        .then(() => {
          this.updateCurrentLead()
          if (this.user && this.zooData && this.zooData.fast_checkout_consent) {
            this.saveAddressToZoo(this.shipping_address)
          } else {
            this.goToNextFlow(1)
          }
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          if (!this.user) {
            this.isLoading2 = false
          }
        })
    },
    saveAddressToZoo (addr) {
      let stringfy = JSON.stringify({ address_1: addr.address_1, address_2: addr.address_2, postcode: addr.postcode })
      let address = []
      if (this.zooData && this.zooData.addresses && this.zooData.addresses.length > 0) {
        address = [...this.zooData.addresses]
      }
      if (address.filter(f => JSON.stringify({ address_1: f.address_1, address_2: f.address_2, postcode: f.postcode }) === stringfy).length > 0) {
        this.goToNextFlow(1)
        return
      }
      this.axios.post(`/zoo/${this.user.profile}/addresses`, addr)
        .then(() => {
          this.goToNextFlow(1)
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading2 = false
        })
    }
  }
}
</script>

<style scoped>
::v-deep .v-text-field--filled > .v-input__control > .v-input__slot{
  background: #F7F7F7 !important;
  border: 1px solid #eaeaea !important;
  border-radius: 4px;
  max-height: 48px;
  min-height: 48px;
  padding-left: 12px;
}
::v-deep .v-text-field.v-input--has-state > .v-input__control > .v-input__slot{
  border: 1px solid var(--v-danger) !important;
}
::v-deep .v-text-field--filled:not(.v-text-field--single-line) input{
  margin-top: 14px;
}
::v-deep .v-text-field--filled .v-label{
  top: 14px !important;
}
::v-deep .v-text-field--filled .v-label--active{
  transform: translateY(-10px) scale(0.75);
}
::v-deep .v-input--has-state.error--text{
  color: red;
}
</style>