<template lang="pug">
  .navigation-layout
    v-layout.my-6(v-if="!form.flowState")
      v-row.fill-height.ma-0(align='center' justify='center')
        v-progress-circular.ma-auto(:width="2" :size="50" color="primary" indeterminate)
    div(v-else)
      FlowComponentsManager(v-if="form.flowState" :currentFlow="form.flowState")

      //- div {{ form.flowState }}
      //- div.mt-6
      //-   div(v-for="(story, index) in stories")
      //-     div {{ index + 1 }}. {{ story.flow }}

</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import FlowComponentsManager from '@/views/FlowComponentsManager.vue'
Vue.mixin({
  methods: {
    goToNextFlow (cursor) {
      let changeStoryTo = null
      const stories = this.$store.getters['current/getStories']
      const form = this.$store.getters['current/getForm']
      let currentStory = stories.find( story => story.flow === form.flowState) || {}
      if (cursor > 0) {
        if (currentStory.next) {
          changeStoryTo = currentStory.next
        } else {
          changeStoryTo = stories[stories.length - 1].flow
        }
      } else if (currentStory.previous) {
        changeStoryTo = currentStory.previous
      }
      this.$store.commit('current/updateFlowState', changeStoryTo)
    },
    updateCurrentLead () {
      const lead = this.$store.getters['current/getLead']
      this.$axios.get(`/leads/${lead.pid}/`)
        .then((resp) => {
          this.$store.commit('current/updateLead', resp.data)
        }).catch(() => {})
    }
  }
})

export default {
  //
  // FlowController Responsibilities:
  // 1. Change to next Flow by Story
  //
  // Flow Responsibilities:
  // 1. Set Component Arrangement
  // 2. Manage & Supply data to components
  //
  name: 'FlowController',
  components: { FlowComponentsManager },
  props: ['pid'],
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      form: 'current/getForm',
      lead: 'current/getLead',
      order: 'current/getOrder',
      stories: 'current/getStories'
    })
  },
  mounted () {
    Vue.prototype.$userFlows = this.form.flows
    if (this.lead) {
      this.$store.commit('current/recoverOrder', this.lead)
      this.$store.commit('current/updateLeadSignature', this.lead)
    }
    const changeStoryTo = this.stories[this.stories.length - 1].flow
    this.$store.commit('current/updateFlowState', changeStoryTo)
  },
  methods: {
  }
}
</script>
