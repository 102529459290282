<template lang="pug">
  div(v-if="orderDateTime")
    v-row(no-gutters @click="$store.commit('current/updateEditingSection', $userFlows.FILL_IN_DELIVERY_DATE_TIME)")
      v-col(cols="4")
        div.p2.basic600--text(style="width: 100px") {{ $t('schedule') }}
      v-col(cols="7")
        transition(name="title")
          div(v-if="!(editingSection === $userFlows.FILL_IN_DELIVERY_DATE_TIME)")
            template(v-if="orderDateTime.is_ASAP")
              .p2 ASAP
                span(v-if="omsData.preparation_time_enabled")  ({{ $t('approx') }}. {{ omsData.preparation_time_value }} {{ omsData.preparation_time_unit }})
            template(v-else)
              .p2
                span {{ orderDateTime.asking_delivery_date  | humanizeDate }}
                span(v-if="orderDateTime.asking_delivery_time") , {{ orderDateTime.asking_delivery_time | covert24To12 }}

      v-col(cols="1" align="end")
        eva-icon(:name="editingSection === $userFlows.FILL_IN_DELIVERY_DATE_TIME ? 'chevron-up' : 'chevron-down'" :fill="$vuetify.theme.themes[$theme].info400" height="20" width="20" )
    transition(name="content")
      div(v-if="editingSection === $userFlows.FILL_IN_DELIVERY_DATE_TIME")
        v-row.mt-1(no-gutters)
          v-col.shrink
            eva-icon.mr-3.mt-2(name="calendar-outline" width="20" height="20" :fill="$vuetify.theme.themes[$theme].basic700")
          v-col.align-self-center
            .p2.textColorDefault--text {{ orderDateTime.asking_delivery_date  | humanizeDate }}
          v-col.shrink(v-if="lead")
            v-btn.button-Aa-tiny.px-0(x-small text color="transparent" @click="openEditEditScheduleDialog = true")
              div.info500--text {{ $t('edit') }}
        
        v-row(v-if="orderDateTime.asking_delivery_time" no-gutters)
          v-col.shrink
            eva-icon.mr-3.mt-2(name="clock-outline" width="20" height="20" :fill="$vuetify.theme.themes[$theme].basic700")
          v-col.align-self-center
            .p2.textColorDefault--text {{ orderDateTime.asking_delivery_time | covert24To12 }}

    .modal
      edit-schedule(v-if="openEditEditScheduleDialog" :open.sync="openEditEditScheduleDialog" :parentOrderDateTime="orderDateTime")
</template>

<script>
import { mapGetters } from 'vuex'
import EditSchedule from '@/components/dialogs/EditSchedule.vue'

export default {
  name: 'CollapseSchedule',
  components: { EditSchedule },
  data: () => ({
    isLoading: false,
    openEditEditScheduleDialog: false,
  }),
  computed: {
    ...mapGetters({
      orderDateTime: 'current/getDateTime',
      omsData: 'current/getOms',
      lead: 'current/getLead',
      editingSection: 'current/getEditingSection',
    })
  },
  watch: {
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped>
.title-enter-active {
  transition: opacity .3s;
}
.title-leave-active {
  transform: scale(0,0) .3s;
}
.title-enter, .title-leave-to {
  opacity: 0;
}
.content-enter-active {
  transition: opacity .3s;
}
.content-leave-active {
  transform: scale(0,0) .3s;
}
.content-enter, .content-leave-to {
  opacity: 0;
}
</style>