<template lang="pug">
  div
    v-dialog(v-model="showModal" scrollable transition="fade-transition" persistent max-width="600")
      v-card
        v-card-title.pa-4 
          div.h6.primary--text {{ $t('select_bank') }}
          v-spacer
          v-btn(small icon @click="showModal = false")
            v-icon(color="primary") mdi-close
        v-card-text.px-4
          v-card(outlined)
            v-list.pa-0
              v-list-item-group(v-model="selectedBank" color="primary")
                template(v-for='(bank, index) in banks')
                  v-divider(v-if="index != 0")
                  v-list-item(:key='bank.code' v-slot:default='{ active, toggle }' :value="bank")
                    v-list-item-icon
                      v-card
                        v-img(:src="bank.image ? bank.image : require('@/assets/image_placeholder.png')" width=48, height=48)
                    v-list-item-content
                      v-list-item-title(v-text='bank.name')
                    v-list-item-action(v-show="active")
                      v-btn(icon)
                        v-icon(color='success') mdi-check
            
        v-card-actions.pa-4
          v-btn.button-AA-medium.white--text(color="success500" small height="40" block @click="savePayment" dark) {{ $t('save') }}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'EditOnlineBanking',
  props: ['open', 'parentBanks'],
  data: () => ({
    isLoading: false,
    banks: [],
    selectedBank: null,
    rules: {
      not_empty: [v => !!v || 'This field is required'],
      credit_card: [v => !!v || 'This field is required', v => !!v && v.length === 16 || 'card number invalid']
    }
  }),
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      payment: 'current/getPayment',
      lead: 'current/getLead'
    }),
    showModal: {
      get () {
        return this.open
      },
      set (newValue) {
        this.$emit('update:open', newValue)
      }
    }
  },
  watch: {},
  mounted () {
    this.banks = this.parentBanks
  },
  methods: {
    savePayment () {
      const payment = {
        method: 'fpx',
        data: {
          gateway: this.lead.form.company.default_payment_gateway,
          bank_code: this.selectedBank.code,
          description: `#${this.lead.rid} ${this.lead.name} from yezza`,
          lead: this.lead.id
        },
        remarks: {
          id: 'fpx',
          display_name: this.banks.filter(f => f.code === this.selectedBank.code)[0].name
        }
      }
      this.$store.commit('current/updatePayment', payment)
      this.savePreferredBank(this.selectedBank.code)
      this.showModal = false
    },
    savePreferredBank (bank) {
      if (!this.user) {
        return
      }
      this.$axios.patch(`/zoo/${this.user.profile}/`, { preferred_bank: bank })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
    }
  }
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: var(--v-primary200);
}
.error--text >>> fieldset {
  border-color: var(--v-danger);
}
</style>