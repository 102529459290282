<template lang="pug">
  div
    .current(v-if="form.flowState === $userFlows.FILL_IN_SHIPPING_METHOD_FLOW")
      .next-is-ready(v-if="nextStepIsReady")
        v-divider.basic300
        v-card.pa-4.elevation-0(:class="[{ 'hide-top-corner-radius': params.hideTopCorner }, { 'hide-bottom-corner-radius': params.hideBottomCorner }]")
          collapse-shipping
      v-card.mt-4(v-else)
        div.pt-4.px-4
          div.d-flex.align-center
            div.h6.primary--text {{ $t('shipping_method') }}
            v-spacer
            template(v-if="pickupAvailable")
              v-btn.button-Aa-small.px-0(v-if="method === 'delivery'" color="transparent" text @click="method = 'pickup'" small)
                div.info500--text {{ $t('switch_to_pick_up') }}
              v-btn.button-Aa-small.px-0(v-if="method === 'pickup'" color="transparent" text @click="method = 'delivery'" small)
                div.info500--text {{ $t('switch_to_delivery') }}
        div(v-if="method === 'delivery'")
          shipping-form
        div.pa-4(v-if="method === 'pickup'")
          div.label.basic600--text.text-uppercase {{ $t('self_pickup') }}
          pickup-form
    .other(v-else)
      .empty(v-if="!lead.shipping")
        v-card.mt-4(flat)
          div.pa-4
            div.s1.basic600--text {{ $t('shipping_method') }}
      .collapse.clickable(v-else)
        v-divider.basic300
        v-card.pa-4.elevation-0(:class="[{ 'hide-top-corner-radius': params.hideTopCorner }, { 'hide-bottom-corner-radius': params.hideBottomCorner }]")
          template(v-if="lead.shipping")
            collapse-pickup(v-if="lead.pickup")
            collapse-delivery(v-else)
          template(v-else)
            collapse-shipping
      
</template>

<script>
import { mapGetters } from 'vuex'
import ShippingForm from '@/components/ShippingForm.vue'
import PickupForm from '@/components/PickupForm.vue'
import CollapseShipping from '@/components/collapse/CollapseShipping.vue'
import CollapseDelivery from '@/components/collapse/CollapseDelivery.vue'
import CollapsePickup from '@/components/collapse/CollapsePickup.vue'

export default {
  name: 'DeliveryMethod',
  components: { ShippingForm, PickupForm, CollapseShipping, CollapseDelivery, CollapsePickup },
  props: {
    params: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    method: 'delivery',
    pickupAvailable: false
  }),
  computed: {
    ...mapGetters({
      form: 'current/getForm',
      zones: 'current/getZones',
      lead: 'current/getLead',
      order: 'current/getOrder',
    }),
    nextStepIsReady () {
      if (this.order.payment) {
        return true
      }
      return false
    }
  },
  watch: {
  },
  mounted () {
    if (this.zones.filter(f => f.shipping_methods.includes('pickup') && f.pickup_address).length > 0) {
      this.pickupAvailable = true
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>