<template lang="pug">
  .components
    v-layout.my-6(v-if="isFetching && !form.isReady")
      v-row.fill-height.ma-0(align='center' justify='center')
        v-progress-circular.ma-auto(:width="2" :size="50" color="primary" indeterminate)
    v-container.pa-4.mb-4(v-else style="max-width: 600px")
      component(:is="component.name" v-for="(component, index) in flowComponents" :key="component.id" :class="component.class" :params="component.params")

</template>

<script>
import { mapGetters } from 'vuex'
import CompanyName from '@/components/viewmodels/CompanyName.vue'
import CustomerPhone from '@/components/viewmodels/CustomerPhone.vue'
import SecureCheckout from '@/components/viewmodels/SecureCheckout.vue'
import DeliveryDateTime from '@/components/viewmodels/DeliveryDateTime.vue'
import RecipientForm from '@/components/viewmodels/RecipientForm.vue'
import DeliveryMethod from '@/components/viewmodels/DeliveryMethod.vue'
import PaymentMethod from '@/components/viewmodels/PaymentMethod.vue'
import ExtraFields from '@/components/viewmodels/ExtraFields.vue'
import OrderSummary from '@/components/viewmodels/OrderSummary.vue'
export default {
  name: 'ComponentBuilder',
  components: { CompanyName, CustomerPhone, SecureCheckout, DeliveryDateTime, RecipientForm, DeliveryMethod, PaymentMethod, ExtraFields, OrderSummary },
  props: {
    currentFlow: {
      type: String,
      default: null
    }
  },
  data: () => ({
    isFetching: false,
  }),
  computed: {
    ...mapGetters({
      storedLeadPhone: 'current/getLeadPhone',
      user: 'user/getUser',
      zooData: 'current/getZooData',
      dataBin: 'current/getDataBin',
      lead: 'current/getLead',
      zones: 'current/getZones',
      form: 'current/getForm',
      order: 'current/getOrder',
      isCourierPlus: 'current/getIsCourierPlus',
      shopIsClosedNow: 'current/getShopIsClosedNow',
      requiredExtraFields: 'current/getRequiredExtraFields',
      hasEmptyExtraFields: 'current/getHasEmptyExtraFields',
    }),
    fulfilmentType () {
      if (this.lead) {
        if (this.lead.pickup) {
          return 'pickup'
        }
        if (this.lead.shipping) {
          return this.lead.shipping.fulfilment_type
        }
      }
      return 'courier'
    },
    flowComponents () {
      let flows = []
      switch(this.currentFlow) {
        case this.$userFlows.FILL_IN_TMP_PHONE_FLOW:
        case this.$userFlows.FILL_IN_PHONE_FLOW:
          flows.push({ id: "CompanyName", name: 'CompanyName', class: ""})
          flows.push({ id: "SecureCheckout", name: 'SecureCheckout', class: "mt-4"})
          if (this.form.oms && this.form.oms.enabled && !this.isCourierPlus) {
            if (this.form.oms.scheduling_enabled) {
              flows.push({ id: "DeliveryDateTime", name: 'DeliveryDateTime', class: "mt-4"})
            } else if (this.shopIsClosedNow) {
              flows.push({ id: "DeliveryDateTime", name: 'DeliveryDateTime', class: "mt-4"})
            }
          }
          flows.push({ id: "OrderSummary", name: 'OrderSummary', class: "mt-4"})
          break
        case this.$userFlows.FILL_IN_TAC_FLOW:
        case this.$userFlows.FILL_IN_TMP_NAME_FLOW:
        case this.$userFlows.FILL_IN_CONSENT_FLOW:
          flows = [
            { id: "CompanyName", name: 'CompanyName', class: ""},
            { id: "CustomerPhone", name: 'CustomerPhone', class: "mt-4"},
            { id: "SecureCheckout", name: 'SecureCheckout', class: "mt-4"},
            { id: "OrderSummary", name: 'OrderSummary', class: "mt-4"},
          ]
          break
        case this.$userFlows.FILL_IN_RECEIPIENT_FLOW:
        case this.$userFlows.FILL_IN_SHIPPING_METHOD_FLOW:
        case this.$userFlows.FILL_IN_DELIVERY_DATE_TIME:
        case this.$userFlows.FILL_IN_EXTRA_FIELDS_FLOW:
        case this.$userFlows.FILL_IN_PAYMENT_FLOW:
        case this.$userFlows.PROCEED_PAYMENT_FLOW: {
          flows.push({ id: "CompanyName", name: 'CompanyName', class: ""})
          const details = []
          if (this.form.zones.length > 0) {
            if (this.form.oms && this.form.oms.enabled && !this.isCourierPlus && this.lead.asking_delivery_date) {
              details.push({ id: "DeliveryDateTime", name: 'DeliveryDateTime', class: ""})
            }
            if (!this.lead.pickup) {
              details.push({ id: "RecipientForm", name: 'RecipientForm', class: ""})
            }
            details.push({ id: "DeliveryMethod", name: 'DeliveryMethod', class: ""})
            if (this.form.oms && this.form.oms.enabled && this.isCourierPlus) {
              if (this.lead.pickup) {
                details.push({ id: "DeliveryDateTime", name: 'DeliveryDateTime', class: ""})
              } else if (this.lead.shipping && this.lead.shipping.fulfilment_type === 'local_delivery') {
                details.push({ id: "DeliveryDateTime", name: 'DeliveryDateTime', class: ""})
              }
            }
          }
          details.push({ id: "PaymentMethod", name: 'PaymentMethod', class: ""})
          if (this.requiredExtraFields) {
            details.push({ id: "ExtraFields", name: 'ExtraFields', class: ""})
          }
          flows.push({ id: "CustomerPhone", name: 'CustomerPhone', class: "mt-4", params: { hideTopCorner: false, hideBottomCorner: details.length > 0 }})
          details.forEach((item, index) => {
            flows.push({ id: item.id, name: item.name, class: item.class, params: { hideTopCorner: true, hideBottomCorner: index != details.length - 1}})
          })
          flows.push({ id: "OrderSummary", name: 'OrderSummary', class: "mt-4"})
        }
      }
      return flows
    }
  },
  watch: {
    currentFlow () {
      this.fetchRequiredInfo()
    },
    fulfilmentType (val) {
      if (this.form.oms && this.form.oms.enabled && this.isCourierPlus) {
        switch(val) {
          case 'local_delivery':
          case 'pickup':
            if (!this.lead.asking_delivery_date) {
              this.$store.commit('current/updateFlowState', this.$userFlows.FILL_IN_DELIVERY_DATE_TIME)
            }
            break
        }
      }
    },
    'lead.shipping' (newVal, oldVal) {
      if (oldVal && !newVal) {
        console.log('trigger')
        this.$store.commit('current/updateFlowState', this.$userFlows.FILL_IN_SHIPPING_METHOD_FLOW)
      }
    }
  },
  mounted () {
    this.fetchRequiredInfo()
  },
  methods: {
    async fetchRequiredInfo () {
      this.isFetching = true
      switch(this.currentFlow) {
        case this.$userFlows.FILL_IN_TMP_PHONE_FLOW:
        case this.$userFlows.FILL_IN_PHONE_FLOW:
          await this.fetchOrderManagementSettings()
          if (this.form.oms && this.form.oms.enabled) {
            await this.fetchZones()
          }
          await this.fillInDefaultDateTime()
          break
        case this.$userFlows.FILL_IN_TMP_NAME_FLOW:
          await this.recoverUser()
          if (this.user) {
            await this.fetchZooData()
          }
          await this.fillInDefaultName()
          break
        case this.$userFlows.FILL_IN_RECEIPIENT_FLOW:
          await this.fillInDefaultAddress()
          if (this.lead.shipping_address) {
            this.goToNextFlow(1)
          }
          break
        case this.$userFlows.FILL_IN_PAYMENT_FLOW:
          await this.fillInDefaultPayment()
          if (this.order.payment) {
            this.goToNextFlow(1)
          }
          break
        case this.$userFlows.PROCEED_PAYMENT_FLOW:
          await this.recoverUser()

          // fetch zone
          if (this.user) {
            await this.fetchZooData()
          }
          await this.fetchOrderManagementSettings()
          await this.fetchZones()
          // fetch zone

          // fillin default from each flow
          await this.fillInDefaultDateTime()
          await this.fillInDefaultAddress()
          await this.fillInDefaultPayment()
          // fillin default from each flow


          this.$store.commit('current/updateDataIsReady')
          if (this.form.zones.length > 0 && !this.lead.pickup && !this.lead.shipping) {
            this.$store.commit('current/updateFlowState', this.$userFlows.FILL_IN_RECEIPIENT_FLOW)
            break
          }
          if (this.form.zones.length > 0 && this.lead.pickup && !this.lead.shipping_address) {
            this.$store.commit('current/updateFlowState', this.$userFlows.FILL_IN_RECEIPIENT_FLOW)
            break
          }
          if (this.form.oms && this.form.oms.enabled && this.isCourierPlus) {
            if (this.lead.pickup && !this.lead.asking_delivery_date) {
              this.$store.commit('current/updateFlowState', this.$userFlows.FILL_IN_DELIVERY_DATE_TIME)
              break
            } else if (!this.lead.asking_delivery_date && this.lead.shipping && this.lead.shipping.fulfilment_type === 'local_delivery') {
              this.$store.commit('current/updateFlowState', this.$userFlows.FILL_IN_DELIVERY_DATE_TIME)
              break
            }
          }
          if (!this.order.payment) {
            this.$store.commit('current/updateFlowState', this.$userFlows.FILL_IN_PAYMENT_FLOW)
            break
          }
          if (this.hasEmptyExtraFields) {
            this.$store.commit('current/updateFlowState', this.$userFlows.FILL_IN_EXTRA_FIELDS_FLOW)
            break
          }
          break
      }
      this.isFetching = false
    },
    async recoverUser () {
      let user =  localStorage.getItem('yzc_user')
      if (user) {
        user = JSON.parse(user)
      }
      if (user) {
        this.$store.commit('user/setUser', user)
      }
      if (this.user) {
        if (this.user.auth_phone != this.storedLeadPhone) {
          this.$store.commit('user/setUser', null)
        }
      }
    },
    async fetchZooData () {
      try {
        const resp = await this.$axios.get(`/zoo/${this.user.profile}`)
        let zooData = resp.data
        if (zooData.fast_checkout_consent) {
          if (zooData.shipping_address) {
            zooData.addresses = [
              {
                id: 'main',
                name: zooData.shipping_address.name || zooData.name || this.lead.name || 'Guest',
                phone: zooData.shipping_address.phone || zooData.phone || this.lead.phone,
                address_1: zooData.shipping_address.address_1,
                address_2: zooData.shipping_address.address_2,
                postcode: zooData.shipping_address.postcode,
                area: zooData.shipping_address.area || null,
                suburb: zooData.shipping_address.suburb || null,
                city: zooData.shipping_address.city,
                state: zooData.shipping_address.state,
                country: zooData.shipping_address.country
              }
            ]
          }
          const addressesResp = await this.$axios.get(`/zoo/${this.user.profile}/addresses`)
          let data = [...addressesResp.data.results]
          if (data.length > 0) {
            if (!zooData.addresses) {
              zooData.addresses = []
            }
            data.forEach(o => {
              let addr = {
                id: o.id,
                name: o.name || this.lead.name || 'Guest',
                phone: o.phone || this.lead.phone,
                address_1: o.address_1,
                address_2: o.address_2,
                postcode: o.postcode,
                area: o.area || null,
                suburb: o.suburb || null,
                city: o.city,
                state: o.state,
                country: o.country
              }
              zooData.addresses.push(addr)
            })
          }
        }
        this.$store.commit('current/setZooData', zooData)
      } catch {/**/}
    },
    async fetchOrderManagementSettings () {
      try {
        const form = this.lead ? this.lead.form : this.dataBin.form
        const resp = await this.$axios.get(`/public_oms/${form.slug}`)
        let oms = resp.data
        this.$store.commit('current/setOms', oms)
      } catch {/**/}
    },
    async fetchZones () {
      try {
        const form = this.lead ? this.lead.form : this.dataBin.form
        const resp = await this.$axios.get(`/forms/${form.slug}/zones`)
        let zones = resp.data
        if (zones.length > 0) {
          zones = zones.filter(f => f.shipping_methods.length > 0)
        }
        this.$store.commit('current/setZoneData', zones)
      } catch {/**/}
    },
    async fillInDefaultDateTime () {
      if (this.order.datetime) {
        return
      }
      if (this.zones.length === 0) {
        return
      }
      if (!this.form.oms) {
        return
      }
      if (!this.form.oms.enabled) {
        return
      }
      if (!this.lead || !this.lead.asking_delivery_date) {
        const data = {
          is_ASAP: true
        }
        this.$store.commit('current/updateDatetime', data)
        return
      }
      const data = {
        is_ASAP: this.lead.is_ASAP,
        asking_delivery_date: this.lead.asking_delivery_date,
        asking_delivery_time: this.lead.asking_delivery_time,
        asking_order_type: this.lead.asking_order_type
      }
      this.$store.commit('current/updateDatetime', data)
    },
    async fillInDefaultName () {
      if (!this.user || !this.zooData) {
        return
      }
      const name = this.zooData.first_name + ' ' + this.zooData.last_name
      this.$store.commit('current/updateOrderLeadName', name)
    },
    async fillInDefaultAddress () {
      if (this.lead.shipping_address) {
        return
      }
      if (this.zones.length === 0) {
        return
      }
      if (!this.user || !this.zooData) {
        return
      }
      if (!this.zooData.fast_checkout_consent) {
        return
      }
      if (!this.zooData.addresses || this.zooData.addresses.length === 0) {
        return
      }
      try {
        let param = { shipping_address: this.zooData.addresses[0] }
        await this.axios.patch(`/leads/${this.lead.pid}/`, param)
        const resp = await this.$axios.get(`/leads/${this.lead.pid}/`)
        this.$store.commit('current/updateLead', resp.data)
      } catch {/**/}
    },
    async fillInDefaultPayment () {
      if (this.order.payment) {
        return
      }
      if (!this.user || !this.zooData) {
        return
      }
      if (!this.zooData.fast_checkout_consent) {
        return
      }
      if (!this.zooData.preferred_bank) {
        return
      }
      const payment = {
        method: 'fpx',
        data: {
          gateway: this.lead.form.company.default_payment_gateway,
          bank_code: this.zooData.preferred_bank,
          description: `#${this.lead.rid} ${this.lead.name} from yezza`,
          lead: this.lead.id
        }
      }
      this.$store.commit('current/updatePayment', payment)
    }
  }
}
</script>

<style scoped>
</style>