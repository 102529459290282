<template lang="pug">
  .text-layout(style="white-space: pre-wrap;")
    div(v-if="text" v-for="(line, index) in lines" :key="index + '-line'")
      span(v-for="(word, index) in line.words" :key="index+ '-word'")
        a.blue--text(v-if="word.type == 'link'" @click="openLink(word.text)" style="text-decoration: none;")
          template(v-if="bold")
            b {{ word.text }}
          template(v-else) {{ word.text }}
        span(v-else) {{ word.text }}
        span {{ ' ' }}

</template>

<script>
export default {
  name: 'LinkifyText',
  props: {
    text: {
      type: String,
      default: ''
    },
    openNewTab: {
      type: Boolean,
      default: true
    },
    bold: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    lines () {
      if (!this.text) {
        return []
      }
      const finalLines = []
      const lines = this.text.split(/\r\n|\r|\n/)
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i]
        const words = line.split(' ').map((m) => {
          let type = 'text'
          if (this.validURL(m)) {
            type = 'link'
          } else {
            type = 'text'
          }
          return {
            text: m,
            type
          }
        })
        finalLines.push({
          words
        })
      }
      return finalLines
    }
  },
  methods: {
    validURL (str) {
      return /(https?:\/\/)?(www\.|\w+\.)?(\w+\.)(com?|net|org|io|me|my|gl|ly)((\.|\/)[^/.]*(.*))?/i.test(str)
    },
    openLink (str) {
      let link = str
      if (!str.startsWith('http')) {
        link = `https://${link}`
      }
      if (this.openNewTab) {
        window.open(link)
      } else {
        location.href = link
      }
    }
  }
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>
