<template lang="pug">
  div
    v-row(no-gutters @click="$store.commit('current/updateEditingSection', $userFlows.FILL_IN_PAYMENT_FLOW)")
      v-col(cols="4")
        div.p2.basic600--text(style="width: 100px") {{ $t('payment') }}
      v-col(cols="7")
        transition(name="title")
          div(v-if="!(editingSection === $userFlows.FILL_IN_PAYMENT_FLOW)")
            div.d-flex.align-center(v-if="payment.method === 'fpx'")
              template(v-if="banks.filter(f => f.code === payment.data.bank_code).length > 0")
                div
                  v-img(:src="banks.filter(f => f.code === payment.data.bank_code)[0].image ? banks.filter(f => f.code === payment.data.bank_code)[0].image : require('@/assets/image_placeholder.png')" width=20, height=20)
                div.p2.primary--text.ml-2 {{ banks.filter(f => f.code === payment.data.bank_code)[0].name }}
              template(v-else)
                v-progress-circular(:width="2" :size="20" color="grey" indeterminate)
            div.d-flex.align-center(v-else-if="payment.method === 'mcvisa'")
              div
                v-img(:src="mcVisa.image ? mcVisa.image : require('@/assets/image_placeholder.png')" width=20, height=20)
              div.p2.primary--text.ml-2 {{ mcVisa.name }}
            div.d-flex.align-center(v-else)
              div.p2.primary--text(v-if="payment.method === 'stripe_card'") {{ $t('card_payment') }}
              div.p2.primary--text(v-if="payment.method === 'e_wallet'") {{ payment.remarks.display_name }}
              div.p2.primary--text(v-if="payment.method === 'stripe_alipay'") Ali pay
              div.p2.primary--text(v-if="payment.method === 'xendit'") {{ $t('online_banking_credit_cards_ewallets') }}
              div.p2.primary--text(v-if="payment.method === 'manual'") {{ $t('manual_transfer') }}
              div.p2.primary--text(v-if="payment.method === 'pas'") {{ $t('pay_at_store') }}
              div.p2.primary--text(v-if="payment.method === 'cod'") {{ $t('cash_on_delivery') }}
      v-col(cols="1" align="end")
        eva-icon(:name="editingSection === $userFlows.FILL_IN_PAYMENT_FLOW ? 'chevron-up' : 'chevron-down'" :fill="$vuetify.theme.themes[$theme].info400" height="20" width="20" )
    transition(name="content")
      div(v-if="editingSection === $userFlows.FILL_IN_PAYMENT_FLOW")
        div.mt-5(v-if="paymentMethods.includes('fpx')")
          v-row(no-gutters @click="selectPaymentMethod('fpx')")
            v-col.pr-2.shrink
              eva-icon(:name="payment.method === 'fpx' ? 'radio-button-on' : 'radio-button-off'" :fill="payment.method === 'fpx' ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].basic600" height="20" width="20" )
            v-col
              div.p2.primary--text {{ $t('online_banking') }}
              template(v-if="payment.method === 'fpx'")
                v-row.py-2(v-if="banks.filter(f => f.code === payment.data.bank_code).length === 1" no-gutters align="center")
                  v-col.shrink.pr-1
                    v-card
                      v-img(:src="banks.filter(f => f.code === payment.data.bank_code)[0].image ? banks.filter(f => f.code === payment.data.bank_code)[0].image : require('@/assets/image_placeholder.png')" width=20, height=20)
                  v-col.pl-1
                    div.p2.basic700--text {{ banks.filter(f => f.code === payment.data.bank_code)[0].name }}
                  v-col.shrink.pl-1
                    v-btn.button-Aa-tiny.px-0(x-small text color="transparent" @click="openEditOnlineBankingDialog = true")
                      div.info500--text Change
        div.mt-5(v-if="paymentMethods.includes('e_wallet')")
          v-row(no-gutters @click="selectPaymentMethod('e_wallet')")
            v-col.pr-2.shrink
              eva-icon(:name="payment.method === 'e_wallet' ? 'radio-button-on' : 'radio-button-off'" :fill="payment.method === 'e_wallet' ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].basic600" height="20" width="20" )
            v-col
              div.p2.primary--text {{ $t('e_wallet') }}
              template(v-if="payment.method === 'e_wallet'")
                v-row.py-2(v-if="eWallets.filter(f => f.code === payment.data.bank_code).length === 1" no-gutters align="center")
                  v-col.shrink.pr-1
                    v-card
                      v-img(:src="eWallets.filter(f => f.code === payment.data.bank_code)[0].image ? eWallets.filter(f => f.code === payment.data.bank_code)[0].image : require('@/assets/image_placeholder.png')" width=20, height=20)
                  v-col.pl-1
                    div.p2.basic700--text {{ payment.remarks.display_name }}
                  v-col.shrink.pl-1
                    v-btn.button-Aa-tiny.px-0(x-small text color="transparent" @click="openEditEWalletDialog = true")
                      div.info500--text {{ $t('change') }}
        div.mt-5(v-if="paymentMethods.includes('stripe_card')")
          v-row(no-gutters @click="selectPaymentMethod('stripe_card')")
            v-col.pr-2.shrink
              eva-icon(:name="payment.method === 'stripe_card' ? 'radio-button-on' : 'radio-button-off'" :fill="payment.method === 'stripe_card' ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].basic600" height="20" width="20" )
            v-col
              div.p2.primary--text {{ $t('credit_debit_card') }}
            v-col.shrink.pl-1(v-if="payment.method === 'stripe_card'")
              v-btn.button-Aa-tiny.px-0(x-small text color="transparent" @click="openEditStripePaymentDialog = true")
                div.info500--text {{ $t('change') }}
        div.mt-5(v-if="paymentMethods.includes('mcvisa')")
          v-row(no-gutters @click="selectPaymentMethod('mcvisa')")
            v-col.pr-2.shrink
              eva-icon(:name="payment.method === 'mcvisa' ? 'radio-button-on' : 'radio-button-off'" :fill="payment.method === 'mcvisa' ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].basic600" height="20" width="20" )
            v-col
              div.p2.primary--text {{ $t('credit_debit_card') }}
              template(v-if="payment.method === 'mcvisa'")
                v-row.py-2(no-gutters align="center")
                  v-col.shrink.pr-1
                    v-card
                      v-img(:src="mcVisa && mcVisa.image ? mcVisa.image : require('@/assets/image_placeholder.png')" width=20, height=20)
                  v-col.pl-1
                    div.p2.basic700--text {{ mcVisa.name }}
        //-           v-col.shrink.pl-1
        //-             v-btn.button-Aa-tiny.px-0(x-small text color="transparent" @click="")
        //-               div.primary400--text Change
        //-         div.p2.basic600--text.mt-2 Bill to
        //-         div.p2.primary--text.mt-1(v-if="payment.data && payment.data.billing") {{ payment.data.billing.address_1 + ',' }} {{ payment.data.billing.address_2 + ',' }} {{ payment.data.billing.postcode + ',' }} {{ payment.data.billing.city + ',' }} {{ payment.data.billing.state + ',' }} {{ payment.data.billing.country }}
        div.mt-5(v-if="paymentMethods.includes('xendit')")
          v-row(no-gutters @click="selectPaymentMethod('xendit')")
            v-col.pr-2.shrink
              eva-icon(:name="payment.method === 'xendit' ? 'radio-button-on' : 'radio-button-off'" :fill="payment.method === 'xendit' ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].basic600" height="20" width="20" )
            v-col
              div.p2.primary--text {{ $t('online_banking_credit_cards_ewallets') }}
        div.mt-5(v-if="paymentMethods.includes('manual')")
          v-row(no-gutters @click="selectPaymentMethod('manual')")
            v-col.pr-2.shrink
              eva-icon(:name="payment.method === 'manual' ? 'radio-button-on' : 'radio-button-off'" :fill="payment.method === 'manual' ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].basic600" height="20" width="20" )
            v-col
              div.p2.primary--text {{ $t('manual_bank_transfer') }}
          template(v-if="payment.method === 'manual'")
            div.p2.basic600--text.mt-2 {{ $t('transfer_after_clicking_pay') }}
        div.mt-5(v-if="paymentMethods.includes('cod')")
          v-row(no-gutters @click="selectPaymentMethod('cod')")
            v-col.pr-2.shrink
              eva-icon(:name="payment.method === 'cod' ? 'radio-button-on' : 'radio-button-off'" :fill="payment.method === 'cod' ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].basic600" height="20" width="20" )
            v-col
              div.p2.primary--text {{ $t('cash_on_delivery') }}
          template(v-if="payment.method === 'cod'")
            div.p2.basic600--text.mt-2 {{ $t('make_full_payment_delivery') }}
        div.mt-5(v-if="paymentMethods.includes('pas')")
          v-row(no-gutters @click="selectPaymentMethod('pas')")
            v-col.pr-2.shrink
              eva-icon(:name="payment.method === 'pas' ? 'radio-button-on' : 'radio-button-off'" :fill="payment.method === 'pas' ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].basic600" height="20" width="20" )
            v-col
              div.p2.primary--text {{ $t('pay_at_store') }}Pay at store
          template(v-if="payment.method === 'pas'")
            div.p2.basic600--text.mt-2 {{ $t('make_full_payment_store') }}
    .modal
      edit-online-banking(v-if="openEditOnlineBankingDialog" :open.sync="openEditOnlineBankingDialog" :parentPayment.sync="payment" :parentBanks="banks")
      edit-e-wallet-banking(v-if="openEditEWalletDialog" :open.sync="openEditEWalletDialog" :parentPayment.sync="payment" :parentBanks="eWallets")
      EditStripePayment(v-if="openEditStripePaymentDialog" :open.sync="openEditStripePaymentDialog" :parentPayment.sync="payment")
</template>

<script>
import { mapGetters } from 'vuex'
import EditOnlineBanking from '@/components/dialogs/EditOnlineBanking.vue'
import EditEWalletBanking from '@/components/dialogs/EditEWalletBanking.vue'
import EditStripePayment from '@/components/dialogs/EditStripePayment.vue'

export default {
  name: 'CollapsePayment',
  components: { EditOnlineBanking, EditEWalletBanking, EditStripePayment },
  props: ['active'],
  data: () => ({
    isLoading: false,
    openEditOnlineBankingDialog: false,
    openEditEWalletDialog: false,
    openEditStripePaymentDialog: false,
    company: null,
    paymentMethods: [],
    paymentProvider: '',
    manualBanks: [],
    banks: [],
    eWallets: [],
    mcVisa: {},
    bankImages: [
      { name: '2C2P Payment Gateway', image: require('@/assets/images/fpx/2c2p.png') },
      { name: 'Affin Bank', image: require('@/assets/images/fpx/ABB0233.png') },
      { name: 'Alliance Bank', image: require('@/assets/images/fpx/ABMB0212.png') },
      { name: 'Alliance Bank Malaysia', image: require('@/assets/images/fpx/ABMB0212.png') },
      { name: 'AGRONet', image: require('@/assets/images/fpx/AGRO01.png') },
      { name: 'AmBank', image: require('@/assets/images/fpx/AMBB0209.png') },
      { name: 'CIMB Clicks', image: require('@/assets/images/fpx/BCBB0235.png') },
      { name: 'CIMB', image: require('@/assets/images/fpx/BCBB0235.png') },
      { name: 'Bank Islam', image: require('@/assets/images/fpx/BIMB0340.png') },
      { name: 'Bank Islam Malaysia', image: require('@/assets/images/fpx/BIMB0340.png') },
      { name: 'Bank Rakyat', image: require('@/assets/images/fpx/BKRM0602.png') },
      { name: 'Bank Muamalat', image: require('@/assets/images/fpx/BMMB0341.png') },
      { name: 'Bank Muamalat Malaysia', image: require('@/assets/images/fpx/BMMB0341.png') },
      { name: 'BSN', image: require('@/assets/images/fpx/BSN0601.png') },
      { name: 'Bank Simpanan Nasional', image: require('@/assets/images/fpx/BSN0601.png') },
      { name: 'Hong Leong Bank', image: require('@/assets/images/fpx/HLB0224.png') },
      { name: 'HSBC Bank', image: require('@/assets/images/fpx/HSBC0223.png') },
      { name: 'HSBC', image: require('@/assets/images/fpx/HSBC0223.png') },
      { name: 'KFH', image: require('@/assets/images/fpx/KFH0346.png') },
      { name: 'Kuwait Finance House', image: require('@/assets/images/fpx/KFH0346.png') },
      { name: 'Maybank2E', image: require('@/assets/images/fpx/MBB0228.png') },
      { name: 'Maybank2U', image: require('@/assets/images/fpx/MBB0228.png') },
      { name: 'Maybank', image: require('@/assets/images/fpx/MBB0228.png') },
      { name: 'OCBC Bank', image: require('@/assets/images/fpx/OCBC0229.png') },
      { name: 'Public Bank', image: require('@/assets/images/fpx/PBB0233.png') },
      { name: 'RHB Now', image: require('@/assets/images/fpx/RHB0218.png') },
      { name: 'RHB Bank', image: require('@/assets/images/fpx/RHB0218.png') },
      { name: 'Standard Chartered', image: require('@/assets/images/fpx/SCB0216.png') },
      { name: 'UOB Bank', image: require('@/assets/images/fpx/UOB0226.png') },
      { name: 'United Overseas Bank', image: require('@/assets/images/fpx/UOB0226.png') },
      { name: 'PayPal', image: require('@/assets/images/fpx/Paypal.png') },
      { name: 'Visa / Mastercard', image: require('@/assets/images/fpx/VISA-MC.png') },
      { name: 'SBI Bank A', image: require('@/assets/images/fpx/FPX.png') },
      { name: 'SBI Bank B', image: require('@/assets/images/fpx/FPX.png') },
      { name: 'SBI Bank C', image: require('@/assets/images/fpx/FPX.png') },
      { name: 'BOCM01', image: require('@/assets/images/fpx/FPX.png') },
      { name: 'LOAD001', image: require('@/assets/images/fpx/FPX.png') },
      { name: 'GrabPay', image: require('@/assets/images/e-wallet/grabpay.png') },
      { name: 'Alipay', image: require('@/assets/images/e-wallet/alipay.png') },
    ],
    rules: {
      not_empty: [v => !!v || 'This field is required']
    }
  }),
  computed: {
    ...mapGetters({
      payment: 'current/getPayment',
      lead: 'current/getLead',
      editingSection: 'current/getEditingSection',
    })
  },
  watch: {
    'lead.shipping' () {
      this.paymentMethods = []
      this.banks = []
      this.manualBanks = []
      this.fetchLead()
    }
    // 'payment.card_date' (val) {
    //   if (val.length === 1) {
    //     if (val > 1) {
    //       this.payment.card_date = '0' + val
    //     }
    //   }
    //   if (val.length === 2) {
    //     if (val > 12) {
    //       this.payment.card_date = 12
    //     }
    //     this.payment.card_date += '/'
    //   }
    // }
  },
  mounted () {
    this.company = this.lead.form.company
    this.paymentProvider = this.company.default_payment_gateway
    this.fetchLead()
  },
  methods: {
    fetchLead () {
      this.$axios.get(`/leads/${this.lead.pid}/`)
        .then(() => {
          this.fetchPaymentMethods()
          this.fetchGateway()
          this.fetchManualTransfer()
        })
        .catch(() => {})
    },
    fetchPaymentMethods () {
      if (this.lead.allow_cod) {
        this.paymentMethods.push('cod')
      }
      if (this.lead.allow_pay_at_shop) {
        this.paymentMethods.push('pas')
      }
      if (this.paymentProvider) {
        this.paymentMethods.push('fpx')
      }
      if (this.company.currency.currency_code === 'IDR') {
        this.$axios.get(`/companies/${this.company.id}/payment_gateway_status/`)
        .then((response) => {
          console.log('aasa', response.data)
          if (response.data['xendit'] === true) {
            this.paymentMethods.push('xendit')
          }
        })
        .catch(() => {})
      }
    },
    fetchGateway() {
      if (!this.paymentProvider || this.paymentProvider == null) {
        return
      }
      this.$axios.get(`/banks/?page_size=100&gateway=${this.paymentProvider}&active=true&staging=${process.env.VUE_APP_STAGING_BANKS}&company=${this.company.id}`)
        .then((resp) => {
          resp.data.results.forEach(bank => {
            const selectedBank = this.bankImages.filter((obj) => {
              return obj.name.toLowerCase() == bank.name.toLowerCase()
            })
            bank['image'] = ''
            if (selectedBank.length != 0) {
              bank['image'] = selectedBank[0].image
            }
            if (bank.category === 'senangpay') {
              this.mcVisa = { ...bank }
              this.paymentMethods.push('mcvisa')
            } else if (bank.category === 'stripe_card') {
              this.paymentMethods.push('stripe_card')
            } else if (bank.category === 'stripe_alipay' || bank.category === 'stripe_grabpay') {
              this.paymentMethods.push('e_wallet')
              this.eWallets.push(bank)
            } else {
              this.banks.push(bank)
            }
          })
          if (this.banks.length === 0) {
            this.paymentMethods = this.paymentMethods.filter(bank => bank !== 'fpx')
          }
          if (this.payment && this.payment.method === 'fpx') {
            this.$store.commit('current/updatePayment', { ...this.payment, remarks: { id: 'fpx', display_name: this.banks.filter(f => f.code === this.payment.data.bank_code)[0].name}})
          }
        }).catch(() => {})
    },
    fetchManualTransfer () {
      this.$axios.get(`/leads/${this.lead.pid}/directpay/?count=100`)
      .then((response) => {
        response.data.results.forEach(bank => {
          if (bank.enabled) {
            this.manualBanks.push(bank)
          }
        })
        if (this.manualBanks.length > 0) {
          this.paymentMethods.push('manual')
        }
      }).catch(() => {})
    },
    // fetchPostcodes (val) {
    //   if (val.length !== 5) {
    //     return
    //   }
    //   this.isLoading = true
    //   this.$axios.get(`/shipping/postcodes?postcode=${this.payment.billing.postcode}`).then((response) => {
    //     const addresses = response.data
    //     if (addresses.length > 0 && addresses[0].postcode) {
    //       this.payment.billing.city = addresses[0].city
    //       this.payment.billing.state = addresses[0].state
    //       this.payment.billing.country = addresses[0].country
    //     }
    //   })
    //   .catch(() => {
    //   })
    //   .finally(() => {
    //     this.isLoading = false
    //   })
    // },
    selectPaymentMethod (val) {
      if (val === 'fpx') {
        if (this.payment.method !== 'fpx') {
          this.openEditOnlineBankingDialog = true
        }
      } else if (val === 'e_wallet') {
        if (this.payment.method !== 'e_wallet') {
          this.openEditEWalletDialog = true
        }
      } else if (val === 'stripe_card') {
        if (this.payment.method !== 'stripe_card') {
          this.openEditStripePaymentDialog = true
        }
      } else {
        let param = {}
        if (val === 'mcvisa') {
          param = {
            method: val,
            data: {
              gateway: this.paymentProvider,
              bank_code: this.mcVisa.code,
              description: `#${this.lead.rid} ${this.lead.name} from yezza`,
              lead: this.lead.id
            },
            remarks: {
              id: 'mcvisa',
              display_name: this.mcVisa.name
            }
          }
        } else if (val === 'cod') {
          param = {
            method: val,
            data: { cod: true, status: 'processed' },
            remarks: {
              id: 'cod',
              display_name: 'Cash on delivery'
            }
          }
        } else if (val === 'pas') {
          param = {
            method: val,
            data: { pay_at_shop: true, status: 'processed' },
            remarks: {
              id: 'pas',
              display_name: 'Pay at store'
            }
          }
        } else if (val === 'xendit') {
          param = {
            method: val,
            data: {
              gateway: 'xendit',
              description: `#${this.lead.rid} ${this.lead.name} from yezza`,
              lead: this.lead.id
            },
            remarks: {
              id: 'xendit',
              display_name: 'Online Banking / Credit Cards/ eWallets'
            }
          }
        } else {
          param = {
            method: val,
            remarks: {
              id: 'manual',
              display_name: 'Manual bank transfer'
            }
          }
        }
        this.$store.commit('current/updatePayment', param)
      }
    },
    checkCvv (evt) {
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    checkCardDate (evt) {
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    }
  }
}
</script>

<style scoped>
.title-enter-active {
  transition: opacity .3s;
}
.title-leave-active {
  transform: scale(0,0) .3s;
}
.title-enter, .title-leave-to {
  opacity: 0;
}
.content-enter-active {
  transition: opacity .3s;
}
.content-leave-active {
  transform: scale(0,0) .3s;
}
.content-enter, .content-leave-to {
  opacity: 0;
}
</style>
