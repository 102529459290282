<template lang="pug">
  div
    .current(v-if="form.flowState === $userFlows.FILL_IN_PAYMENT_FLOW")
      v-card.mt-4
        div.px-4.pt-4
          div.h6.primary--text
            span {{ $t('payment') }}
        div.mt-2
          v-form(ref="form")
            .fpx(v-if="paymentMethods.includes('fpx')")
              div.pa-4
                v-row(no-gutters @click="selectPaymentMethod('fpx')")
                  v-col.pr-2.shrink
                    eva-icon(:name="payment.method === 'fpx' ? 'radio-button-on' : 'radio-button-off'" :fill="payment.method === 'fpx' ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].basic600" height="20" width="20" )
                  v-col
                    div.s1.primary--text {{ $t('online_banking') }}
                template(v-if="payment.method === 'fpx'")
                  v-select.mt-2(v-model="payment.bank_code" :items="banks" item-text="name" item-value="code" item-color="basic100" :placeholder="$t('select_bank')" background-color="basic200" outlined dense hide-details :rules="rules.not_empty")
                    template(v-slot:append)
                      eva-icon(name="chevron-down-outline" width="24" height="24" :fill="$vuetify.theme.themes[$theme].basic700")
                    template(v-slot:item="{ item, on, attrs }")
                      v-row.py-3(no-gutters align="center")
                        v-col.shrink.pr-1
                          v-card(flat tile)
                            v-img(:src="item.image ? item.image : require('@/assets/image_placeholder.png')" width=24, height=24)
                        v-col.pl-1
                          div {{ item.name }}
              v-divider.basic300
            .e-wallet(v-if="paymentMethods.includes('e_wallet')")
              div.pa-4
                v-row(no-gutters @click="selectPaymentMethod('e_wallet')")
                  v-col.pr-2.shrink
                    eva-icon(:name="payment.method === 'e_wallet' ? 'radio-button-on' : 'radio-button-off'" :fill="payment.method === 'e_wallet' ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].basic600" height="20" width="20" )
                  v-col
                    div.s1.primary--text {{ $t('e_wallet') }}
                template(v-if="payment.method === 'e_wallet'")
                  v-select.mt-2(v-model="payment.bank_code" :items="eWallets" item-text="name" item-value="code" item-color="basic100" :placeholder="$t('select_provider')" background-color="basic200" outlined dense hide-details :rules="rules.not_empty")
                    template(v-slot:append)
                      eva-icon(name="chevron-down-outline" width="24" height="24" :fill="$vuetify.theme.themes[$theme].basic700")
                    template(v-slot:item="{ item, on, attrs }")
                      v-row.py-3(no-gutters align="center")
                        v-col.shrink.pr-1
                          v-card(flat tile)
                            v-img(:src="item.image ? item.image : require('@/assets/image_placeholder.png')" width=24, height=24)
                        v-col.pl-1
                          div {{ item.name }}
              v-divider.basic300
            .stripe(v-if="paymentMethods.includes('stripe_card')")
              div.pa-4
                v-row(no-gutters @click="selectPaymentMethod('stripe_card')")
                  v-col.pr-2.shrink
                    eva-icon(:name="payment.method === 'stripe_card' ? 'radio-button-on' : 'radio-button-off'" :fill="payment.method === 'stripe_card' ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].basic600" height="20" width="20" )
                  v-col
                    div.s1.primary--text {{ $t('credit_debit_card') }}
                template(v-if="payment.method === 'stripe_card'")
                  p.mb-1.mt-2.label.basic600--text.text-uppercase {{ $t('card_number_exp_date_cvv') }}
                  v-stripe-card.pt-2(v-if="payment.method === 'stripe_card'" dense solo flat background-color="basic200" height="40" ref="stripeCard" color="primary" @ready="cardReady" @input="addCard($event)" :api-key="stripeKey" outlined hide-details="auto")
              v-divider.basic300
            .mcvisa(v-if="paymentMethods.includes('mcvisa')")
              div.pa-4
                v-row(no-gutters @click="selectPaymentMethod('mcvisa')")
                  v-col.pr-2.shrink
                    eva-icon(:name="payment.method === 'mcvisa' ? 'radio-button-on' : 'radio-button-off'" :fill="payment.method === 'mcvisa' ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].basic600" height="20" width="20" )
                  v-col
                    div.s1.primary--text {{ $t('credit_debit_card') }}
                template(v-if="payment.method === 'mcvisa'")
                  v-row.py-3(no-gutters align="center")
                    v-col.shrink.pr-1
                      v-card
                        v-img(:src="mcVisa.image || require('@/assets/image_placeholder.png')" width=23, height=23)
                    v-col.pl-1
                      div.p1.basic700--text {{ mcVisa.name }}
                //-   v-text-field.mt-2(v-model="payment.card_number" placeholder="Card number" type="number" background-color="basic200" outlined dense hide-details :rules="rules.credit_card")
                //-   v-text-field.mt-2(v-model="payment.card_name" placeholder="Card holder name" background-color="basic200" outlined dense  hide-details :rules="rules.not_empty")
                //-   v-row(no-gutters)
                //-     v-col.pr-2
                //-       v-text-field.mt-2(v-model="payment.card_date" placeholder="MM/YY" maxlength="5" background-color="basic200" outlined dense hide-details :rules="rules.not_empty" @keypress="checkCardDate($event)")
                //-     v-col.pl-2
                //-       v-text-field.mt-2(v-model="payment.card_cvv" placeholder="CVV" maxlength="3" background-color="basic200" outlined dense hide-details :rules="rules.not_empty" @keypress="checkCvv($event)")
                //-   div.mt-4
                //-     y-checkbox.my-auto.mx-0.pa-0(v-model="payment.billing.ship_address")
                //-       template(v-slot:label)
                //-         div.c1.primary--text Billing address same as shipping
                //-   template(v-if="!payment.billing.ship_address")
                //-     v-text-field.mt-4(v-model="payment.billing.address_1" placeholder="Address 1" background-color="basic200" outlined dense :rules="rules.not_empty")
                //-     v-text-field(v-model="payment.billing.address_2" placeholder="Address 2" background-color="basic200" outlined dense)
                //-     v-text-field(v-model="payment.billing.postcode" placeholder="Postcode" background-color="basic200" outlined dense :rules="rules.not_empty" @keyup="fetchPostcodes(payment.billing.postcode)")
                //-     v-text-field(v-model="payment.billing.city" placeholder="City" background-color="basic200" outlined dense :rules="rules.not_empty" :loading="isLoading")
                //-     v-text-field(v-model="payment.billing.state" placeholder="State" background-color="basic200" outlined dense :rules="rules.not_empty" :loading="isLoading")
                //-     v-text-field(v-model="payment.billing.country" placeholder="Country" background-color="basic200" outlined dense :rules="rules.not_empty" :loading="isLoading")
              v-divider.basic300
            .xendit(v-if="paymentMethods.includes('xendit')")
              div.pa-4
                v-row(no-gutters @click="selectPaymentMethod('xendit')")
                  v-col.pr-2.shrink
                    eva-icon(:name="payment.method === 'xendit' ? 'radio-button-on' : 'radio-button-off'" :fill="payment.method === 'xendit' ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].basic600" height="20" width="20" )
                  v-col
                    div.s1.primary--text {{ $t('online_banking_credit_cards_ewallets') }}
              v-divider.basic300
            .manual(v-if="paymentMethods.includes('manual')")
              div.pa-4
                v-row(no-gutters @click="selectPaymentMethod('manual')")
                  v-col.pr-2.shrink
                    eva-icon(:name="payment.method === 'manual' ? 'radio-button-on' : 'radio-button-off'" :fill="payment.method === 'manual' ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].basic600" height="20" width="20" )
                  v-col
                    div.s1.primary--text {{ $t('manual_bank_transfer') }}
                template(v-if="payment.method === 'manual'")
                  div.p2.basic600--text.mt-2 {{ $t('transfer_after_clicking_pay') }}
              v-divider.basic300
            .cod(v-if="paymentMethods.includes('cod')")
              div.pa-4
                v-row(no-gutters @click="selectPaymentMethod('cod')")
                  v-col.pr-2.shrink
                    eva-icon(:name="payment.method === 'cod' ? 'radio-button-on' : 'radio-button-off'" :fill="payment.method === 'cod' ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].basic600" height="20" width="20" )
                  v-col
                    div.s1.primary--text {{ $t('cash_on_delivery') }}
                template(v-if="payment.method === 'cod'")
                  div.p2.basic600--text.mt-2 {{ $t('make_full_payment_delivery') }}
              v-divider.basic300
            .pas(v-if="paymentMethods.includes('pas')")
              div.pa-4
                v-row(no-gutters @click="selectPaymentMethod('pas')")
                  v-col.pr-2.shrink
                    eva-icon(:name="payment.method === 'pas' ? 'radio-button-on' : 'radio-button-off'" :fill="payment.method === 'pas' ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].basic600" height="20" width="20" )
                  v-col
                    div.s1.primary--text {{ $t('pay_at_store') }}
                template(v-if="payment.method === 'pas'")
                  div.p2.basic600--text.mt-2 {{ $t('make_full_payment_store') }}

        div.pa-4
          v-btn.button-AA-medium(color="info500" small height="40" block :disabled="!payment || !payment.method" @click="savePayment")
            div(:class="{ 'white--text': !Boolean(!payment || !payment.method) }") {{ hasEmptyExtraFields ? $t('continue_to_additional_info') : $t('continue_to_review') }}

    .other(v-else)
      .empty(v-if="!order.payment")
        v-card.mt-4(flat)
          div.pa-4
            div.s1.basic600--text {{ $t('payment') }}
      .collapse.clickable(v-else)
        v-divider.basic300
        v-card.pa-4.elevation-0(:class="[{ 'hide-top-corner-radius': params.hideTopCorner }, { 'hide-bottom-corner-radius': params.hideBottomCorner }]")
          collapse-payment

</template>

<script>
import { mapGetters } from 'vuex'
import CollapsePayment from '@/components/collapse/CollapsePayment.vue'
import VStripeCard from 'v-stripe-elements/lib/VStripeCard'

export default {
  name: 'PaymentMethod',
  components: { CollapsePayment, VStripeCard },
  props: {
    params: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    isLoading: false,
    payment: {},
    company: null,
    paymentMethods: [],
    paymentProvider: '',
    manualBanks: [],
    banks: [],
    eWallets: [],
    mcVisa: {},
    stripeElement: {},
    stripeKey: process.env.VUE_APP_STRIPE_API_KEY,
    bankImages: [
      { name: '2C2P Payment Gateway', image: require('@/assets/images/fpx/2c2p.png') },
      { name: 'Affin Bank', image: require('@/assets/images/fpx/ABB0233.png') },
      { name: 'Alliance Bank', image: require('@/assets/images/fpx/ABMB0212.png') },
      { name: 'Alliance Bank Malaysia', image: require('@/assets/images/fpx/ABMB0212.png') },
      { name: 'AGRONet', image: require('@/assets/images/fpx/AGRO01.png') },
      { name: 'AmBank', image: require('@/assets/images/fpx/AMBB0209.png') },
      { name: 'CIMB Clicks', image: require('@/assets/images/fpx/BCBB0235.png') },
      { name: 'CIMB', image: require('@/assets/images/fpx/BCBB0235.png') },
      { name: 'Bank Islam', image: require('@/assets/images/fpx/BIMB0340.png') },
      { name: 'Bank Islam Malaysia', image: require('@/assets/images/fpx/BIMB0340.png') },
      { name: 'Bank Rakyat', image: require('@/assets/images/fpx/BKRM0602.png') },
      { name: 'Bank Muamalat', image: require('@/assets/images/fpx/BMMB0341.png') },
      { name: 'Bank Muamalat Malaysia', image: require('@/assets/images/fpx/BMMB0341.png') },
      { name: 'BSN', image: require('@/assets/images/fpx/BSN0601.png') },
      { name: 'Bank Simpanan Nasional', image: require('@/assets/images/fpx/BSN0601.png') },
      { name: 'Hong Leong Bank', image: require('@/assets/images/fpx/HLB0224.png') },
      { name: 'HSBC Bank', image: require('@/assets/images/fpx/HSBC0223.png') },
      { name: 'HSBC', image: require('@/assets/images/fpx/HSBC0223.png') },
      { name: 'KFH', image: require('@/assets/images/fpx/KFH0346.png') },
      { name: 'Kuwait Finance House', image: require('@/assets/images/fpx/KFH0346.png') },
      { name: 'Maybank2E', image: require('@/assets/images/fpx/MBB0228.png') },
      { name: 'Maybank2U', image: require('@/assets/images/fpx/MBB0228.png') },
      { name: 'Maybank', image: require('@/assets/images/fpx/MBB0228.png') },
      { name: 'OCBC Bank', image: require('@/assets/images/fpx/OCBC0229.png') },
      { name: 'Public Bank', image: require('@/assets/images/fpx/PBB0233.png') },
      { name: 'RHB Now', image: require('@/assets/images/fpx/RHB0218.png') },
      { name: 'RHB Bank', image: require('@/assets/images/fpx/RHB0218.png') },
      { name: 'Standard Chartered', image: require('@/assets/images/fpx/SCB0216.png') },
      { name: 'UOB Bank', image: require('@/assets/images/fpx/UOB0226.png') },
      { name: 'United Overseas Bank', image: require('@/assets/images/fpx/UOB0226.png') },
      { name: 'PayPal', image: require('@/assets/images/fpx/Paypal.png') },
      { name: 'Visa / Mastercard', image: require('@/assets/images/fpx/VISA-MC.png') },
      { name: 'SBI Bank A', image: require('@/assets/images/fpx/FPX.png') },
      { name: 'SBI Bank B', image: require('@/assets/images/fpx/FPX.png') },
      { name: 'SBI Bank C', image: require('@/assets/images/fpx/FPX.png') },
      { name: 'BOCM01', image: require('@/assets/images/fpx/FPX.png') },
      { name: 'LOAD001', image: require('@/assets/images/fpx/FPX.png') },
      { name: 'GrabPay', image: require('@/assets/images/e-wallet/grabpay.png') },
      { name: 'Alipay', image: require('@/assets/images/e-wallet/alipay.png') },
    ],
    rules: {
      not_empty: [v => !!v || 'This field is required'],
      credit_card: [v => !!v || 'This field is required', v => !!v && v.length === 16 || 'card number invalid']
    }
  }),
  computed: {
    ...mapGetters({
      form: 'current/getForm',
      user: 'user/getUser',
      lead: 'current/getLead',
      order: 'current/getOrder',
      hasEmptyExtraFields: 'current/getHasEmptyExtraFields',
    })
  },
  watch: {
    'lead.shipping' () {
      this.paymentMethods = []
      this.banks = []
      this.manualBanks = []
      this.fetchLead()
    }
    // 'payment.card_date' (val) {
    //   if (val.length === 1) {
    //     if (val > 1) {
    //       this.payment.card_date = '0' + val
    //     }
    //   }
    //   if (val.length === 2) {
    //     if (val > 12) {
    //       this.payment.card_date = 12
    //     }
    //     this.payment.card_date += '/'
    //   }
    // }
  },
  mounted () {
    this.company = this.lead.form.company
    this.paymentProvider = this.company.default_payment_gateway
    this.fetchLead()
  },
  methods: {
    fetchLead () {
      this.$axios.get(`/leads/${this.lead.pid}/`)
        .then(() => {
          this.fetchPaymentMethods()
          this.fetchGateway()
          this.fetchManualTransfer()
        })
        .catch(() => {})
    },
    fetchPaymentMethods () {
      if (this.lead.allow_cod) {
        this.paymentMethods.push('cod')
      }
      if (this.lead.allow_pay_at_shop) {
        this.paymentMethods.push('pas')
      }
      if (this.paymentProvider) {
        this.paymentMethods.push('fpx')
      }
      if (this.company.currency.currency_code === 'IDR') {
        this.$axios.get(`/companies/${this.company.id}/payment_gateway_status/`)
        .then((response) => {
          console.log('aasa', response.data)
          if (response.data['xendit'] === true) {
            this.paymentMethods.push('xendit')
          }
        })
        .catch(() => {})
      }
    },
    fetchGateway() {
      if (!this.paymentProvider || this.paymentProvider == null) {
        return
      }
      this.$axios.get(`/banks/?page_size=100&gateway=${this.paymentProvider}&active=true&staging=${process.env.VUE_APP_STAGING_BANKS}&company=${this.company.id}`)
        .then((resp) => {
          resp.data.results.forEach(bank => {
            const selectedBank = this.bankImages.filter((obj) => {
              return obj.name.toLowerCase() == bank.name.toLowerCase()
            })
            bank['image'] = ''
            if (selectedBank.length != 0) {
              bank['image'] = selectedBank[0].image
            }
            if (bank.category === 'senangpay') {
              this.mcVisa = { ...bank }
              this.paymentMethods.push('mcvisa')
            } else if (bank.category === 'stripe_card') {
              this.paymentMethods.push('stripe_card')
            } else if (bank.category === 'stripe_alipay' || bank.category === 'stripe_grabpay') {
              this.paymentMethods.push('e_wallet')
              this.eWallets.push(bank)
            } else {
              this.banks.push(bank)
            }
          })
          if (this.banks.length === 0) {
            this.paymentMethods = this.paymentMethods.filter(bank => bank !== 'fpx')
          }
        }).catch(() => {})
    },
    fetchManualTransfer () {
      this.$axios.get(`/leads/${this.lead.pid}/directpay/?count=100`)
      .then((response) => {
        response.data.results.forEach(bank => {
          if (bank.enabled) {
            this.manualBanks.push(bank)
          }
        })
        if (this.manualBanks.length > 0) {
          this.paymentMethods.push('manual')
        }
      }).catch(() => {})
    },
    fetchPostcodes (val) {
      if (val.length !== 5) {
        return
      }
      this.isLoading = true
      this.$axios.get(`/shipping/postcodes?postcode=${this.payment.billing.postcode}`).then((response) => {
        const addresses = response.data
        if (addresses.length > 0 && addresses[0].postcode) {
          this.$set(this.payment.billing, 'city', addresses[0].city)
          this.$set(this.payment.billing, 'state', addresses[0].state)
          this.$set(this.payment.billing, 'country', addresses[0].country)
        }
      })
      .catch(() => {
      })
      .finally(() => {
        this.isLoading = false
      })
    },
    selectPaymentMethod (val) {
      this.payment = {
        ...this.payment,
        method: val,
        billing: this.payment.billing || { ship_address: true }
      }
    },
    checkCvv (evt) {
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    checkCardDate (evt) {
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    async savePayment () {
      if (!this.$refs.form.validate()) {
        return
      }
      if (this.payment.method === 'stripe_card') {
        await this.$refs.stripeCard.processCard()
        if (!this.stripeElement.ready) {
          return
        }
      }
      let param = {}
      if (this.payment.method === 'fpx') {
        param = {
          method: this.payment.method,
          data: {
            gateway: this.paymentProvider,
            bank_code: this.payment.bank_code,
            description: `#${this.lead.rid} ${this.lead.name} from yezza`,
            lead: this.lead.id
          },
          remarks: {
            id: 'fpx',
            display_name: this.banks.filter(f => f.code === this.payment.bank_code)[0].name
          }
        }
        this.savePreferredBank(this.payment.bank_code)
      } else if (this.payment.method === 'mcvisa') {
        param = {
          method: this.payment.method,
          data: {
            gateway: this.paymentProvider,
            bank_code: this.mcVisa.code,
            description: `#${this.lead.rid} ${this.lead.name} from yezza`,
            lead: this.lead.id
          },
          remarks: {
            id: 'mcvisa',
            display_name: this.mcVisa.name
          }
        }
      // } else if (this.payment.method === 'cc') {
      //   let billing = {}
      //   if (this.payment.billing.ship_address) {
      //     billing = this.lead.shipping_address
      //   } else {
      //     billing = this.payment.billing
      //   }
      //   param = {
      //     method: this.payment.method,
      //     data: {
      //       gateway: this.paymentProvider,
      //       bank_code: this.mcVisa.code,
      //       description: `#${this.lead.rid} ${this.lead.name} from yezza`,
      //       lead: this.lead.id,
      //       card: {
      //         card_number: this.payment.card_number,
      //         card_name: this.payment.card_name,
      //         card_date: this.payment.card_date,
      //         card_cvv: this.payment.card_cvv
      //       },
      //       billing: billing
      //     },
      //     remarks: {
      //       id: 'cc',
      //       display_name: `****${this.payment.card_number.substr(this.payment.card_number - 4)}`
      //     }
      //   }
      } else if (this.payment.method === 'stripe_card') {
        param = {
          method: this.payment.method,
          data: {
            gateway: this.paymentProvider,
            stripe_card_token: this.stripeElement.token,
            channel: 'cc',
            description: `#${this.lead.rid} ${this.lead.name} from yezza`,
            lead: this.lead.id
          },
          remarks: {
            id: 'stripe_card',
            display_name: 'Card payment'
          }
        }
      } else if (this.payment.method === 'e_wallet') {
        param = {
          method: this.payment.method,
          data: {
            gateway: this.paymentProvider,
            bank_code: this.payment.bank_code,
            description: `#${this.lead.rid} ${this.lead.name} from yezza`,
            lead: this.lead.id
          },
          remarks: {
            id: 'e_wallet',
            display_name: this.eWallets.filter(f => f.code === this.payment.bank_code)[0].name
          }
        }
      } else if (this.payment.method === 'xendit') {
        param = {
          method: this.payment.method,
          data: {
            gateway: this.paymentProvider,
            description: `#${this.lead.rid} ${this.lead.name} from yezza`,
            lead: this.lead.id
          },
          remarks: {
            id: 'xendit',
            display_name: 'Online Banking / Credit Cards/ eWallets'
          }
        }
      } else if (this.payment.method === 'cod') {
        param = {
          method: this.payment.method,
          data: { cod: true, status: 'processed' },
          remarks: {
            id: 'cod',
            display_name: 'Cash on delivery'
          }
        }
      } else if (this.payment.method === 'pas') {
        param = {
          method: this.payment.method,
          data: { pay_at_shop: true, status: 'processed' },
          remarks: {
            id: 'pas',
            display_name: 'Pay at store'
          }
        }
      } else {
        param = {
          method: this.payment.method,
          remarks: {
            id: 'manual',
            display_name: 'Manual bank transfer'
          }
        }
      }
      this.$store.commit('current/updatePayment', param)
      this.goToNextFlow(1)
    },
    savePreferredBank (bank) {
      if (!this.user) {
        return
      }
      this.$axios.patch(`/zoo/${this.user.profile}/`, { preferred_bank: bank })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
    },
    cardReady () {
      this.$watch(
        () => this.$refs.stripeCard.errorBucket,
        (val) => {
          if (val.length > 0) {
            this.stripeElement.ready = false
          } else {
            this.stripeElement.ready = true
          }
        }
      )
    },
    addCard (token) {
      this.stripeElement.token = token.id
    }
  }
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: var(--v-primary200);
}
.error--text >>> fieldset {
  border-color: var(--v-danger);
}
div >>> .v-list-item--active{
  background-color: var(--v-info400) !important;
  color:white;
}
</style>
