<template lang="pug">
  div
    .current(v-if="form.flowState === $userFlows.FILL_IN_DELIVERY_DATE_TIME")
      v-card.mt-4(v-if="!nextStepIsReady")
        DateTimeForm
        div.px-4.pb-4
          v-btn.button-AA-medium(color="info500" small height="40" block @click="updateDeliveryDateTime()" :loading="isLoading" :disabled="!isValidOrderDateTime")
            div.white--text continue to payment
      v-card.elevation-0(v-else :class="[{ 'hide-top-corner-radius': params.hideTopCorner }, { 'hide-bottom-corner-radius': params.hideBottomCorner }]")
        DateTimeForm
        div.px-4.pb-4
          v-btn.button-AA-medium(color="info500" small height="40" block @click="updateDeliveryDateTime()" :loading="isLoading" :disabled="!isValidOrderDateTime")
            div.white--text {{ $t('continue_to_payment') }}
    .other(v-else)
      .empty(v-if="!lead || !lead.asking_delivery_date")
        .first-entry(v-if="form.flowState === $userFlows.FILL_IN_TMP_PHONE_FLOW || form.flowState === $userFlows.FILL_IN_PHONE_FLOW")
          v-card.mt-4
            DateTimeForm
        v-card.mt-4(flat v-else-if="!nextStepIsReady")
          div.pa-4
            div.s1.basic600--text {{ $t('delivery_date_time') }}
      .collapse.clickable(v-else)
        v-divider.basic300
        v-card.pa-4.elevation-0(:class="[{ 'hide-top-corner-radius': params.hideTopCorner }, { 'hide-bottom-corner-radius': params.hideBottomCorner }]")
          CollapseSchedule
      
</template>

<script>
import { mapGetters } from 'vuex'
import DateTimeForm from '@/components/DateTimeForm.vue'
import CollapseSchedule from '@/components/collapse/CollapseSchedule.vue'

export default {
  name: 'DeliveryDateTime',
  components: { DateTimeForm, CollapseSchedule },
  props: {
    params: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapGetters({
      form: 'current/getForm',
      omsData: 'current/getOms',
      orderDateTime: 'current/getDateTime',
      lead: 'current/getLead',
      order: 'current/getOrder',
    }),
    nextStepIsReady () {
      if (this.order.payment) {
        return true
      }
      return false
    },
    isValidOrderDateTime () {
      if (this.orderDateTime.is_ASAP !== undefined) {
        if (this.orderDateTime.is_ASAP === false && !this.orderDateTime.asking_delivery_date) {
          return false
        }
      }
      return true
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    updateDeliveryDateTime() {
      this.isLoading = true
      this.axios.patch(`/leads/${this.lead.pid}/`, this.orderDateTime)
        .then(() => {
          this.updateCurrentLead()
          if (this.form.flowState === this.$userFlows.FILL_IN_DELIVERY_DATE_TIME) {
            this.goToNextFlow(1)
          }
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style scoped>
</style>