<template lang="pug">
  div
    template
      .current-flow.pa-0(v-if="form.flowState === $userFlows.FILL_IN_DELIVERY_DATE_TIME")
        v-card-text
          div.h6.primary--text {{ shippingMethod === 0 ? $t('delivery') : $t('pickup') }} {{ $t('date_and_time') }}
          v-row.my-2(v-if="showAsap" no-gutters)
            v-col.pa-1
              div.px-3.py-2.clickable(outlined :style="selectedType === 'asap' ? `border:1px solid ${$vuetify.theme.themes[$theme].info500}; background:${$vuetify.theme.themes[$theme].infoT08};` : `border:1px solid ${$vuetify.theme.themes[$theme].textColorHint}; background:${$vuetify.theme.themes[$theme].basic600T08};`" @click="selectedType = 'asap'" style="border-radius:4px")
                div.button-AA-medium.font-weight-bold(:class="selectedType === 'asap' ? 'info500--text' : 'textColorHint--text'")
                  div.d-flex
                    v-icon.mr-3(color="black" size="18" :color="selectedType === 'asap' ? $vuetify.theme.themes[$theme].info500 : $vuetify.theme.themes[$theme].textColorHint" style="margin-top:1px; margin-bottom:2px") $icon-timer
                    span(style="margin-top:2px") ASAP
            v-col.pa-1(v-if="omsData.scheduling_enabled")
              div.px-3.py-2.clickable(outlined :style="selectedType === 'schedule' ? `border:1px solid ${$vuetify.theme.themes[$theme].info500}; background:${$vuetify.theme.themes[$theme].infoT08};` : `border:1px solid ${$vuetify.theme.themes[$theme].textColorHint}; background:${$vuetify.theme.themes[$theme].basic600T08};`" @click="selectedType = 'schedule'" style="border-radius:4px")
                div.button-AA-medium.font-weight-bold(:class="selectedType === 'schedule' ? 'info500--text' : 'textColorHint--text'")
                  div.d-flex
                    eva-icon.mr-3(name="calendar-outline" width="18" height="18" :fill="selectedType === 'schedule' ? $vuetify.theme.themes[$theme].info500 : $vuetify.theme.themes[$theme].textColorHint")
                    span.text-uppercase(style="margin-top:2px") {{ $t('schedule') }}
            
          template(v-if="selectedType === 'asap' && omsData.preparation_time_enabled")
            div.pa-2 {{ $t('estimated_preparation_time') }}: {{ omsData.preparation_time_value }} {{ omsData.preparation_time_unit }}
            
          template(v-if="selectedType === 'schedule'")
            v-card.px-2.py-1.mt-4.mx-2(v-if="!selectedDateTime" elevation="0" :color="$vuetify.theme.themes[$theme].dangerT16")
              div.c1.danger700--text {{ 'Please select date and time' }}
            y-schedule-picker(v-model="dateTime" :bgActive="$vuetify.theme.themes[$theme].infoT08" width="600px" :params="params")
            div.px-4.pt-2.text-center {{ selectedDateTime || '&nbsp;' }} 

      .first-entry(v-else)
        v-card-title.pa-0.main-container.pt-2(v-if="!useSameHours")
          v-tabs(v-model="shippingMethod" fixed-tabs color="textColorAccent" height="40" slider-size="4")
            v-tab.button-AA-medium {{ $t('delivery') }}
            v-tab.button-AA-medium {{ $t('pick_up') }}
          div.separator(:style="{ 'background-color':$vuetify.theme.themes[$theme].basic600T16 }")
        
        v-card-text.pa-4

          template(v-if="omsData.scheduling_enabled")
            .both(v-if="showAsap")
              div.h6.primary--text {{ $t('select_date_and_time_for') }} {{ useSameHours ? 'delivery/pickup' : shippingMethod === 0 ? $t('delivery') : $t('pick_up') }}
              v-row.my-2(no-gutters)
                v-col.pa-1
                  div.px-3.py-2.clickable(outlined :style="selectedType === 'asap' ? `border:1px solid ${$vuetify.theme.themes[$theme].info500}; background:${$vuetify.theme.themes[$theme].infoT08};` : `border:1px solid ${$vuetify.theme.themes[$theme].textColorHint}; background:${$vuetify.theme.themes[$theme].basic600T08};`" @click="selectedType = 'asap'" style="border-radius:4px")
                    div.button-AA-medium.font-weight-bold(:class="selectedType === 'asap' ? 'info500--text' : 'textColorHint--text'")
                      div.d-flex
                        v-icon.mr-3(color="black" size="18" :color="selectedType === 'asap' ? $vuetify.theme.themes[$theme].info500 : $vuetify.theme.themes[$theme].textColorHint" style="margin-top:1px; margin-bottom:2px") $icon-timer
                        span(style="margin-top:2px") {{ $t('asap') }}
                v-col.pa-1
                  div.px-3.py-2.clickable(outlined :style="selectedType === 'schedule' ? `border:1px solid ${$vuetify.theme.themes[$theme].info500}; background:${$vuetify.theme.themes[$theme].infoT08};` : `border:1px solid ${$vuetify.theme.themes[$theme].textColorHint}; background:${$vuetify.theme.themes[$theme].basic600T08};`" @click="selectedType = 'schedule'" style="border-radius:4px")
                    div.button-AA-medium.font-weight-bold(:class="selectedType === 'schedule' ? 'info500--text' : 'textColorHint--text'")
                      div.d-flex
                        eva-icon.mr-3(name="calendar-outline" width="18" height="18" :fill="selectedType === 'schedule' ? $vuetify.theme.themes[$theme].info500 : $vuetify.theme.themes[$theme].textColorHint")
                        span(style="margin-top:2px") {{ $t('schedule') }}
            
            .schedule(v-else)
              div.h6.primary--text {{ $t('schedule_for_later') }}
              .c1.mt-2(v-if="isOpenToday && !isOpenNow") {{ $t('were_closed_schedule_for_later') }}
              .c1.mt-2(v-if="!isOpenToday") {{ $t('were_closed_will_reopen_on', { day: nextBusinessDay }) }}

            template(v-if="selectedType === 'asap' && omsData.preparation_time_enabled")
              div.pa-2 {{ $t('estimated_preparation_time') }}: {{ omsData.preparation_time_value }} {{ omsData.preparation_time_unit }}
              
            template(v-if="selectedType === 'schedule'")
              template(v-if="omsData.scheduling_begin_time_unit === 'days' && omsData.scheduling_begin_time_value === 0")
                template(v-if="isOpenToday")
                  v-card.px-2.py-1.mt-4.mx-2(v-if="!selectedDateTime" elevation="0" :color="$vuetify.theme.themes[$theme].dangerT16")
                    div.c1.danger700--text {{ 'Please select date and time' }}
                  y-schedule-picker(v-model="dateTime" :bgActive="$vuetify.theme.themes[$theme].infoT08" width="600px" :params="params")
                  div.px-4.pt-2.text-center {{ selectedDateTime || '&nbsp;' }}
              template(v-else)
                v-card.px-2.py-1.mt-4.mx-2(v-if="!selectedDateTime" elevation="0" :color="$vuetify.theme.themes[$theme].dangerT16")
                  div.c1.danger700--text {{ $t('please_select_date_and_time') }}
                y-schedule-picker(v-model="dateTime" :bgActive="$vuetify.theme.themes[$theme].infoT08" width="600px" :params="params")
                div.px-4.pt-2.text-center {{ selectedDateTime || '&nbsp;' }}

          template(v-else)
            .c1 {{ $t('were_closed_for_now') }}

      //- .closed(v-else)
        v-card-text.pa-4
          template
            div(v-if="!showAsap")
              div(v-if="omsData.preparation_time_enabled")
                .c1 {{ $t('please_expect_approx') }}. {{ omsData.preparation_time_value }} {{ omsData.preparation_time_unit }} {{ $t('preparation_time') }}
              div(v-else)
                .c1 {{ $t('were_closed_for_now') }}.{{ nextBusinessDay? ` ${$t('come_back_on')} ${nextBusinessDay}.` : '' }}
      
</template>

<script>
import { DateTime } from 'luxon'
import { mapGetters } from 'vuex'

export default {
  name: 'DateTimeForm',
  components: { },
  data: () => ({
    dateTime: null,
    shippingMethod: 0,
    nextBusinessDay: null,
  }),
  computed: {
    ...mapGetters({
      lead: 'current/getLead',
      form: 'current/getForm',
      omsData: 'current/getOms',
      orderDateTime: 'current/getDateTime',
    }),
    selectedType: {
      get () {
        if (this.orderDateTime.is_ASAP === false) {
          return 'schedule'
        }
        return 'asap'
      },
      set (newValue) {
        const data = {...this.orderDateTime, is_ASAP: newValue === 'asap'}
        if (!this.showAsap) {
          delete data.asking_delivery_date
          delete data.asking_delivery_time
        }
        this.$store.commit('current/updateDatetime', data)
      }
    },
    useSameHours () {
      if (this.omsData.business_hour && this.omsData.business_hour.length > 0 && JSON.stringify(this.omsData.business_hour[0].days) === JSON.stringify(this.omsData.business_hour[1].days)) {
        return true
      }
      return false
    },
    isOpenToday () {
      let hasToday = false
      let hours = []
      if (this.shippingMethod === 0) {
        hours = this.omsData.business_hour.filter(f => f.type_name === 'delivery')
      } else {
        hours = this.omsData.business_hour.filter(f => f.type_name === 'pickup')[0]
      }
      hours.forEach((o) => {
        if (o.days.filter(f => f.day === 'Today').length > 0) {
          hasToday = true
        }
      })
      return hasToday
    },
    isOpenNow () {
      let openNow = false
      const timeNow = DateTime.local().toFormat('y-LL-dd') + 'T' + DateTime.local().toFormat('HH:mm:ss') + DateTime.local().toFormat('ZZ')
      const dayToday = new Date().toString().substr(0, 3)
      let hours = []
      if (this.shippingMethod === 0) {
        hours = this.omsData.business_hour.filter(f => f.type_name === 'delivery')
      } else {
        hours = this.omsData.business_hour.filter(f => f.type_name === 'pickup')[0]
      }
      hours.forEach((o) => {
        const today = o.days.find(f => f.day.substr(0, 3) === dayToday)
        if (today) {
          today.hours.forEach((o) => {
            const begin = DateTime.local().toFormat('y-LL-dd') + 'T' + o.begin + DateTime.local().toFormat('ZZ')
            const end = DateTime.local().toFormat('y-LL-dd') + 'T' + o.end + DateTime.local().toFormat('ZZ')
            if (Date.parse(timeNow) >= Date.parse(begin) && Date.parse(timeNow) < Date.parse(end)) {
              openNow = true
            }
          })
        }
      })
      return openNow
    },
    selectedBusinessHour () {
      if (this.useSameHours) {
        return this.omsData.business_hour[0].days
      } else if (this.shippingMethod === 0) {
        return this.omsData.business_hour.filter(f => f.type_name === 'delivery')[0].days
      } else {
        return this.omsData.business_hour.filter(f => f.type_name === 'pickup')[0].days
      }
    },
    showAsap () {
      if (this.useSameHours) {
        return this.omsData.business_hour[0].can_ASAP
      } else if (this.shippingMethod === 0) {
        return this.omsData.business_hour.filter(f => f.type_name === 'delivery')[0].can_ASAP
      } else {
        return this.omsData.business_hour.filter(f => f.type_name === 'pickup')[0].can_ASAP
      }
    },
    params () {
      return {
        timeInterval: 15,
        daysCount: this.getSchedulingDays() || 0,
        businessHour: this.selectedBusinessHour,
        offset: this.getOffsetDays(),
        daysOff: this.omsData.restricted_dates
      }
    },
    selectedDateTime () {
      if (this.selectedType === 'asap') {
        return 'As soon as possible'
      } else if (this.dateTime) {
        const [date, time] = this.dateTime.split(' ')
        return this.convertDate(date) + ', ' + this.covert24To12(time)
      }
      return null
    }
  },
  watch: {
    dateTime (val) {
      const data = {...this.orderDateTime} || {}
      data.asking_delivery_date = null
      data.asking_delivery_date = null
      if (val) {
        const [date, time] = this.dateTime.split(' ')
        data.asking_delivery_date = date
        data.asking_delivery_time = time
      }
      this.$store.commit('current/updateDatetime', data)
    },
    shippingMethod () {
      const data = {...this.orderDateTime} || {}
      data.asking_order_type = this.shippingMethod === 0 ? 'delivery' : 'pickup'
      this.$store.commit('current/updateDatetime', data)
      if (!this.showAsap) {
        this.selectedType = 'schedule'
      }
    },
    'lead.pickup' (val) {
      if (val) {
        this.shippingMethod = 1
      } else {
        this.shippingMethod = 0
      }
    }
  },
  mounted () {
    this.getNextBusinessDay()
    if (this.lead && this.lead.pickup) {
      this.shippingMethod = 1
    }
    if (!this.showAsap) {
      this.selectedType = 'schedule'
    }
  },
  methods: {
    getNextBusinessDay () {
      for (let index = 1; index <= 7; index++) {
        const date = new Date(new Date().getTime() + (index * 24 * 60 * 60 * 1000))
        if (this.omsData.business_hour[0] && this.omsData.business_hour[0].days.filter(f => f.day.substr(0, 3) === date.toString().substr(0, 3)).length > 0) {
          this.nextBusinessDay = date.toString().substr(4, 6) + ', ' + this.covert24To12(this.omsData.business_hour[0].days.filter(f => f.day.substr(0, 3) === date.toString().substr(0, 3))[0].hours[0].begin)
          break
        } else if (this.omsData.business_hour[1] && this.omsData.business_hour[1].days.filter(f => f.day.substr(0, 3) === date.toString().substr(0, 3)).length > 0) {
          this.nextBusinessDay = date.toString().substr(4, 6) + ', ' + this.covert24To12(this.omsData.business_hour[1].days.filter(f => f.day.substr(0, 3) === date.toString().substr(0, 3))[0].hours[0].begin)
          break
        }
      }
    },
    getSchedulingDays () {
      const value = this.omsData.scheduling_begin_time_value
      const unit = this.omsData.scheduling_begin_time_unit
      if (unit === 'months') {
        return Number(value) * 30
      } else if (unit === 'weeks') {
        return Number(value) * 7
      } else {
        return Number(value)
      }
    },
    getOffsetDays () {
      if (this.omsData.preparation_time_enabled) {
        if (this.omsData.preparation_time_unit === 'days') {
          return Number(this.omsData.preparation_time_value || 0)
        }
        return 0
      }
    },
    covert24To12 (time) { // format 08:00:00
      // Check correct time format and split into components
      time = time.split(':')[0] + ':' + time.split(':')[1]
      time = time.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time]
      if (time.length > 1) { // If time format correct
        time = time.slice(1) // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM' // Set AM/PM
        time[0] = +time[0] % 12 || 12 // Adjust hours
      }
      return time.join('') // return adjusted time or original string
    },
    convertDate (val) {
      const today = new Date(new Date().getTime() + (0 * 24 * 60 * 60 * 1000))
      const tomorrow = new Date(new Date().getTime() + (1 * 24 * 60 * 60 * 1000))
      const todaydate = today.getFullYear() + '-' + this.addLeadingZero((today.getMonth() + 1), 2) + '-' + this.addLeadingZero(today.getDate(), 2)
      const tomorrowdate = tomorrow.getFullYear() + '-' + this.addLeadingZero((tomorrow.getMonth() + 1), 2) + '-' + this.addLeadingZero(tomorrow.getDate(), 2)
      if (val === todaydate) {
        return 'Today'
      }
      if (val === tomorrowdate) {
        return 'Tomorrow'
      }
      if (val === '') {
        return '-'
      }
      if (!val) {
        return 'Close'
      }
      const d = new Date(val)
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      const newDate = days[d.getDay()] + ', ' + this.addLeadingZero(d.getDate(), 2) + ' ' + months[d.getMonth()]
      return newDate
    },
    addLeadingZero (str, count) {
      str = String(str)
      const len = str.length
      if (len < count) {
        for (let index = 0; index < count - len; index++) {
          str = '0' + str
        }
        return str
      }
      return str
    }
  }
}
</script>

<style scoped>
</style>