<template lang="pug">
  div
    v-form(ref="form")
      v-text-field.mt-2(v-model="shipping_address.name" label="Recipient Name" filled rounded hide-details="auto" :rules="rules.not_empty")
      v-text-field.mt-3(v-model="shipping_address.phone" label="Recipient Phone" filled rounded hide-details="auto" :rules="rules.not_empty")
      template(v-if="zonelist.length > 1")
        div.label.basic600--text.text-uppercase.mt-5 {{ $t('preferred_pick_up_area') }}
        v-select.mt-2(v-model="selectedZone" :items="zonelist" item-text="name" return-object :placeholder="$t('select_area')" background-color="basic200" outlined dense hide-details="auto")
          template(v-slot:append)
            eva-icon(name="chevron-down-outline" width="24" height="24" :fill="$vuetify.theme.themes[$theme].basic700")
      div.mt-6.text-center(align="center" v-if="selectedZone")
        div
          eva-icon(name="pin-outline" :fill="$vuetify.theme.themes[$theme].primary" height="35" width="35")
        linkify-text.s1.primary--text(:text="selectedZone.pickup_address")
        template(v-if="selectedCourier && selectedCourier.meta_data && selectedCourier.meta_data.type === 'store_pickup'")
          linkify-text.p2.primary--text.mt-3(:text="selectedCourier.meta_data.instruction")
        .c1.basic600--text(v-if="datetimeData.description") {{ datetimeData.description }}
        //- v-btn.button-Aa-small.mt-4(x-small text color="primary400") View location
      div.mt-8(v-else)
        div.c1.basic600--text.text-center {{ $t('your_pick_up_location_will') }}
      v-btn.button-AA-medium.mt-6(color="info500" small height="40" block @click="saveShippingFee" :loading="isLoading" :dark="Boolean(selectedCourier) && datetimeData.available" :disabled="!selectedCourier || !datetimeData.available")
        div(:class="{ 'white--text': selectedCourier }") {{ $t('continue_to_payment') }}
</template>

<script>
import { mapGetters } from 'vuex'
import LinkifyText from '@/components/custom/LinkifyText.vue'

export default {
  name: 'PickupForm',
  components: { LinkifyText },
  data: () => ({
    isLoading: false,
    shipping_address: {},
    zonelist: [],
    selectedZone: null,
    selectedCourier: null,
    rules: {
      not_empty: [v => !!v || 'This field is required']
    }
  }),
  computed: {
    ...mapGetters({
      zones: 'current/getZones',
      lead: 'current/getLead',
      datetimeData: 'current/getPickupDatetimeData',
    })
  },
  watch: {
    selectedZone (val) {
      if (val != null) {
        this.fetchCouriers()
      }
    }
  },
  mounted () {
    if (this.lead.shipping_address) {
      this.shipping_address = { ...this.lead.shipping_address }
      if (!this.shipping_address.phone) {
        this.$set(this.shipping_address, 'phone', this.lead.phone)
      }
      if (!this.shipping_address.name) {
        this.$set(this.shipping_address, 'name', this.lead.name)
      }
    } else {
      this.$set(this.shipping_address, 'phone', this.lead.phone)
      this.$set(this.shipping_address, 'name', this.lead.name)
    }
    this.zonelist = [...this.zones.filter(f => f.shipping_methods.includes('pickup') && f.pickup_address)]
    if (this.zonelist.length === 1) {
      this.selectedZone = { ...this.zonelist[0] }
    }
  },
  methods: {
    fetchCouriers () {
      this.isFetching = true
      this.$axios.get(`/shipping/fees/${this.lead.pid}/?zone=${this.selectedZone.id}&pickup=true`)
        .then((response) => {
          this.selectedCourier = { ...response.data[0] }
        })
        .catch(e => {
          if (e.response && e.response.data && e.response.data.message) {
            this.errorMessage = e.response.data.message
          } else {
            this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
          }
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    saveShippingFee () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.isLoading = true
      let params = {
        fee: this.selectedCourier.fee,
        lead: this.lead.id,
        shipping_method: this.selectedCourier.shipping_method,
        meta_data: this.selectedCourier.meta_data,
        signature: this.selectedCourier.signature,
        zone: this.selectedZone.id
      }

      if (this.lead.shipping) {
        this.axios.patch(`/shipping/shippings/${this.lead.shipping.id}/`, params)
        .then(() => {
          this.$store.commit('current/updateRemarksShipping', this.selectedCourier)
          this.saveAddress()
          this.goToNextFlow(1)
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading = false
        })
      } else {
        this.axios.post(`/shipping/shippings/`, params)
        .then(() => {
          this.$store.commit('current/updateRemarksShipping', this.selectedCourier)
          this.saveAddress()
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading = false
        })
      }
    },
    saveAddress () {
      let param = { shipping_address: { name: this.shipping_address.name, phone: this.shipping_address.phone } }
      this.axios.patch(`/leads/${this.lead.pid}/`, param)
        .then(() => {
          this.updateCurrentLead()
          this.goToNextFlow(1)
          this.goToNextFlow(1)
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })    
    }
  }
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: var(--v-primary200);
}
::v-deep .v-text-field--filled > .v-input__control > .v-input__slot{
  background: #F7F7F7 !important;
  border: 1px solid #eaeaea !important;
  border-radius: 4px;
  max-height: 48px;
  min-height: 48px;
  padding-left: 12px;
}
::v-deep .v-text-field.v-input--has-state > .v-input__control > .v-input__slot{
  border: 1px solid var(--v-danger) !important;
}
::v-deep .v-text-field--filled:not(.v-text-field--single-line) input{
  margin-top: 14px;
}
::v-deep .v-text-field--filled .v-label{
  top: 14px !important;
}
::v-deep .v-text-field--filled .v-label--active{
  transform: translateY(-10px) scale(0.75);
}
::v-deep .v-input--has-state.error--text{
  color: red;
}
</style>