<template lang="pug">
  div
    v-dialog(v-model="showModal" scrollable transition="fade-transition" persistent max-width="600")
      v-card
        v-card-title.pa-4 
          div.h6.primary--text {{ $t('select_shipping_method') }}
          v-spacer
          v-btn(v-if="source !== 'pickup'" small icon @click="showModal = false")
            v-icon(color="primary") mdi-close
        v-card-text.pa-0
          div.pa-4
            div.label.basic600--text.text-uppercase {{ $t('select_your_delivery_area') }}
            v-select.mt-2(v-model="selectedZone" :items="zonesAvailable" item-text="name" :placeholder="$t('select_area')" background-color="basic200" return-object outlined dense hide-details="auto")
              template(v-slot:append)
                eva-icon(name="chevron-down-outline" width="24" height="24" :fill="$vuetify.theme.themes[$theme].basic700")
            .guide.mt-2.d-flex
              eva-icon.mr-2(name="info-outline" :fill="$vuetify.theme.themes[$theme].basic600" height="15" width="15" style="margin-top: -2px")
              div.c1.basic600--text {{ $t('do_consult_with', { text: lead.form.company.name }) }}
          v-layout.my-6(v-if="isFetching")
            v-row.fill-height.ma-0(align='center' justify='center')
              v-progress-circular.ma-auto(:width="2" :size="50" color="primary" indeterminate)
          v-card(flat v-else)
            template(v-if="selectedZone && couriers.length === 0")
              div.p2.px-4.text-center.danger500--text {{ $t('no_couriers_available') }}
            template(v-for="(courier, index) in couriers")
              v-divider.basic300
              v-row.pa-4(no-gutters :key="JSON.stringify(courier)" @click="(!courier.disabled && courier.available) ? selectedCourier = courier : ''")
                v-col.shrink.pr-2
                  eva-icon(:name="JSON.stringify(selectedCourier) === JSON.stringify(courier) ? 'radio-button-on' : 'radio-button-off'" :fill="JSON.stringify(selectedCourier) === JSON.stringify(courier) ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].basic600" height="20" width="20" )
                v-col
                  div.d-flex
                    div(style="word-break: break-word")
                      div.s1(:style="{'color' : courier.disabled ? $vuetify.theme.themes[$theme].danger500 : courier.available ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].textColorDisabled }") {{ courier.title }}
                      div.c1.basic600--text(v-if="courier.shipping_method == 'easyparcel' && courier.meta_data && courier.meta_data.delivery") {{ courier.meta_data.delivery }}
                      div.c1.basic600--text(v-if="courier.shipping_method == 'zepto' && courier.meta_data && courier.meta_data.distance") {{ courier.meta_data.distance }} km
                      div.c1.basic600--text(v-if="courier.meta_data && courier.meta_data.currency === 'IDR' && courier.meta_data.delivery_time") {{ courier.meta_data.delivery_time }} {{ $t('working_days') }}
                      div.c1(v-if="courier.fulfilment_type == 'local_delivery'" :style="{'color' : courier.available ? $vuetify.theme.themes[$theme].basic600 : $vuetify.theme.themes[$theme].textColorDisabled }") {{ courier.datetime_info }}
                    v-spacer
                    div(v-if="!courier.disabled" align="right")
                      div.p2.mr-1(v-if="!courier.disabled" style="margin-top: 2px;" :style="{'color' : courier.available ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].textColorDisabled }") {{ courier.fee | displayPrice($currency) }}
                      v-chip.c2.px-2(v-if="courier.meta_data && courier.meta_data.cod" x-small outlined color="basic600" :style="`background-color: ${$vuetify.theme.themes[$theme].basic600T08} !important`") {{ $t('cod_available') }}
        v-card-actions.pa-4
          v-btn.button-AA-medium(color="success500" small height="40" block @click="saveShippingFee" :loading="isLoading" :dark="Boolean(selectedCourier)" :disabled="!selectedCourier")
            div(:class="{ 'white--text': selectedCourier }") {{ source === 'pickup' ? $t('confirm_shipping_method') : $t('save_shipping_method') }}
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'EditDelivery',
  props: ['open', 'source'],
  data: () => ({
    isFetching: false,
    isLoading: false,
    selectedZone: null,
    selectedCourier: null,
    couriers: []
  }),
  computed: {
    ...mapGetters({
      remarks: 'current/getOrderRemarks',
      zones: 'current/getZones',
      lead: 'current/getLead',
      datetimeData: 'current/getDeliveryDatetimeData',
    }),
    showModal: {
      get () {
        return this.open
      },
      set (newValue) {
        this.$emit('update:open', newValue)
      }
    },
    zonesAvailable () {
      let tempZones = []
      if (this.lead.shipping_address && this.lead.shipping_address.city) {
        if (this.zones.length > 0) {
          this.zones.forEach(o => {
            if (o.shipping_methods.some(s => s !== 'pickup')) {
              if (!o.cities) {
                tempZones.push(o)
              } else if (o.cities.includes(this.lead.shipping_address.city)) {
                tempZones.push(o)
              }
            }
          })
        }
      }
      return tempZones    
    }
  },
  watch: {
    selectedZone: {
      immediate: true,
      handler (val) {
        if (val) {
          this.selectedCourier = null
          this.fetchCouriers()
        } else {
          this.couriers = []
          this.selectedCourier = null
        }
      }
    }
  },
  mounted() {
    this.selectedZone = this.zonesAvailable.filter(f => f.id === this.lead.shipping.zone)[0]
  },
  methods: {
    fetchCouriers () {
      this.isFetching = true
      this.$axios.get(`/shipping/fees/${this.lead.pid}/?zone=${this.selectedZone.id}&pickup=false`)
        .then((response) => {
          this.couriers = []
          const respCouriers = [...response.data]
          respCouriers.forEach(o => {
            const courier = {
              ...o,
              available: o.fulfilment_type === 'local_delivery' ? this.datetimeData.available : true,
              datetime_info:  o.fulfilment_type === 'local_delivery' ? this.datetimeData.description : ''
            }
            this.couriers.push(courier)
          })
          if (this.lead.shipping.zone === this.selectedZone.id) {
            if (this.source !== 'pickup') {
              for (let i = 0; i <= this.couriers.length; i++) {
                if (this.lead.shipping.shipping_method === this.couriers[i].shipping_method) {
                  if (this.lead.shipping.shipping_method === 'manual') {
                    if (this.couriers[i].meta_data && this.couriers[i].meta_data.id === this.lead.shipping.manual_shipping) {
                      this.selectedCourier = { ...this.couriers[i] }
                      break
                    }
                  } else if (this.lead.shipping.shipping_method === 'lalamove') {
                    this.selectedCourier = { ...this.couriers[i] }
                    break
                  } else if (this.lead.shipping.shipping_method === 'zepto') {
                    this.selectedCourier = { ...this.couriers[i] }
                    break
                  } else if (this.lead.shipping.shipping_method === 'easyparcel' || this.lead.shipping.shipping_method === 'ys_courier' || this.lead.shipping.shipping_method === 'ys_localdelivery') {
                    if (this.couriers[i].title == this.remarks.shipping_method.title && this.couriers[i].fee === this.remarks.shipping_method.fee) {
                      this.selectedCourier = { ...this.couriers[i] }
                      break
                    }
                  }
                }   
              }
            }
          }
        })
        .catch(e => {
          if (e.response && e.response.data && e.response.data.message) {
            this.errorMessage = e.response.data.message
          } else {
            this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
          }
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    saveShippingFee () {
      if (!this.selectedCourier) {
        this.showMessage(['error', 'Please choose a courier'])
        return
      }

      this.isLoading = true
      let params = {
        fee: this.selectedCourier.fee,
        lead: this.lead.id,
        shipping_method: this.selectedCourier.shipping_method,
        meta_data: this.selectedCourier.meta_data,
        signature: this.selectedCourier.signature,
        zone: this.selectedZone.id
      }

      this.axios.patch(`/shipping/shippings/${this.lead.shipping.id}/`, params)
        .then(() => {
          this.updateCurrentLead()
          this.$store.commit('current/updateRemarksShipping', this.selectedCourier)
          this.showModal = false
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  }
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: var(--v-primary200);
}
.error--text >>> fieldset {
  border-color: var(--v-danger);
}

</style>