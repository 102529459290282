<template lang="pug">
  div
    v-row(no-gutters @click="$store.commit('current/updateEditingSection', $userFlows.FILL_IN_SHIPPING_METHOD_FLOW)")
      v-col(cols="4")
        div.p2.basic600--text(style="width: 100px") {{ $t('delivery') }}
      v-col(cols="7")
        transition(name="title")
          div(v-if="!(editingSection === $userFlows.FILL_IN_SHIPPING_METHOD_FLOW)")
            template(v-if="selectedCourier")
              div.p2.primary--text {{ selectedCourier.title }} {{ selectedCourier.fee | displayPrice($currency) }}
            template(v-else)
              v-progress-circular(:width="2" :size="20" color="grey" indeterminate)
      v-col(cols="1" align="end")
        eva-icon(:name="editingSection === $userFlows.FILL_IN_SHIPPING_METHOD_FLOW ? 'chevron-up' : 'chevron-down'" :fill="$vuetify.theme.themes[$theme].info400" height="20" width="20" )
    transition(name="content")
      div(v-if="editingSection === $userFlows.FILL_IN_SHIPPING_METHOD_FLOW")
        v-row.mt-4(no-gutters)
          v-col.align-self-center
            div.c2.primary--text(v-if="selectedZone") {{ selectedZone.name }}
          v-col.shrink
            v-btn.button-Aa-tiny.px-0(x-small text color="transparent" @click="openEditDeliveryDialog = true")
              div.info500--text {{ $t('change_area') }}
        div.danger500--text.c1(v-if="errorMessage") {{ errorMessage }}
        v-card(flat)
          v-layout.my-6(v-if="isFetching")
            v-row.fill-height.ma-0(align='center' justify='center')
              v-progress-circular.ma-auto(:width="2" :size="50" color="primary" indeterminate)
          template(v-else v-for="(courier, index) in couriers")
            v-row.mt-3(no-gutters :key="courier.title + index" @click="(!courier.disabled && courier.available) ? saveCourier(courier) : ''")
              v-col.shrink.pr-2
                eva-icon(:name="JSON.stringify(selectedCourier) === JSON.stringify(courier) ? 'radio-button-on' : 'radio-button-off'" :fill="JSON.stringify(selectedCourier) === JSON.stringify(courier) ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].basic600" height="20" width="20" )
              v-col
                div.d-flex
                  div(style="word-break: break-word")
                    div.p2(:style="{'color' : courier.disabled ? $vuetify.theme.themes[$theme].danger500 : courier.available ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].textColorDisabled }") {{ courier.title }}
                    div.c1.basic600--text(v-if="courier.shipping_method == 'easyparcel' && courier.meta_data && courier.meta_data.delivery") {{ courier.meta_data.delivery }}
                    div.c1.basic600--text(v-if="courier.meta_data && courier.meta_data.currency === 'IDR' && courier.meta_data.delivery_time") {{ courier.meta_data.delivery_time }} {{ $t('working_days') }}
                    div.c1(v-if="courier.fulfilment_type == 'local_delivery'" :style="{'color' : courier.available ? $vuetify.theme.themes[$theme].basic600 : $vuetify.theme.themes[$theme].textColorDisabled }") {{ courier.datetime_info }}
                  v-spacer
                  div(v-if="!courier.disabled" align="right")
                    div.p2.mr-1(:style="{'color' : courier.available ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].textColorDisabled }") {{ courier.fee | displayPrice($currency) }}
                    v-chip.c2.px-2(v-if="courier.meta_data && courier.meta_data.cod" x-small outlined color="basic600" :style="`background-color: ${$vuetify.theme.themes[$theme].basic600T08} !important`") {{ $t('cod_available') }}
        div.mt-4(v-if="zones.filter(f => f.shipping_methods.includes('pickup') && f.pickup_address).length > 0")
          v-btn.button-Aa-small.px-0(color="transparent" text @click="openEditPickupDialog = true" small)
            eva-icon.mr-1(name="swap" :fill="$vuetify.theme.themes[$theme].info500" height="15" width="15" style="margin-top: 2px")
            div.info500--text {{ $t('switch_to_pick_up') }}
    .modal
      edit-delivery(v-if="openEditDeliveryDialog" :open.sync="openEditDeliveryDialog" @refresh="refreshData" :source="'delivery'")
      edit-pickup(v-if="openEditPickupDialog" :open.sync="openEditPickupDialog" @refresh="refreshData" :source="'delivery'")
</template>

<script>
import { mapGetters } from 'vuex'
import EditDelivery from '@/components/dialogs/EditDelivery.vue'
import EditPickup from '@/components/dialogs/EditPickup.vue'

export default {
  name: 'CollapseDelivery',
  components: { EditDelivery, EditPickup },
  props: ['active'],
  data: () => ({
    isFetching: false,
    openEditDeliveryDialog: false,
    openEditPickupDialog: false,
    selectedCourier: null,
    couriers: [],
    errorMessage: null
  }),
  computed: {
    ...mapGetters({
      remarks: 'current/getOrderRemarks',
      zones: 'current/getZones',
      lead: 'current/getLead',
      editingSection: 'current/getEditingSection',
      datetimeData: 'current/getDeliveryDatetimeData',
    }),
    selectedZone () {
      return this.zones.filter(f => f.id === this.lead.shipping.zone)[0]
    }
  },
  watch: {
    'selectedZone.name' () {
      this.refreshData()
    },
  },
  mounted () {
    this.fetchCouriers()
  },
  methods: {
    fetchCouriers () {
      this.errorMessage = null
      this.isFetching = true
      this.$axios.get(`/shipping/fees/${this.lead.pid}/?zone=${this.lead.shipping.zone}&pickup=false`)
        .then((response) => {
          this.couriers = []
          const respCouriers = [...response.data]
          respCouriers.forEach(o => {
            const courier = {
              ...o,
              available: o.fulfilment_type === 'local_delivery' ? this.datetimeData.available : true,
              datetime_info:  o.fulfilment_type === 'local_delivery' ? this.datetimeData.description : ''
            }
            this.couriers.push(courier)
          })
          for (let i = 0; i <= this.couriers.length; i++) {
            if (this.lead.shipping.shipping_method === this.couriers[i].shipping_method) {
              if (this.lead.shipping.shipping_method === 'manual') {
                if (this.couriers[i].meta_data && this.couriers[i].meta_data.id === this.lead.shipping.manual_shipping) {
                  this.selectedCourier = { ...this.couriers[i] }
                  break
                }
              } else if (this.lead.shipping.shipping_method === 'lalamove') {
                this.selectedCourier = { ...this.couriers[i] }
                break
              } else if (this.lead.shipping.shipping_method === 'zepto') {
                this.selectedCourier = { ...this.couriers[i] }
                break
              } else if (this.lead.shipping.shipping_method === 'easyparcel' || this.lead.shipping.shipping_method === 'ys_courier' || this.lead.shipping.shipping_method === 'ys_localdelivery') {
                if (this.couriers[i].title == this.remarks.shipping_method.title && this.couriers[i].fee === this.remarks.shipping_method.fee) {
                  this.selectedCourier = { ...this.couriers[i] }
                  break
                }
              }
            }   
          }
        })
        .catch(e => {
          if (e.response && e.response.data && e.response.data.message) {
            this.errorMessage = e.response.data.message
          } else {
            this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
         }
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    saveCourier (courier) {
      let tempCourier = this.selectedCourier
      this.selectedCourier = courier
      let params = {
        fee: courier.fee,
        lead: this.lead.id,
        shipping_method: courier.shipping_method,
        meta_data: courier.meta_data,
        signature: courier.signature,
        zone: this.lead.shipping.zone
      }
      this.axios.patch(`/shipping/shippings/${this.lead.shipping.id}/`, params)
        .then(() => {
          this.updateCurrentLead()
          this.$store.commit('current/updateRemarksShipping', this.selectedCourier)
        })
        .catch(() => {
          this.selectedCourier = tempCourier
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    refreshData () {
      this.fetchCouriers()
      this.openEditDeliveryDialog = false
      this.openEditPickupDialog = false
    }
  }
}
</script>

<style scoped>
.title-enter-active {
  transition: opacity .3s;
}
.title-leave-active {
  transform: scale(0,0) .3s;
}
.title-enter, .title-leave-to {
  opacity: 0;
}
.content-enter-active {
  transition: opacity .3s;
}
.content-leave-active {
  transform: scale(0,0) .3s;
}
.content-enter, .content-leave-to {
  opacity: 0;
}
</style>