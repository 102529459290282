<template lang="pug">
  .navigation-layout
    v-layout.my-6(v-if="isFetching")
      v-row.fill-height.ma-0(align='center' justify='center')
        v-progress-circular.ma-auto(:width="2" :size="50" color="primary" indeterminate)
    .content(v-else-if="lead")
      .main(v-if="isMainPage")
        order-confirmation(v-if="lead.paid || showOrderPage")
        .checkout(v-else)
          div.basic100.py-1(dense flat)
            v-container(style="max-width: 600px")
              .h6.primary500--text {{ $t('your_cart') }}
          flow-controller
      router-view(v-else)
    v-layout.my-6(v-else)
      v-row.fill-height.ma-0(align='center' justify='center')
        div.mx-auto
          v-img.mx-auto(:src="require('@/assets/images/icon_empty.png')" style="width:80px; height:80px" contain)
          p.caption {{ $t('the_url_no_longer_valid') }}
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import FlowController from '@/views/FlowController.vue'
import OrderConfirmation from '@/views/OrderConfirmation.vue'

export default {
  name: 'Navigation',
  components: { FlowController, OrderConfirmation },
  props: ['pid'],
  data: () => ({
    isFetching: false,
  }),
  computed: {
     ...mapGetters({
      lead: 'current/getLead',
      currency: 'currency/getCurrency'
    }),
    showOrderPage () {
      if (this.$route.path.split('/')[1] === 'orders') {
        return true
      }
      if (this.lead) {
        if (this.lead.cod || this.lead.pay_at_shop) {
          return true
        }
      }
      if (this.$store.state.current.order.price.paidAmount) {
        return true
      }
      return false
    },
    isMainPage () {
      const slashes = this.$route.path.toString().split('/')
      if (slashes.length > 3 && slashes[3] != '') {
        return false
      }
      return true
    }
  },
  mounted () {
    this.$store.commit('cart/setTmpCheckout', false)
    this.fetchLead()
  },
  methods: {
    fetchLead() {
      this.isFetching = true
      this.$axios.get(`/leads/${this.pid}/`)
        .then((resp) => {
          this.$store.commit('current/updateLead', resp.data)
          this.$store.commit('currency/updateCurrency', resp.data.form.company.currency)
          if (resp.data.form.type !== 'store') {
            let language = 'en'
            if (resp.data.form.language === 'id') {
              language = 'id'
            }
            this.$store.commit('language/setLanguage', language)
          }
          Vue.prototype.$currency = this.currency
        })
        .catch(() => {})
        .finally(() => {
          this.$axios.get(`/companies/${this.lead.form.company.id}/genes/`)
            .then((resp) => {
              const contracts = ['YZ_FREE', 'YZ_FREE_V2', 'YZ_PERSONAL', 'YZ_BIZ', 'YZ_INTEL', 'YBOOKS_NORMAL', 'YZ_START', 'YZ_GROWTH', 'YZ_LANDING_PAGE']
              const genes = resp.data.map(gene => gene.feature)
              var included = contracts.some(contract => genes.includes(contract))
              if (!included) {
                this.$store.commit('current/updateLead', null)
              }
            })
            .catch(() => {})
          this.isFetching = false
        })
    }
  }
}
</script>
