<template lang="pug">
  div
    v-dialog(v-model="showModal" scrollable transition="fade-transition" persistent max-width="600")
      v-card
        v-card-title.pa-4 
          div.h6.primary--text {{ $t('select_shipping_address') }}
          v-spacer
          v-btn(small icon @click="showModal = false")
            v-icon(color="primary") mdi-close
        v-card-text.px-4.py-0
          template(v-for="(address, index) in addresses")
            v-row.mt-4(no-gutters :key="address.name + index")
              v-col(@click="!disableSelectAddress(address.city) ? selectedAddress = address : ''")
                v-row(no-gutters)
                  v-col.shrink.pr-2
                    template(v-if="selectedAddress")
                      eva-icon(:name="JSON.stringify({ address_1: address.address_1, address_2: address.address_2, postcode: address.postcode }) === JSON.stringify({ address_1: selectedAddress.address_1, address_2: selectedAddress.address_2, postcode: selectedAddress.postcode }) ? 'radio-button-on' : 'radio-button-off'" :fill="JSON.stringify({ address_1: address.address_1, address_2: address.address_2, postcode: address.postcode }) === JSON.stringify({ address_1: selectedAddress.address_1, address_2: selectedAddress.address_2, postcode: selectedAddress.postcode }) ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].basic600" height="20" width="20" )
                    template(v-else)
                      eva-icon(name="radio-button-off" :fill="$vuetify.theme.themes[$theme].basic600" height="20" width="20")
                  v-col
                    div.p2(:class="disableSelectAddress(address.city) ? 'danger500--text' : 'primary--text'") {{ address.name }} | {{ address.phone }}
                    div.p2(:class="disableSelectAddress(address.city) ? 'danger500--text' : 'primary--text'") {{ address.address_1 }}, {{ address.address_2 }}
                    template(v-if="address.area")
                      div.p2.basic600--text {{ address.postcode }}, {{ address.area }}
                      div.p2.basic600--text {{ address.suburb }}, {{ address.city }}
                    template(v-else)
                      div.p2(:class="disableSelectAddress(address.city) ? 'danger400--text' : 'basic600--text'") {{ address.postcode }}, {{ address.city }}
                    div.p2(:class="disableSelectAddress(address.city) ? 'danger400--text' : 'basic600--text'") {{ address.state }}, {{ address.country }}
              v-col.shrink.pl-2(align="end")
                v-menu(left)
                  template(v-slot:activator='{ on }')
                    v-btn(icon small color="transparent" v-on='on')
                      eva-icon(name="more-vertical" :fill="$vuetify.theme.themes[$theme].primary500" height="20" width="20")
                  v-list(dense)
                    v-list-item(@click="clickEditAddress(address)")
                      v-list-item-content
                        v-list-item-title.s2.basic800--text {{ $t('edit') }}
                    v-list-item(v-if="addresses.length > 1 && address.id !== 'main'" @click="clickDeleteAddress(address, index)")
                      v-list-item-content
                        v-list-item-title.s2.basic800--text {{ $t('delete') }}
          div.mt-4(v-if="addresses.length === 0 || zooData")
            v-btn.button-Aa-small.px-0(color="transparent" text small @click="openAddAddressDialog = true")
              v-icon.mr-1(color="info500" size="17") mdi-plus
              div.info500--text {{ $t('add_new_address') }}
        v-card-actions.pa-4
          v-btn.button-AA-medium.white--text(color="success500" small height="40" block @click="saveAddress" :loading="isLoading" :dark="Boolean(selectedAddress)" :disabled="!selectedAddress")
            div(:class="{ 'white--text': selectedAddress }") {{ $t('continue_shipping_method') }}
    .modal
      add-shipping-address-id(v-if="$currency.code === 'IDR' && openAddAddressDialog" :open.sync="openAddAddressDialog" :parentAddress="editAddress" :source="'select'" @edit="handleEditItem($event)" @add="handleAddItem($event)" @close="handleCloseDialog")
      add-shipping-address(v-else-if="openAddAddressDialog" :open.sync="openAddAddressDialog" :parentAddress="editAddress" :source="'select'" @edit="handleEditItem($event)" @add="handleAddItem($event)" @close="handleCloseDialog")
</template>

<script>
import { mapGetters } from 'vuex'
import AddShippingAddress from '@/components/dialogs/AddShippingAddress.vue'
import AddShippingAddressId from '@/components/dialogs/AddShippingAddressId.vue'

export default {
  name: 'SelectShippingAddress',
  components: { AddShippingAddress, AddShippingAddressId },
  props:  ['open'],
  data: () => ({
    isLoading: false,
    openAddAddressDialog: false,
    recipient: null,
    addresses: [],
    selectedAddress: null,
    editAddress: null
  }),
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      zooData: 'current/getZooData',
      restrictedCities: 'current/getRestrictedCities',
      lead: 'current/getLead'
    }),
    showModal: {
      get () {
        return this.open
      },
      set (newValue) {
        this.$emit('update:open', newValue)
      }
    }
  },
  mounted () {
    if (this.zooData && this.zooData.addresses && this.zooData.addresses.length > 0) {
      this.addresses = [...this.zooData.addresses]
    } else if (this.lead.shipping_address && this.lead.shipping_address.address_1) {
      this.addresses = [{ ...this.lead.shipping_address }]
    }
  },
  methods: {
    saveAddress () {
      this.isLoading = true
      this.axios.patch(`/leads/${this.lead.pid}/`, { shipping_address: this.selectedAddress })
        .then(() => {
          this.updateCurrentLead()
          this.$emit('shipping')
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    disableSelectAddress (city) {
      if (this.restrictedCities) {
        if (this.restrictedCities.includes(city)) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    clickEditAddress (addr) {
      this.editAddress = addr
      this.openAddAddressDialog = true
    },
    clickDeleteAddress (addr, idx) {
      this.$axios.delete(`/zoo/${this.user.profile}/addresses/${addr.id}`)
        .then(() => {
          this.addresses.splice(idx, 1)
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading2 = false
        })
    },
    handleAddItem (evt) {
      this.addresses.push(evt)
      if (!this.selectedAddress) {
        this.selectedAddress = { ...evt }
      }
      this.openAddAddressDialog = false
    },
    handleEditItem (evt) {
      this.addresses[this.addresses.indexOf(this.editAddress)] = evt
      this.selectedAddress = evt
      this.editAddress = null
      this.openAddAddressDialog = false
    },
    handleCloseDialog () {
      this.editAddress = null
      this.openAddAddressDialog = false
    }
  }
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: var(--v-primary200);
}
.error--text >>> fieldset {
  border-color: var(--v-danger);
}

</style>