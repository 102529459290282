<template lang="pug">
  div
    v-form(ref="form")
      div.h6.primary--text Recipient Information
      v-text-field.mt-4(v-model="shipping_address.name" label="Name" filled rounded hide-details="auto" :rules="rules.not_empty")
      v-text-field.mt-3(v-model="shipping_address.phone" label="Phone" filled rounded hide-details="auto" :rules="rules.not_empty")
      v-select.mt-3(v-model="address_data.state" label="Province" :items="states" append-icon="" item-text="name" item-value="id" filled rounded hide-details="auto" :rules="rules.not_empty" :loading="isLoading" @change="fetchCities(address_data.state)")
      v-select.mt-3(v-model="address_data.city" label="City" :items="cities" append-icon="" item-text="name" item-value="id" filled rounded hide-details="auto" :rules="rules.not_empty" :loading="isLoading" @change="fetchSuburbs(address_data.city)")
      v-select.mt-3(v-model="address_data.suburb" label="Suburb" :items="suburbs" append-icon="" item-text="name" item-value="id" filled rounded hide-details="auto" :rules="rules.not_empty" :loading="isLoading" @change="fetchAreas(address_data.suburb)")
      v-select.mt-3(v-model="address_data.area" label="Area" :items="areas" append-icon="" item-text="area" filled rounded hide-details="auto" :rules="rules.not_empty" return-object :loading="isLoading" @change="setShippingAddress(address_data.area)")
      v-text-field.mt-3(v-model="shipping_address.address_1" label="Address 1" filled rounded hide-details="auto" :rules="rules.not_empty")
      v-text-field.mt-3(v-model="shipping_address.address_2" label="Address 2 (optional)" filled rounded hide-details="auto" )
      v-text-field.mt-3(v-model="shipping_address.postcode" label="Postcode" filled rounded hide-details="auto" :rules="rules.not_empty" :loading="isLoading")
      v-text-field.mt-3(v-model="shipping_address.country" label="Country" filled rounded hide-details="auto" :rules="rules.not_empty" :loading="isLoading")
      v-btn.button-AA-medium.mt-4(color="info500" dark small height="40" block @click="saveAddress()" :loading="isLoading2") continue to shipping method
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DeliveryFormId',
  data: () => ({
    isLoading: false,
    isLoading2: false,
    tab: null,
    shipping_address: {},
    address_data: {},
    states: [],
    cities: [],
    suburbs: [],
    areas: [],
    rules: {
      not_empty: [v => !!v || 'This field is required']
    }
  }),
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      zooData: 'current/getZooData',
      zones: 'current/getZones',
      restrictedCities: 'current/getRestrictedCities',
      lead: 'current/getLead'
    })
  },
  mounted () {
    if (this.lead.shipping_address) {
      this.shipping_address = { ...this.lead.shipping_address }
      if (!this.shipping_address.phone) {
        this.$set(this.shipping_address, 'phone', this.lead.phone)
      }
      if (!this.shipping_address.name) {
        this.$set(this.shipping_address, 'name', this.lead.name)
      }
    } else {
      this.$set(this.shipping_address, 'phone', this.lead.phone)
      this.$set(this.shipping_address, 'name', this.lead.name)
    }
    this.fetchStates()
  },
  methods: {
    fetchStates () {
      this.isLoading = true
      this.$axios.get('/addresses_idn/states')
        .then(resp => {
          this.states = resp.data
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    fetchCities (id) {
      this.isLoading = true
      this.address_data = { state: id }
      this.cities = []
      this.suburbs = []
      this.areas = []
      this.clearAddress()
      this.$axios.get(`/addresses_idn/cities?state_id=${id}`)
        .then(resp => {
          this.cities = resp.data
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    fetchSuburbs (id) {
      this.isLoading = true
      delete this.address_data.suburb
      delete this.address_data.area
      this.suburbs = []
      this.areas = []
      this.clearAddress()
      this.$axios.get(`/addresses_idn/suburbs?city_id=${id}`)
        .then(resp => {
          this.suburbs = resp.data
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    fetchAreas (id) {
      this.isLoading = true
      delete this.address_data.area
      this.areas = []
      this.clearAddress()
      this.$axios.get(`/addresses_idn/areas?suburb_id=${id}`)
        .then(resp => {
          this.areas = resp.data
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    setShippingAddress (data) {
      data.name = this.shipping_address.name
      data.phone = this.shipping_address.phone
      this.shipping_address = data
    },
    clearAddress () {
      let data = {
        name: this.shipping_address.name,
        phone: this.shipping_address.phone
      }
      this.shipping_address = data
    },
    saveAddress () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.isLoading2 = true
      let param = { shipping_address: this.shipping_address }
      if (this.lead.phone.endsWith(this.shipping_address.phone) || this.lead.name === 'Guest') {
        param.name = this.shipping_address.name
      }
      this.axios.patch(`/leads/${this.lead.pid}/`, param)
        .then(() => {
          this.updateCurrentLead()
          // this.goToNextFlow(1)
          if (this.user && this.zooData && this.zooData.fast_checkout_consent) {
            this.saveAddressToZoo(this.shipping_address)
          } else {
            this.goToNextFlow(1)
          }
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          if (!this.user) {
            this.isLoading2 = false
          }
        })
    },
    saveAddressToZoo (addr) {
      let stringfy = JSON.stringify({ address_1: addr.address_1, address_2: addr.address_2, postcode: addr.postcode })
      let address = []
      if (this.zooData && this.zooData.addresses && this.zooData.addresses.length > 0) {
        address = [...this.zooData.addresses]
      }
      if (address.filter(f => JSON.stringify({ address_1: f.address_1, address_2: f.address_2, postcode: f.postcode }) === stringfy).length > 0) {
        this.goToNextFlow(1)
        return
      }
      this.axios.post(`/zoo/${this.user.profile}/addresses`, addr)
        .then(() => {
          this.goToNextFlow(1)
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading2 = false
        })
    }
  }
}
</script>

<style scoped>
::v-deep .v-text-field--filled > .v-input__control > .v-input__slot{
  background: #F7F7F7 !important;
  border: 1px solid #eaeaea !important;
  border-radius: 4px;
  max-height: 48px;
  min-height: 48px;
  padding-left: 12px;
}
::v-deep .v-text-field.v-input--has-state > .v-input__control > .v-input__slot{
  border: 1px solid var(--v-danger) !important;
}
::v-deep .v-text-field--filled:not(.v-text-field--single-line) input{
  margin-top: 14px;
}
::v-deep .v-text-field--filled .v-label{
  top: 14px !important;
}
::v-deep .v-text-field--filled .v-label--active{
  transform: translateY(-10px) scale(0.75);
}
::v-deep .v-input--has-state.error--text{
  color: red;
}
</style>