<template lang="pug">
  v-row.mt-4(no-gutters justify="center")
    v-col(v-for="n in 6" align="center" :key="n")
      div(style="max-width: 40px" :key="n")
        v-text-field.centered-input.button-AA-medium(v-model="codeInput[n]" :id="'id'+n" placeholder="-" background-color="basic200"  type="number" maxlength="1" outlined dense hide-details @keyup="input(n, $event)" @focus="clearInput(n)")
</template>

<script>
export default {
  name: 'CodeInput',
  data: () => ({
    codeInput: [],
  }),
  watch: {
    codeInput(val) {
      const parseValue = val.filter(f => f !== null )
      if (parseValue.length === 6) {
        this.$emit('finished', parseValue.join(''))
      }
    }
  },
  methods: {
    input (val, evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        if (val < 6) {
          let idInput = 'id' + (val + 1)
          var el = document.getElementById(idInput)
          el.focus()
          el.value=''
        } else {
          let idInput = 'id' + val
          var el2 = document.getElementById(idInput)
          el2.blur()
        }
      }
    },
    clearInput (val) {
      this.$set(this.codeInput, val, null)
    }
  }
  
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: var(--v-primary200);
}
.centered-input >>> input {
  text-align: center;
}
.v-input--is-focused >>> fieldset {
  border-color: var(--v-info500);
  background-color: var(--v-infoT08);
}
.centered-input >>> input::-webkit-outer-spin-button,
.centered-input >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.centered-input >>> input[type=number] {
  -moz-appearance: textfield;
}
</style>