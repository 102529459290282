<template lang="pug">
  div
    v-row(no-gutters @click="$store.commit('current/updateEditingSection', $userFlows.FILL_IN_RECEIPIENT_FLOW)")
      v-col(cols="4")
        div.p2.basic600--text(style="width: 100px") {{ $t('recipient') }}
      v-col(cols="7")
        transition(name="title")
          div(v-if="!(editingSection === $userFlows.FILL_IN_RECEIPIENT_FLOW)")
            div.p2.primary--text {{ lead.shipping_address.name }}
            div.p2.primary--text {{ lead.shipping_address.address_1 }}, {{ lead.shipping_address.address_2 }}
      v-col(cols="1" align="end")
        eva-icon(:name="(editingSection === $userFlows.FILL_IN_RECEIPIENT_FLOW) ? 'chevron-up' : 'chevron-down'" :fill="$vuetify.theme.themes[$theme].info400" height="20" width="20" )

    transition(name="content")
      div(v-if="editingSection === $userFlows.FILL_IN_RECEIPIENT_FLOW")
        template(v-for="(address, index) in addresses")
          v-row.mt-4(no-gutters :key="address.name + index")
            v-col(@click="saveAddress(address)")
              v-row(no-gutters)
                v-col.shrink.pr-2
                  eva-icon(:name="JSON.stringify({ address_1: address.address_1, address_2: address.address_2, postcode: address.postcode }) === JSON.stringify({ address_1: selectedAddress.address_1, address_2: selectedAddress.address_2, postcode: selectedAddress.postcode }) ? 'radio-button-on' : 'radio-button-off'" :fill="JSON.stringify({ address_1: address.address_1, address_2: address.address_2, postcode: address.postcode }) === JSON.stringify({ address_1: selectedAddress.address_1, address_2: selectedAddress.address_2, postcode: selectedAddress.postcode }) ? $vuetify.theme.themes[$theme].primary500 : $vuetify.theme.themes[$theme].basic600" height="20" width="20" )
                v-col
                  div.p2.primary--text {{ address.name }} | {{ address.phone }}
                  div.p2.primary--text {{ address.address_1 }}, {{ address.address_2 }}
                  template(v-if="address.area")
                    div.p2.basic600--text {{ address.postcode }}, {{ address.area }}
                    div.p2.basic600--text {{ address.suburb }}, {{ address.city }}
                  template(v-else)
                    div.p2.basic600--text {{ address.postcode }}, {{ address.city }}
                  div.p2.basic600--text {{ address.state }}, {{ address.country }}
            v-col.shrink.pl-2(align="end")
              v-menu(left)
                template(v-slot:activator='{ on }')
                  v-btn(icon small color="transparent" v-on='on')
                    eva-icon(name="more-vertical" :fill="$vuetify.theme.themes[$theme].primary500" height="20" width="20")
                v-list(dense)
                  v-list-item(@click="clickEditAddress(address)")
                    v-list-item-content
                      v-list-item-title.s2.basic800--text {{ $t('edit') }}
                  v-list-item(v-if="addresses.length > 1 && address.id !== 'main'" @click="clickDeleteAddress(address, index)")
                    v-list-item-content
                      v-list-item-title.s2.basic800--text {{ $t('delete') }}
        div.mt-4(v-if="zooData")
          v-btn.button-Aa-small.px-0(color="transparent" text small @click="openAddAddressDialog = true")
            v-icon.mr-1(color="info500" size="17") mdi-plus
            div.info500--text {{ $t('add_new_address') }}
    .modal
      add-shipping-address-id(v-if="$currency.code === 'IDR' && openAddAddressDialog" :open.sync="openAddAddressDialog" :parentAddress="editAddress" :source="'address'" @edit="handleEditItem($event)" @add="handleAddItem($event)" @close="handleCloseDialog")
      add-shipping-address(v-else-if="openAddAddressDialog" :open.sync="openAddAddressDialog" :parentAddress="editAddress" :source="'address'" @edit="handleEditItem($event)" @add="handleAddItem($event)" @close="handleCloseDialog")

</template>

<script>
import { mapGetters } from 'vuex'
import AddShippingAddress from '@/components/dialogs/AddShippingAddress.vue'
import AddShippingAddressId from '@/components/dialogs/AddShippingAddressId.vue'

export default {
  name: 'CollapseRecipient',
  components: { AddShippingAddress, AddShippingAddressId },
  props: ['active'],
  data: () => ({
    openAddAddressDialog: false,
    recipient: null,
    addresses: [],
    selectedAddress: null,
    editAddress: null,
    checkingAddr: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      zooData: 'current/getZooData',
      lead: 'current/getLead',
      editingSection: 'current/getEditingSection',
    })
  },
  watch: {
    checkingAddr (val) {
      if (val) {
        this.checkAddress(this.selectedAddress)
      }
    }
  },
  mounted () {
    if (this.zooData && this.zooData.addresses && this.zooData.addresses.length > 0) {
      this.addresses = [...this.zooData.addresses]
    } else if (this.lead.shipping_address && this.lead.shipping_address.address_1) {
      this.addresses = [{ ...this.lead.shipping_address }]
    }
    this.selectedAddress = { ...this.lead.shipping_address }
  },
  methods: {
    saveAddress (addr) {
      let tempAddr = this.selectedAddress
      this.selectedAddress = addr
      this.axios.patch(`/leads/${this.lead.pid}/`, { shipping_address: addr })
        .then(() => {
          this.updateCurrentLead()
        })
        .catch(() => {
          this.selectedAddress = tempAddr
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
    },
    saveAddressToZoo (addr) {
      let stringfy = JSON.stringify({ address_1: addr.address_1, address_2: addr.address_2, postcode: addr.postcode })
      if (this.addresses.filter(f => JSON.stringify({ address_1: f.address_1, address_2: f.address_2, postcode: f.postcode }) === stringfy).length === 0) {
        if (this.user && this.zooData && this.zooData.fast_checkout_consent) {
          this.axios.post(`/zoo/${this.user.profile}/addresses`, addr).catch(() => {})
        }
      }
    },
    clickEditAddress (addr) {
      this.editAddress = addr
      this.openAddAddressDialog = true
    },
    clickDeleteAddress (addr, idx) {
      this.$axios.delete(`/zoo/${this.user.profile}/addresses/${addr.id}`)
        .then(() => {
          this.addresses.splice(idx, 1)
          this.$store.commit('current/updateZooDataAddresses', this.addresses)
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading2 = false
        })
    },
    handleAddItem (evt) {
      this.addresses.push(evt)
      this.openAddAddressDialog = false
    },
    handleEditItem (evt) {
      this.saveAddressToZoo(evt)
      this.addresses[this.addresses.indexOf(this.editAddress)] = evt
      this.selectedAddress = evt
      this.editAddress = null
      this.openAddAddressDialog = false
    },
    handleCloseDialog () {
      this.editAddress = null
      this.openAddAddressDialog = false
    }
  }
}
</script>

<style scoped>
.title-enter-active {
  transition: opacity .3s;
}
.title-leave-active {
  transform: scale(0,0) .3s;
}
.title-enter, .title-leave-to {
  opacity: 0;
}
.content-enter-active {
  transition: opacity .3s;
}
.content-leave-active {
  transform: scale(0,0) .3s;
}
.content-enter, .content-leave-to {
  opacity: 0;
}
</style>