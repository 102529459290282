<template lang="pug">
  div
    .current(v-if="form.flowState === $userFlows.FILL_IN_RECEIPIENT_FLOW")
      v-card.pt-1.mt-4
        template(v-if="deliveryMethod === 'both'")
          v-card-title.pa-0.main-container
            v-tabs(v-model="tab" fixed-tabs color="textColorAccent" height="40" slider-size="4")
              v-tab.button-AA-medium {{ $t('delivery') }}
              v-tab.button-AA-medium {{ $t('pick_up') }}
            div.separator(:style="{ 'background-color':$vuetify.theme.themes[$theme].basic600T16 }")
          v-card-text.pa-4
            v-tabs-items(v-model="tab")
              v-tab-item
                template(v-if="tab === 0")
                  delivery-form-id(v-if="$currency.code === 'IDR'" @next="goToNextFlow(1)")
                  delivery-form(v-else @next="goToNextFlow(1)")
              v-tab-item
                pickup-form(v-if="tab === 1" @next="goToNextFlow(1)")
        template(v-if="deliveryMethod === 'delivery'")
          div.pa-4
            delivery-form-id(v-if="$currency.code === 'IDR'" @next="goToNextFlow(1)")
            delivery-form(v-else @next="goToNextFlow(1)")
        template(v-if="deliveryMethod === 'pickup'")
          div.pa-4
            div.h6.primary--text.mb-6 {{ $t('self_pickup') }}
            pickup-form(@next="goToNextFlow(1)")
        template(v-if="deliveryMethod === 'error'")
          div.pa-4
            div.c1.danger500--text.text-center {{ $t('unexpected_error_occur') }}
    .other(v-else)
      .empty(v-if="!lead.shipping_address")
        v-card.mt-4(flat)
          div.pa-4
            div.s1.basic600--text {{ $t('recipient_info') }}
      .collapse.clickable(v-else)
        v-divider.basic300
        v-card.pa-4.elevation-0(:class="[{ 'hide-top-corner-radius': params.hideTopCorner }, { 'hide-bottom-corner-radius': params.hideBottomCorner }]")    
          collapse-recipient(v-if="lead.shipping_address && lead.shipping_address.address_1")

</template>

<script>
import { mapGetters } from 'vuex'
import DeliveryForm from '@/components/DeliveryForm.vue'
import DeliveryFormId from '@/components/DeliveryFormId.vue'
import PickupForm from '@/components/PickupForm.vue'
import CollapseRecipient from '@/components/collapse/CollapseRecipient.vue'

export default {
  name: 'RecipientForm',
  components: { DeliveryForm, DeliveryFormId, PickupForm, CollapseRecipient },
  props: {
    params: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    tab: null,
  }),
  computed: {
    ...mapGetters({
      form: 'current/getForm',
      zones: 'current/getZones',
      lead: 'current/getLead',
      zooData: 'current/getZooData',
    }),
    deliveryMethod () {
      if (this.zones.filter(f => f.shipping_methods.some(s => s !== 'pickup')).length > 0 && this.zones.filter(f => f.shipping_methods.includes('pickup') && f.pickup_address).length > 0) {
        return 'both'
      } else if (this.zones.filter(f => f.shipping_methods.some(s => s !== 'pickup')).length > 0) {
        return 'delivery'
      } else if (this.zones.filter(f => f.shipping_methods.includes('pickup') && f.pickup_address).length > 0) {
        return 'pickup'
      } else {
        return 'error'
      }
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: var(--v-primary200);
}
.error--text >>> fieldset {
  border-color: var(--v-danger);
}
.main-container {
  position: relative;
}
.separator {
  position: absolute;
  height:4px;
  top: 36px;
  left: 0px;
  right: 0px;
}
</style>