<template lang="pug">
  div
    .current(v-if="form.flowState === $userFlows.FILL_IN_EXTRA_FIELDS_FLOW")
      v-card.mt-4
        div.px-4.pt-4
          div.h6.primary--text {{ $t('additional_info') }}
        div.mt-2(v-if="storeSetting")
          v-form(ref="form")
            div.px-4(v-if="lead.form.email_enabled")
              div.label.basic600--text.text-uppercase.mt-4 {{ $t('email.label') }}
              v-text-field.mt-2(v-model="leadEmail" :placeholder="$t('email.label')" background-color="basic200" outlined dense hide-details="auto" :rules="(lead.form.email_required || leadEmail) ? rules.valid_email : []")
            div.px-4(v-if="field.enabled" v-for="(field, index) in extraFieldsForm")
              .text(v-if="field.type == 'text'")
                div.label.basic600--text.text-uppercase.mt-4 {{ field.name }}
                v-text-field.mt-2(v-model="extraFields[index]" :placeholder="field.name" background-color="basic200" outlined dense hide-details="auto" :rules="field.required ? rules.not_empty : []")
              .dropdown(v-if="field.type == 'dropdown'")
                div.label.basic600--text.text-uppercase.mt-4 {{ field.name }}
                v-select.mt-2(v-model="extraFields[index]" :placeholder="field.name" background-color="basic200" outlined dense hide-details="auto" :rules="field.required ? rules.not_empty : []" :items="field.choices")
              .checklist.mb-7(v-if="field.type == 'checklist'")
                div.label.basic600--text.text-uppercase.mt-4 {{ field.name }}
                v-card.mt-2.px-4.py-1(outlined)
                  template(v-for="(choice, i) in field.choices")
                    y-checkbox.pa-0.mx-0.my-3(v-model="extraFields[index][i]" :label="choice" :key="choice" hide-details :rules="field.required ? checkboxRules(extraFields[index]) : []")
              .date(v-if="field.type == 'date'")
                v-dialog(v-model='extraFields[index].show' width='290px')
                  template(v-slot:activator='{ on }')
                    div.label.basic600--text.text-uppercase.mt-4 {{ field.name }}
                    v-text-field.mt-2(:value="extraFields[index].value" :placeholder="$t('date.placeholder')" background-color="basic200" outlined dense hide-details="auto" :rules="field.required ? rules.not_empty : []" readonly v-on='on')
                  v-date-picker.pt-6(v-model='extraFields[index].date' color="textColorAccent" @change="saveDate(index)" no-title)
              .date(v-if="field.type == 'delivery_date'")
                v-dialog(v-model='extraFields[index].show' width='290px')
                  template(v-slot:activator='{ on }')
                    div.label.basic600--text.text-uppercase.mt-4 {{ field.name }}
                    v-text-field.mt-2(:value="extraFields[index].value" :placeholder="$t('date.placeholder')" background-color="basic200" outlined dense hide-details="auto" :rules="field.required ? rules.not_empty : []" readonly v-on='on')
                  v-date-picker.pt-6(v-model='extraFields[index].date' color="textColorAccent" @change="saveDate(index)" no-title :min="getMinDayBefore(field.min_day_before)" :allowed-dates="field.no_delivery_outside_business_date ? allowedDates : allowedAllDates")
              .date(v-if="field.type == 'time'")
                v-dialog(v-model='extraFields[index].show' width='290px')
                  template(v-slot:activator='{ on }')
                    div.label.basic600--text.text-uppercase.mt-4 {{ field.name }}
                    v-text-field.mt-2(:value="extraFields[index].value" :placeholder="$t('time.placeholder')" background-color="basic200" outlined dense hide-details="auto" :rules="field.required ? rules.not_empty : []" readonly v-on='on')
                  v-time-picker(v-model='extraFields[index].time' color="textColorAccent")
                    v-btn.button-AA-medium.mt-3(color="textColorAccent" :dark="Boolean(extraFields[index].time)" :disabled="!extraFields[index].time" small height="40" block @click="saveTime(index)")
                      div(:class="{ 'white--text': extraFields[index].time }") set
              .date(v-if="field.type == 'delivery_time'")
                v-dialog(v-model='extraFields[index].show' width='290px')
                  template(v-slot:activator='{ on }')
                    div.label.basic600--text.text-uppercase.mt-4 {{ field.name }}
                    v-text-field.mt-2(:value="extraFields[index].value" :placeholder="$t('time.placeholder')" background-color="basic200" outlined dense hide-details="auto" :rules="field.required ? rules.not_empty : []" readonly v-on='on')
                  v-time-picker(v-model='extraFields[index].time' color="textColorAccent" :min="field.start_time" :max="field.end_time")
                    v-btn.button-AA-medium.mt-3(color="textColorAccent" :dark="Boolean(extraFields[index].time)" :disabled="!extraFields[index].time" small height="40" block @click="saveTime(index)")
                      div(:class="{ 'white--text': extraFields[index].time }") set
              .long_text(v-if="field.type == 'long_text'")
                div.label.basic600--text.text-uppercase.mt-4 {{ field.name }}
                v-textarea.mt-2(v-model="extraFields[index]" :placeholder="field.hint" background-color="basic200" outlined dense hide-details="auto" spellcheck="false" :rules="field.required ? rules.not_empty : []")
        div.pa-4
          v-btn.button-AA-medium.mt-4.white--text(color="info500" dark small height="40" block @click="saveExtraFields" :loading="isLoading") {{ $t('continue_to_review') }}

    .other(v-else)
      .empty(v-if="hasEmptyExtraFields")
        v-card.mt-4(flat)
          div.pa-4
            div.s1.basic600--text {{ $t('additional_info') }}
      .collapse.clickable(v-else)
        v-divider.basic300
        v-card.pa-4.elevation-0(:class="[{ 'hide-top-corner-radius': params.hideTopCorner }, { 'hide-bottom-corner-radius': params.hideBottomCorner }]")    
          collapse-extrafield
</template>

<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import CollapseExtrafield from '@/components/collapse/CollapseExtrafield.vue'

export default {
  name: 'ExtraFields',
  components: { CollapseExtrafield },
  props: {
    params: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    isLoading: false,
    storeSetting: null,
    leadEmail: null,
    extraFields: [],
    extraFieldsForm: [],
    rules: {
      not_empty: [val => (val || '').length > 0 || 'This field is required'],
      phone: [
        v => !!v || 'Phone Number is required',
        v => /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/.test(v) || 'Phone Number must be valid'
      ],
      valid_email: [v => !!v || 'Email is required', v => /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Invalid email address']
    }
  }),
  computed: {
    ...mapGetters({
      form: 'current/getForm',
      user: 'user/getUser',
      lead: 'current/getLead',
      hasEmptyExtraFields: 'current/getHasEmptyExtraFields',
    }),
    isBussinessHourEnabled () {
      if (this.settings && this.settings.business_hours && this.settings.business_hours.enabled) {
        return true
      }
      return false
    }
  },
  mounted () {
    this.$i18n.locale = this.lead.form.language || 'ms'
    this.fetchStoreSettings()
    this.getExtraFields()
    this.extraFieldsForm = [...this.lead.form.extra_fields]
  },
  methods: {
    fetchStoreSettings () {
      this.$axios.get(`/store/settings/${this.lead.form.company.slug}`)
        .then(resp => {
          this.storeSetting = { ...resp.data }
        })
    },
    getExtraFields () {
      if (this.lead.form.extra_fields) {
        this.extraFields = []
        for (let i = 0; i < this.lead.form.extra_fields.length; i++) {
          const field = this.lead.form.extra_fields[i]
          if (field.type === 'checklist') {
            const selecteds = []
            field.choices.forEach(() => {
              selecteds.push(false)
            })
            this.extraFields.push(selecteds)
          } else if (field.type === 'date' || field.type === 'time' || field.type === 'delivery_time' || field.type === 'delivery_date') {
            const data = {
              show: false,
              date: null,
              value: ''
            }
            this.extraFields.push(data)
          } else {
            this.extraFields.push('')
          }
        }
      }
    },
    saveExtraFields () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.isLoading = true
      let data = {}
      if (this.extraFieldsForm.length > 0) {
        let param = [...this.extraFieldsForm]
        this.extraFields.forEach((val, index) => {
          const field = this.lead.form.extra_fields[index]
          if (field.type === 'checklist') {
            let selectedString = ''
            let array_string = []
            val.forEach((bool, i) => {
              if (bool) {
                if (selectedString.length > 0) {
                  selectedString = selectedString.concat(', ')
                }
                selectedString = selectedString.concat(field.choices[i])
                array_string.push(field.choices[i])
              }
            })
            param[index].array_value = array_string
            param[index].value = selectedString
          } else if (field.type === 'date' || field.type === 'time' || field.type === 'delivery_time' || field.type === 'delivery_date') {
            param[index].value = val.value
          } else {
            param[index].value = val
          }
        })
        data.extra_fields_data = param
      }
      if (this.leadEmail) {
        data.email = this.leadEmail
      }
      this.axios.patch(`/leads/${this.lead.pid}/`, data)
        .then(() => {
          this.updateCurrentLead()
          this.goToNextFlow(1)
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    checkboxRules (val) {
      return val.includes(true) ? [] : ['This field is required']
    },
    allowedAllDates: () => true,
    allowedDates (val) {
      if (!this.isBussinessHourEnabled) {
        return true
      }
      if (this.storeSetting.business_hours.holidays.includes(val)) {
        return false
      }
      const date = DateTime.fromFormat(val, 'yyyy-MM-dd')
      const openDays = this.storeSetting.business_hours.timetable.filter(f => f.slots.length > 0).filter(f => f.name === this.weekdays[date.weekday - 1])
      if (openDays.length === 0) {
        return false
      }
      return true
    },
    saveDate (index) {
      this.extraFields[index].show = false
      this.extraFields[index].value = this.formatDate(this.extraFields[index].date)
    },
    saveTime (index) {
      this.extraFields[index].show = false
      this.extraFields[index].value = this.formatTime(this.extraFields[index].time)
    },
    getMinDayBefore (day) {
      return DateTime.local().plus({ days: day }).toFormat('yyyy-MM-dd')
    },
    formatDate (date) {
      if (!date) { return null }
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    formatTime (time24) {
      let ts = time24
      const H = +ts.substr(0, 2)
      let h = (H % 12) || 12
      h = (h < 10) ? ('0' + h) : h
      const ampm = H < 12 ? 'AM' : 'PM'
      ts = h + ':' + ts.substr(3, 2) + ampm
      return ts
    },
  }
}
</script>

<style scoped>
.v-sheet--outlined {
  border: thin solid var(--v-primary200) !important;
}
.v-text-field--outlined >>> fieldset {
  border-color: var(--v-primary200);
}
.error--text >>> fieldset {
  border-color: var(--v-danger);
}
</style>

<i18n>
{
  "en": {
    "company_name_p0": " ",
    "send_message": "Send a message to",
    "company_name_p1": "{company_name}",
    "company_name_p2": " ",
    "via_whatsapp": "via WhatsApp",
    "disclaimer_whatsapp": "*Yezza is just a WhatsApp based platform and has no official affiliation with WhatsApp Inc.",
    "name": {
      "label": "Name",
      "placeholder": "Your name"
    },
    "phone": {
      "label": "Phone No",
      "placeholder": "Your phone no"
    },
    "email": {
      "label": "Email",
      "placeholder": "Your email"
    },
    "date": {
      "label": "Date",
      "placeholder": "Select date"
    },
    "time": {
      "label": "Time",
      "placeholder": "Select time"
    }
  },
  "ms": {
    "company_name_p0": " ",
    "send_message": "Hantar mesej ke",
    "company_name_p1": "{company_name}",
    "company_name_p2": " ",
    "via_whatsapp": "melalui WhatsApp",
    "disclaimer_whatsapp": "*Yezza adalah platform WhatsApp Commerce. Ia tiada kaitan rasmi dengan WhatsApp Inc",
    "name": {
      "label": "Nama",
      "placeholder": "Isikan nama anda"
    },
    "phone": {
      "label": "Nombor Telefon",
      "placeholder": "Isikan nombor telefon anda"
    },
    "email": {
      "label": "E-mel",
      "placeholder": "Isikan e-mel anda"
    },
    "date": {
      "label": "Tarikh",
      "placeholder": "Pilih tarikh"
    },
    "time": {
      "label": "Masa",
      "placeholder": "Pilih masa"
    }
  },
  "zh": {
    "company_name_p0": " ",
    "send_message": "通过 Whatsapp 给",
    "company_name_p1": " ",
    "company_name_p2": "{company_name}",
    "via_whatsapp": "发信息",
    "disclaimer_whatsapp": "*Yezza is just a WhatsApp based platform and has no official affiliation with WhatsApp Inc.",
    "name": {
      "label": "姓名",
      "placeholder": "Your name"
    },
    "phone": {
      "label": "Phone No",
      "placeholder": "Your phone no"
    },
    "email": {
      "label": "Email",
      "placeholder": "Your email"
    },
    "date": {
      "label": "Date",
      "placeholder": "Select date"
    },
    "time": {
      "label": "Time",
      "placeholder": "Select time"
    }
  },
  "ta": {
    "company_name_p0": "{company_name}",
    "send_message": " நிறுவனத்திற்கு",
    "company_name_p1": " ",
    "company_name_p2": " ",
    "via_whatsapp": "\"வாட்ஸ் ஆப்\" மூலம் குறுஞ்செய்தி அனுப்பவும்.",
    "disclaimer_whatsapp": "*எஸ்ஞா என்பது \"வாட்ஸ் ஆப்\" மூலம் இணைக்கப்பட்டு மேற்கொள்ளப்படும் ஒரு வர்த்தக கட்டமைப்பு.",
    "name": {
      "label": "பெயர்",
      "placeholder": "Your name"
    },
    "phone": {
      "label": "தொலை பேசி இலக்கம்",
      "placeholder": "Your phone no"
    },
    "email": {
      "label": "மின் அஞ்சல்",
      "placeholder": "Your email"
    },
    "date": {
      "label": "Date",
      "placeholder": "தெரிவு செய்த நாள்"
    },
    "time": {
      "label": "Time",
      "placeholder": "Select time"
    }
  }
}
</i18n>