<template lang="pug">
  div
    v-dialog(v-model="showModal" scrollable transition="fade-transition" persistent max-width="600")
      v-card
        v-card-title.pa-4
          div.h6.primary--text {{ $t('card_details') }}
          v-spacer
          v-btn(small icon @click="showModal = false")
            v-icon(color="primary") mdi-close
        v-card-text.px-4
          p.mb-1.label.basic600--text.text-uppercase {{ $t('card_number_exp_date_cvv') }}
          v-stripe-card.pt-2(dense solo flat background-color="basic200" height="40" ref="stripeCard" color="primary" @input="addCard($event)" @ready="cardReady" :api-key="stripeKey" outlined hide-details="auto")

        v-card-actions.pa-4
          v-btn.button-AA-medium.white--text(color="success500" small height="40" block @click="savePayment" dark) {{ $t('save') }}
</template>

<script>
import { mapGetters } from 'vuex'
import VStripeCard from 'v-stripe-elements/lib/VStripeCard'

export default {
  name: 'EditStripePayment',
  props: ['open'],
  components: { VStripeCard },
  data: () => ({
    stripeElement: {},
    stripeKey: process.env.VUE_APP_STRIPE_API_KEY,
  }),
  computed: {
    ...mapGetters({
      lead: 'current/getLead'
    }),
    showModal: {
      get () {
        return this.open
      },
      set (newValue) {
        this.$emit('update:open', newValue)
      }
    }
  },
  watch: {},
  mounted () {
  },
  methods: {
    async savePayment () {
      await this.$refs.stripeCard.processCard()
      if (!this.stripeElement.ready) {
        return
      }
      const payment = {
        method: 'stripe_card',
        data: {
          gateway: this.lead.form.company.default_payment_gateway,
          stripe_card_token: this.stripeElement.token,
          channel: 'cc',
          description: `#${this.lead.rid} ${this.lead.name} from yezza`,
          lead: this.lead.id
        },
        remarks: {
          id: 'stripe_card',
          display_name: 'Card payment'
        }
      }
      this.$store.commit('current/updatePayment', payment)
      this.showModal = false
    },
    cardReady () {
      this.$watch(
        () => this.$refs.stripeCard.errorBucket,
        (val) => {
          if (val.length > 0) {
            this.stripeElement.ready = false
          } else {
            this.stripeElement.ready = true
          }
        }
      )
    },
    addCard (token) {
      this.stripeElement.token = token.id
    }
  }
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: var(--v-primary200);
}
.error--text >>> fieldset {
  border-color: var(--v-danger);
}
</style>
