<template lang="pug">
  v-dialog(fill-height fluid v-model="showModal" scrollable transition="fade-transition" persistent max-width="420px")
    v-card
      v-card-title
        div.h6.basic800--text {{ $t('select_date_and_time') }}
        v-spacer
        v-btn(small icon @click="showModal = false")
          v-icon(color="primary") mdi-close
      v-divider.basic300
      template(v-if="omsData.scheduling_enabled")
        v-card-text.pa-4
          v-row(v-if="showAsap" no-gutters)
            v-col.pa-1
              div.px-3.py-2.clickable(outlined :style="selectedType === 'asap' ? `border:1px solid ${$vuetify.theme.themes[$theme].info500}; background:${$vuetify.theme.themes[$theme].infoT08};` : `border:1px solid ${$vuetify.theme.themes[$theme].textColorHint}; background:${$vuetify.theme.themes[$theme].basic600T08};`" @click="selectedType = 'asap'" style="border-radius:4px")
                div.button-AA-medium.font-weight-bold(:class="selectedType === 'asap' ? 'info500--text' : 'textColorHint--text'")
                  div.d-flex
                    v-icon.mr-3(color="black" size="18" :color="selectedType === 'asap' ? $vuetify.theme.themes[$theme].info500 : $vuetify.theme.themes[$theme].textColorHint" style="margin-top:1px; margin-bottom:2px") $icon-timer
                    span(style="margin-top:2px") ASAP
            v-col.pa-1
              div.px-3.py-2.clickable(outlined :style="selectedType === 'schedule' ? `border:1px solid ${$vuetify.theme.themes[$theme].info500}; background:${$vuetify.theme.themes[$theme].infoT08};` : `border:1px solid ${$vuetify.theme.themes[$theme].textColorHint}; background:${$vuetify.theme.themes[$theme].basic600T08};`" @click="selectedType = 'schedule'" style="border-radius:4px")
                div.button-AA-medium.font-weight-bold(:class="selectedType === 'schedule' ? 'info500--text' : 'textColorHint--text'")
                  div.d-flex
                    eva-icon.mr-3(name="calendar-outline" width="18" height="18" :fill="selectedType === 'schedule' ? $vuetify.theme.themes[$theme].info500 : $vuetify.theme.themes[$theme].textColorHint")
                    span.text-uppercase(style="margin-top:2px") {{ $t('schedule') }}

          template(v-if="selectedType === 'asap' && omsData.preparation_time_enabled")
            div.pa-2 {{ $t('estimated_preparation_time') }}: {{ omsData.preparation_time_value }} {{ omsData.preparation_time_unit }}
          template(v-if="selectedType === 'schedule'")
            v-card.px-2.py-1.mt-4.mx-2(v-if="!selectedDateTime" elevation="0" :color="$vuetify.theme.themes[$theme].dangerT16")
              div.c1.danger700--text {{ 'Please select date and time' }}
            y-schedule-picker(v-model="dateTime" :bgActive="$vuetify.theme.themes[$theme].infoT08" :params="params" width="600")
            div.px-4.pt-2.text-center {{ selectedDateTime }}
          
        v-card-actions
          v-btn.button-AA-medium.white--text.mb-2(color="success500" small height="40" block @click="updateDeliveryDateTime()" :loading="isLoading" :dark="Boolean(selectedDateTime)" :disabled="!selectedDateTime")
            div(:class="{ 'white--text': selectedDateTime }") {{ $t('save_date_and_time') }}
        
      .closed(v-else)
        v-card-text.pa-4
          template
            div(v-if="showAsap")
              div(v-if="omsData.preparation_time_enabled")
                .c1 ASAP. {{ $t('please_expect_approx') }}. {{ omsData.preparation_time_value }} {{ omsData.preparation_time_unit }} {{ $t('preparation_time') }} 
              div(v-else)
                .c1 ASAP

            div(v-else)
              .c1 {{ $t('were_closed_for_now') }}.{{ nextBusinessDay? ` ${$t('come_back_on')} ${nextBusinessDay}.` : '' }}
        
        v-card-actions(v-if="showAsap")
          v-btn.button-AA-medium.white--text.mb-2(color="success500" small height="40" block @click="updateDeliveryDateTime()" :loading="isLoading" :dark="Boolean(selectedDateTime)" :disabled="!selectedDateTime")
            div(:class="{ 'white--text': selectedDateTime }") {{ $t('save_date_and_time') }}

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'EditSchedule',
  props: ['open', 'parentOrderDateTime'],
  data: () => ({
    orderDateTime: null,
    dateTime: null,
    shippingMethod: 0,
    isLoading: false,
    nextBusinessDay: null,
  }),
  computed: {
    ...mapGetters({
      omsData: 'current/getOms',
      lead: 'current/getLead',
    }),
    showModal: {
      get() {
        return this.open
      },
      set(newValue) {
        this.$emit('update:open', newValue)
      }
    },
    selectedType: {
      get() {
        if (this.orderDateTime && this.orderDateTime.is_ASAP === false) {
          return 'schedule'
        }
        return 'asap'
      },
      set(newValue) {
        const data = {...this.orderDateTime, is_ASAP: newValue === 'asap'}
        this.orderDateTime = data
      }
    },
    useSameHours () {
      if (this.omsData.business_hour && this.omsData.business_hour.length > 0 && JSON.stringify(this.omsData.business_hour[0].days) === JSON.stringify(this.omsData.business_hour[1].days)) {
        return true
      }
      return false
    },
    selectedBusinessHour () {
      if (this.useSameHours) {
        return this.omsData.business_hour[0].days
      } else if (this.shippingMethod === 0) {
        return this.omsData.business_hour.filter(f => f.type_name === 'delivery')[0].days
      } else {
        return this.omsData.business_hour.filter(f => f.type_name === 'pickup')[0].days
      }
    },
    showAsap () {
      if (this.useSameHours) {
        return this.omsData.business_hour[0].can_ASAP
      } else if (this.shippingMethod === 0) {
        return this.omsData.business_hour.filter(f => f.type_name === 'delivery')[0].can_ASAP
      } else {
        return this.omsData.business_hour.filter(f => f.type_name === 'pickup')[0].can_ASAP
      }
    },
    params () {
      return {
        timeInterval: 15,
        daysCount: this.getSchedulingDays() || 0,
        businessHour: this.selectedBusinessHour,
        offset: this.getOffsetDays(),
        daysOff: this.omsData.restricted_dates
      }
    },
    selectedDateTime () {
      if (this.selectedType === 'asap') {
        return 'As soon as possible'
      } else if (this.dateTime) {
        const [date, time] = this.dateTime.split(' ')
        return this.convertDate(date) + ', ' + this.covert24To12(time)
      }
      return null
    }
  },
  watch: {
    dateTime (val) {
      const data = {...this.orderDateTime} || {}
      data.asking_delivery_date = null
      data.asking_delivery_date = null
      if (val) {
        const [date, time] = this.dateTime.split(' ')
        data.asking_delivery_date = date
        data.asking_delivery_time = time
      }
      this.orderDateTime = data
    }
  },
  mounted () {
    if (this.parentOrderDateTime) {
      this.orderDateTime = { ...this.parentOrderDateTime }
      if (this.orderDateTime.asking_delivery_date && this.orderDateTime.asking_delivery_time) {
        this.dateTime = `${this.orderDateTime.asking_delivery_date} ${this.orderDateTime.asking_delivery_time}`
      }
    }
    if (this.lead && this.lead.pickup) {
      this.shippingMethod = 1
    }
    if (!this.showAsap) {
      this.selectedType = 'schedule'
    } else if (!this.omsData.scheduling_enabled) {
      this.selectedType = 'asap'
    }
    this.getNextBusinessDay()
  },
  methods: {
    getNextBusinessDay () {
      for (let index = 1; index <= 7; index++) {
        const date = new Date(new Date().getTime() + (index * 24 * 60 * 60 * 1000))
        if (this.omsData.business_hour[0] && this.omsData.business_hour[0].days.filter(f => f.day.substr(0, 3) === date.toString().substr(0, 3)).length > 0) {
          this.nextBusinessDay = date.toString().substr(4, 6) + ', ' + this.covert24To12(this.omsData.business_hour[0].days.filter(f => f.day.substr(0, 3) === date.toString().substr(0, 3))[0].hours[0].begin)
          break
        } else if (this.omsData.business_hour[1] && this.omsData.business_hour[1].days.filter(f => f.day.substr(0, 3) === date.toString().substr(0, 3)).length > 0) {
          this.nextBusinessDay = date.toString().substr(4, 6) + ', ' + this.covert24To12(this.omsData.business_hour[1].days.filter(f => f.day.substr(0, 3) === date.toString().substr(0, 3))[0].hours[0].begin)
          break
        }
      }
    },
    updateDeliveryDateTime() {
      this.isLoading = true
      this.axios.patch(`/leads/${this.lead.pid}/`, this.orderDateTime)
        .then(() => {
          this.$store.commit('current/updateDatetime', this.orderDateTime)
          this.updateCurrentLead()
          this.showModal = false
        })
        .catch(() => {
          this.$store.commit('message/showMessage', ['error', 'Unable to complete your request. Please try again later'])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getOffsetDays () {
      if (this.omsData.preparation_time_enabled) {
        if (this.omsData.preparation_time_unit === 'days') {
          return Number(this.omsData.preparation_time_value || 0)
        }
        return 0
      }
    },
    getSchedulingDays () {
      const value = this.omsData.scheduling_begin_time_value
      const unit = this.omsData.scheduling_begin_time_unit
      if (unit === 'months') {
        return Number(value) * 30
      } else if (unit === 'weeks') {
        return Number(value) * 7
      } else {
        return Number(value)
      }
    },
    covert24To12 (time) { // format 08:00:00
      // Check correct time format and split into components
      time = time.split(':')[0] + ':' + time.split(':')[1]
      time = time.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time]
      if (time.length > 1) { // If time format correct
        time = time.slice(1) // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM' // Set AM/PM
        time[0] = +time[0] % 12 || 12 // Adjust hours
      }
      return time.join('') // return adjusted time or original string
    },
    convertDate (val) {
      const today = new Date(new Date().getTime() + (0 * 24 * 60 * 60 * 1000))
      const tomorrow = new Date(new Date().getTime() + (1 * 24 * 60 * 60 * 1000))
      const todaydate = today.getFullYear() + '-' + this.addLeadingZero((today.getMonth() + 1), 2) + '-' + this.addLeadingZero(today.getDate(), 2)
      const tomorrowdate = tomorrow.getFullYear() + '-' + this.addLeadingZero((tomorrow.getMonth() + 1), 2) + '-' + this.addLeadingZero(tomorrow.getDate(), 2)
      if (val === todaydate) {
        return 'Today'
      }
      if (val === tomorrowdate) {
        return 'Tomorrow'
      }
      if (val === '') {
        return '-'
      }
      if (!val) {
        return 'Close'
      }
      const d = new Date(val)
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      const newDate = days[d.getDay()] + ', ' + this.addLeadingZero(d.getDate(), 2) + ' ' + months[d.getMonth()]
      return newDate
    },
    addLeadingZero (str, count) {
      str = String(str)
      const len = str.length
      if (len < count) {
        for (let index = 0; index < count - len; index++) {
          str = '0' + str
        }
        return str
      }
      return str
    }
  }
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: var(--v-primary200);
}
.error--text >>> fieldset {
  border-color: var(--v-danger);
}
</style>
